@use "sass:color"

#login
  background-color: #FFFFFF
  box-shadow: 1px 1px 0px rgba(224, 224, 224, 0.75)
  border-radius: 10px
  width: 300px
  overflow: visible
  position: relative
  padding: 30px 40px
  margin: 0 auto
  padding-top: 75px

  &.terms
    width: 50%
    textarea
      resize: none
      // fallback
      height: 200px
      height: calc(75vh - 150px)

  form
    width: 100%
    height: 100%

  textarea
    width: 100%
    margin: 10px 0
    height: 200px
    font-size: 12px
    box-sizing: border-box

  h1, h2, h3, h4, h5
    color: #0A1D32

  h1
    padding-bottom: 10px
    font-size: 26pt
    letter-spacing: 1px
    font-weight: bold
    margin-bottom: 10px

  h2
    border-bottom: #4363FF 2px solid
    margin-bottom: 20px
    font-size: 1.5em

  label
    display: block
    font-weight: bold
    padding-bottom: 5px

  .fillIn
    outline: none
    border: 1px solid #D6D6D6
    background-color: #FFF
    padding: 10px 12px
    margin-bottom: 15px
    box-sizing: border-box
    border-radius: 5px
    letter-spacing: 0.02rem
    width: 100%
    &:hover
      background-color: #F8F9FF
      border-color: #c9c9c9

    &:focus
      border-color: #4363FF

  .submit
    cursor: pointer
    padding: 10px 15px
    letter-spacing: 1px
    font-weight: normal
    border-radius: 10px
    user-select: none
    box-sizing: border-box
    width: 100%
    text-align: center
    margin-top: 10px

    background-color: #4363ff
    border: 1px solid #4363ff
    color: #FFFFFF

    &:hover
      background-color: color.adjust(#4363ff, $lightness: 5%)
      border-color: color.adjust(#4363ff, $lightness: 5%)

  .form_validation_info
    color: #707070
    margin-bottom: 1em
    .valid
      color: #139417
    .invalid
      color: #bb1212

  .error
    padding: 10px
    margin: 5px 0 20px 0
    color: #0A1D32
    background-color: #ffd3d3
    font-size: 14px
    line-height: 140%
    letter-spacing: 0.02rem
    position: relative
    border-radius: 10px
    box-sizing: border-box

  .confirmation
    padding: 10px
    margin: 5px 0 20px 0
    color: #0A1D32
    background-color: #FFFDD3
    font-size: 14px
    line-height: 140%
    letter-spacing: 0.02rem
    position: relative
    border-radius: 10px
    box-sizing: border-box

  .info
    font-style: italic
    margin: 2em 0

  .without_bottom_margin
    margin-bottom: 0

  .with_half_top_margin
    margin-top: 1em

  &.timeout .error
    margin-top: 0

  .checkbox_handler
    margin-bottom: 15px
    text-align: center

    *
      cursor: pointer

    label
      padding: 0 5px
      display: inline-block

  .success
    font-style: italic
    color: black
    text-align: center
    margin: 2em 0

  .forgot_password_link
    color: #4363FF
    display: inline-block
    margin-top: 10px
    text-align: right
    font-size: 0.8em

    &:hover, &:focus
      text-decoration: underline

  .forgot_password_link
    margin-top: 10px
    margin-bottom: 0


  .options
    p
      display: block
      margin: 10px 0
      padding: 10px 0
    a
      text-align: center
      display: block
      margin: 10px 0
      padding: 10px 0
      background-color: transparent
      border: 1px solid #d6d6d6
      color: color.adjust(#0A1D32, $lightness: 10%)
      border-radius: 10px
      &:hover
        background-color: #ededed


  .settings_form
    .select_country_wrapper
      margin: 20px 0px

    .select_country_inline_wrapper
      margin-right: 10px

    label
      margin: 5px 0px



@media only screen and (max-device-width: 900px), screen and (max-width: 900px)
  #login
    box-sizing: border-box

  #login.terms
    width: 100%
