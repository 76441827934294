.sidebar
  position: fixed
  top: 0
  left: 0
  bottom: 0
  width: 329px
  border-right: 1px solid #b8b8b8
  overflow-y: auto
  overflow-x: hidden
  background-color: #EEE

  #sidebarlogobase
    background-size: 250px
    display: block
    background-color: #E8E8E7
    background-repeat: no-repeat
    border-bottom: 1px solid #b8b8b8
    height: 99px
    width: 329px

  #sidebarlogo
    @extend #sidebarlogobase
    background-image: url("@cvpartner/design-system/logos/mainWordmark.svg")
    background-position: center
    background-size: 200px

  #sidebar
    margin-top: 100px

  h3
    color: #555
    padding: 20px 20px
    background-color: #EEE

    border-bottom: 1px solid #b8b8b8
    border-top: 1px solid #fff

    &:hover
      background-color: #fafafa



.helpcontainer
  padding-left: 330px
  color: #444
  -webkit-font-smoothing: antialiased

  h1
    margin: 0 auto
    max-width: 760px
    color: #555

  .h1_wrapper
    background-color: #eeeeee
    border-bottom: 1px solid #b8b8b8
    padding: 25px 40px

  h3
    letter-spacing: 0.01rem
    font-style: normal
    font-size: 26px
    font-weight: bold
    color: #555
    padding: 10px
    background-color: #F5F5F5
    border-bottom: 1px solid #E0E0E0

  a
    color: #52A5D6

  label
    font-weight: bold

  .helpwrapper
    padding: 30px 40px
    max-width: 760px
    margin: 0 auto
    color: #444

    .helpelement
      margin: 0 0 4em 0
      padding-bottom: 20px
      background-color: #FFF

    .example
      padding: 20px 20px 0 20px

      p
        font-size: 14px
        color: #333


    .explanation
      margin-top: 1em
      font-size: 0.8em
      font-style: italic
      color: #707070

    p
      margin: 0.5em 0
      letter-spacing: 0.01rem
      font-weight: 400
      font-style: normal
      font-size: 22px
      line-height: 1.5

    table
      border-collapse: collapse
      border-spacing: 0

      td, th
        border: 1px solid #999
        padding: 5px 10px
        color: #555
        font-size: 14px
        text-align: left

      th
        font-weight: bold
        background: #f8f8f8

    ul
      padding: 15px 15px 5px
      margin: 20px 20px 0px 20px
      background-color: #EEE

      li
        margin-bottom: 10px
        list-style: none
        font-size: 12px
        word-wrap: break-word
