@use "bootstrap/mixins"

$colorPersonalDetails: #E8E8E8
$colorKeyQualifications: #8DD3C7
$colorProjectExperiences: #FFED6F
$colorAggregatedRoles: #EBD957
$colorTechnologies: #BEBADA
$colorEducations: #FB8072
$colorWorkExperiences: #80B1D3
$colorCertifications: #FDB462
$colorCourses: #B3DE69
$colorPresentations: #FCCDE5
$colorRecommendations: #D9D9D9
$colorPositions: #BC80BD
$colorHonorsAwards: #b2c7da
$colorBlogs: #CCEBC5
$colorLanguages: #FFFFB3
$colorMentorings: #FFDAB2

// COLOR SETTINGS //
#main_container
  #personal_details_link
    .colorblock
      display: none !important
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorPersonalDetails)

  #key_qualifications, .key_qualifications
    @include mixins.boxcolor($colorKeyQualifications)
    #key_qualifications_list
      .long_description_wrapper .key_qualification_input
        min-height: 150px
      .collapsedField:first-child
        white-space: pre-wrap
  #key_qualifications_link
    @include mixins.sidenavbar($colorKeyQualifications)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorKeyQualifications)

  #project_experiences, .project_experiences
    @include mixins.boxcolor($colorProjectExperiences)
    #project_experiences_list
      .long_description_wrapper, .customer_description_wrapper, .customer_value_proposition_wrapper
        .project_experience_input
          min-height: 150px
  #project_experiences_link
    @include mixins.sidenavbar($colorProjectExperiences)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorProjectExperiences)

  #aggregated_roles, #highlighted_roles, #cv_roles
    @include mixins.boxcolor($colorAggregatedRoles)

  #aggregated_roles_link, #highlighted_roles_link, #cv_roles_link
    @include mixins.sidenavbar($colorAggregatedRoles)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorAggregatedRoles)

  #technologies, .technologies
    @include mixins.boxcolor($colorTechnologies)
  #technologies_link
    @include mixins.sidenavbar($colorTechnologies)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorTechnologies)

  #educations
    @include mixins.boxcolor($colorEducations)
  #educations_link
    @include mixins.sidenavbar($colorEducations)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorEducations)

  #work_experiences
    @include mixins.boxcolor($colorWorkExperiences)
    #work_experiences_list
      .collapsedField:first-child
        min-width: 15%
  #work_experiences_link
    @include mixins.sidenavbar($colorWorkExperiences)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorWorkExperiences)

  #certifications, .certifications
    @include mixins.boxcolor($colorCertifications)
    #certifications_list
      .collapsedField:first-child
        min-width: 10%
      .collapsedField
        min-width: 60%
  #certifications_link
    @include mixins.sidenavbar($colorCertifications)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorCertifications)

  #courses, .courses
    @include mixins.boxcolor($colorCourses)
    #courses_list
      .collapsedField:first-child
        min-width: 10%
      .collapsedField
        min-width: 40%
  #courses_link
    @include mixins.sidenavbar($colorCourses)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorCourses)

  #presentations
    @include mixins.boxcolor($colorPresentations)
  #presentations_link
    @include mixins.sidenavbar($colorPresentations)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorPresentations)

  #recommendations
    @include mixins.boxcolor($colorRecommendations)
  #recommendations_link
    @include mixins.sidenavbar($colorRecommendations)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorRecommendations)

  #positions
    @include mixins.boxcolor($colorPositions)
  #positions_link
    @include mixins.sidenavbar($colorPositions)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorPositions)

  #honors_awards
    @include mixins.boxcolor($colorHonorsAwards)
  #honors_awards_link
    @include mixins.sidenavbar($colorHonorsAwards)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorHonorsAwards)

  #blogs
    @include mixins.boxcolor($colorBlogs)
  #blogs_link
    @include mixins.sidenavbar($colorBlogs)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorBlogs)

  #languages
    @include mixins.boxcolor($colorLanguages)
  #languages_link
    @include mixins.sidenavbar($colorLanguages)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorLanguages)

  #mentorings
    @include mixins.boxcolor($colorMentorings)
  #mentorings_link
    @include mixins.sidenavbar($colorMentorings)
    &.selected, &:hover, &:focus, &.section_hovered
      @include mixins.sidenavbar_hover($colorMentorings)
// END OFF COLOR SETTINGS //

// START COLOR SEARCH MATCHING
.filter_options, .filter_tag
  .button, .filter_tag_content
    &.personal_details
      @include mixins.set_search_color($colorPersonalDetails)
    &.key_qualifications, &.key_points
      @include mixins.set_search_color($colorKeyQualifications)
    &.project_experiences, &.roles, &.industries, &.industry, &.project_types, &.customers, &.company_customer, &.cv_roles, &.cv_roles_name, &.project_experiences_industry, &.project_experiences_project_type, &.project_type, &.project_experiences_location_country_code, &.project_location_country, &.contract_type, &.project_experiences_contract_type
      @include mixins.set_search_color($colorProjectExperiences)
    &.technologies, &.skills, &.technologies_technology_skills_tag
      @include mixins.set_search_color($colorTechnologies)
    &.educations, &.schools, &.school
      @include mixins.set_search_color($colorEducations)
    &.work_experiences
      @include mixins.set_search_color($colorWorkExperiences)
    &.certifications, &.certifications_name
      @include mixins.set_search_color($colorCertifications)
    &.courses, &.courses_name
      @include mixins.set_search_color($colorCourses)
    &.presentations
      @include mixins.set_search_color($colorPresentations)
    &.recommendations
      @include mixins.set_search_color($colorRecommendations)
    &.positions, &.title
      @include mixins.set_search_color($colorPositions)
    &.honors_awards
      @include mixins.set_search_color($colorHonorsAwards)
    &.blogs
      @include mixins.set_search_color($colorBlogs)
    &.languages, &.languages_name
      @include mixins.set_search_color($colorLanguages)
    &.mentorings
      @include mixins.set_search_color($colorMentorings)
// END COLOR SEARCH MATCHING

// START MODAL SECTION TYPE COLOR
#editable_cv .modal_content
  .personal_details
    border-color: $colorPersonalDetails
  .key_qualifications
    border-color: $colorKeyQualifications
  .project_experiences
    border-color: $colorProjectExperiences
  .technologies
    border-color: $colorTechnologies
  .educations
    border-color: $colorEducations
  .work_experiences
    border-color: $colorWorkExperiences
  .certifications
    border-color: $colorCertifications
  .courses
    border-color: $colorCourses
  .presentations
    border-color: #FF8FC8
  .recommendations
    border-color: $colorRecommendations
  .positions
    border-color: $colorPositions
  .honors_awards
    border-color: $colorHonorsAwards
  .blogs
    border-color: $colorBlogs
  .languages
    border-color: #FBFB95
  .mentorings
    border-color: $colorMentorings

// END MODAL SECTION TYPE COLORING

// TEMPLATE HELP COLOURING
.helpcontainer .helpwrapper
  .personal_details
    border-color: $colorPersonalDetails
  .key_qualifications
    border-color: $colorKeyQualifications
  .project_experiences
    border-color: $colorProjectExperiences
  .technologies
    border-color: $colorTechnologies
  .educations
    border-color: $colorEducations
  .work_experiences
    border-color: $colorWorkExperiences
  .certifications
    border-color: $colorCertifications
  .courses
    border-color: $colorCourses
  .presentations
    border-color: $colorPresentations
  .recommendations
    border-color: $colorRecommendations
  .positions
    border-color: $colorPositions
  .honors_awards
    border-color: $colorHonorsAwards
  .blogs
    border-color: $colorBlogs
  .languages
    border-color: $colorLanguages
  .mentorings
    border-color: $colorMentorings
