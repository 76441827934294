@media only screen and (max-device-width: calc(64rem - 1px)), screen and (max-width: calc(64rem - 1px))
  .breadcrumbs_container
    .breadcrumbs
      padding-left: 10px
      padding-right: 10px

  .scroll_nav
    display: none

  .block_container
    margin: 0 10px !important
    &.contained_list
      margin: 0 !important

    .right_sidebar_container
      position: static
      right: auto
      top: auto
      width: auto

  .block_section_wrapper

    &.extra_margin_top
      margin-top: 30px !important

    &.normal_margin_top
      margin-top: 24px !important

    .block_section
      &.right_aligned
        position: relative !important
        top: auto !important
        right: auto !important

    .block_section_group.with_right_absolute_action
      padding-right: 0

@media only screen and (max-device-width: 990px), screen and (max-width: 990px)
  .block_section_wrapper
    .block_section_group .switch
      position: relative
      display: inline-block
      top: auto
      right: auto
      margin-bottom: 10px
    &.right_sidebar_container
      .switch
        position: relative
        display: inline-block
        top: auto
        right: auto
        left: auto
        margin-bottom: 10px
        margin-top: -10px

@media only screen and (max-device-width: 900px), screen and (max-width: 900px)
  .block_container
    .expandSection
      display: block
  .search_categories_container .search_category_dropdown
    position: absolute
  .search_categories_container .search_category
    padding-left: 20px
    padding-right: 20px
  .search_container
    .search_categories_container .search_category.dropdown_active:after
        right: 24px
  #dashboard
    .search_categories_container
      .search_category
          border-radius: 10px

@media only screen and (max-device-width: 500px), screen and (max-width: 500px)
  .block_section_group
    &.padding_right
      padding-right: 10px !important
  .block_container
    .block_section_wrapper
      .block_section_container
        padding: 10px 10px 0 10px
        &.no_padding_right
          padding-right: 0px !important
        &.expandable
          padding-top: 40px !important
        &.with_left_section_image
          .left_section_image
            display: none
    .block_section_wrapper
      .block_section.subsection_padding
        padding: 10px 15px 15px 15px
        .handle
          display: none

    .block_section.custom_container.right_absolute_action
      display: block
      position: static
      right: auto
      top: auto

    .block_section.custom_container .add_custom_container
      max-width: 280px

  .block_section_wrapper .block_section
    &.targ_card_padding
      white-space: initial

    input
      max-width: 280px !important
