@media only screen and (max-device-width: 1070px), screen and (max-width: 1070px)
  #settings
    .scroll_nav
      display: none

    .settings_platform.autoplatform_with_sidebar
      margin-right: 0px

    .fieldsetWrapper.equal_sides
      padding-right: 0px

@media only screen and (max-device-width: 650px), screen and (max-width: 650px)
  #settings

    .settings_platform.autoplatform_with_sidebar
      padding: 2em 0

@media only screen and (max-device-width: calc(64rem - 1px)), screen and (max-width: calc(64rem - 1px))
  #settings
    .dropdown_container, .button.float_right
      float: none
      margin-left: 20px
