@media only screen and (max-device-width: 1300px), screen and (max-width: 1300px)
  .modal_cv
    .modal_nav
      .dropdown_container
        &.navigation_dropdown
          span
            max-width: 150px

@media only screen and (max-device-width: 1000px), screen and (max-width: 1000px)
  .modal_cv
    .modal_nav.for_references
      .navigation_dropdown.disabled
        display: none
      .navigation_dropdown.less_important
        display: none

@media only screen and (max-device-width: 900px), screen and (max-width: 900px)
  .modal-wrapper.extended_modal_size
    width: 100%
    top: 50px
    left: 0
    margin-left: 0
    .modal_extra_padding
      padding: 10px 0

@media only screen and (max-device-width: 600px), screen and (max-width: 600px)
  .modal-wrapper
    width: 400px
    margin-left: -200px
    .popupheading
      padding: 10px 0
      h1
        font-size: 1em
      span
        font-size: 0.6em
  .modal_cv
    .modal_nav
      .navigation_dropdown.disabled
        display: none
  .modal_cv
    .modal_nav.for_references
      .navigation_dropdown.disabled_dropdown
        display: none

@media only screen and (max-device-width: 400px), screen and (max-width: 400px)
  .modal-wrapper
    width: 100%
    left: 0
    top: 0
    margin-left: 0
    z-index: 10000
    .modal_extra_padding
      padding: 10px

  // temporary
  #editable_cv .read_only .read_only_container .language_container
    display: none !important
