@media only screen and (max-device-width: calc(80rem - 1px)), screen and (max-width: calc(80rem - 1px))
  .interactive_cards li
    min-width: 250px


// For full width
@media only screen and (max-device-width: 470px), screen and (max-width: 470px)
  .interactive_cards .full_width
    .bottom_information_card
      top: 5px
    .secondary_actions_block.with_bottom_information_card
      padding-top: 25px

  #dashboard #offers.toggled_sidebar + #main_content
    margin-right: 295px
