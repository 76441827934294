@import "@cvpartner/design-system/dist/fonts.css"

@font-face
  font-family: 'IBM-Plex-Sans'
  font-style: normal
  font-weight: 400
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexSans-Regular.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexSans-Regular.woff") format('woff')

@font-face
  font-family: 'IBM-Plex-Sans'
  font-style: italic
  font-weight: 400
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexSans-Italic.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexSans-Italic.woff") format('woff')

@font-face
  font-family: 'IBM-Plex-Sans'
  font-style: normal
  font-weight: 700
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexSans-SemiBold.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexSans-SemiBold.woff") format('woff')

@font-face
  font-family: 'IBM-Plex-Mono'
  font-style: normal
  font-weight: 400
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexMono-Regular.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexMono-Regular.woff") format('woff')

@font-face
  font-family: 'IBM-Plex-Mono'
  font-style: italic
  font-weight: 400
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexMono-Italic.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexMono-Italic.woff") format('woff')

@font-face
  font-family: 'IBM-Plex-Mono'
  font-style: normal
  font-weight: 700
  font-display: auto
  src: url("../images/fonts/woff2/IBMPlexMono-SemiBold.woff2") format('woff2'), url("../images/fonts/woff/IBMPlexMono-SemiBold.woff") format('woff')
