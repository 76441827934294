html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  padding: 0
  margin: 0

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main
  display: block

audio, canvas, video
  display: inline-block

li
  list-style: none

a
  text-decoration: none
  color: inherit

button
  overflow: visible
  border: 0
  padding: 0
  font: inherit
  -webkit-font-smoothing: inherit
  letter-spacing: inherit
  background: none
  cursor: pointer

fieldset
  border: none

body
  margin: 0
  font: 16px "IBM-Plex-Sans", "Helvetica Neue", Arial, sans-serif
  // for the future - font: 16px/1 "Helvetica Neue", Helvetica, Arial, sans-serif
  font-size: 100%
  min-height: 100%
  background-color: #FFF
  letter-spacing: 0.01rem

html
  font-size: 100%
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%

img
  max-width: 100%
  height: auto
  border: 0
  -ms-interpolation-mode: bicubic
  vertical-align: middle

button, input, select, textarea
  margin: 0
  font-size: 100%

// Inner spacing ie IE6/7 - FF3/4 have !important on line-height in UA stylesheet
button, input
  *overflow: visible
  line-height: normal

// datetimepicker-local reset
input::-webkit-datetime-edit-fields-wrapper
  padding: 0

// Inner padding and border oddities in FF3/4
button::-moz-focus-inner, input::-moz-focus-inner
  padding: 0
  border: 0

// Remove input spinners
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
  display: none
  appearance: none
  margin: 0

// Remove vertical scrollbar in IE6-9
textarea
  overflow: auto

// We just dont love you google chrome
body, div, a, h1, h2, h3, h4, h5, textarea, button, input, a:focus, a:active
  outline: none
  outline-offset: -2px

// We just dont love you iOS
input
  border-radius: 0

// firefox bug
\::-moz-focus-inner
  padding: 0
  border: 0

// Default hide password autofill apps //////////////////

// Lastpass
div[data-lastpass-icon-root], div[data-lastpass-root], div[data-lpicon]
  visibility: hidden

// 1Password
div[data-onepassword-filled], div[data-onepassword-root], #op-button
  display: none

.allow_password_autofill_apps_on_page
  // Lastpass
  div[data-lastpass-icon-root], div[data-lastpass-root], div[data-lpicon]
    visibility: visible
  // 1Password
  #op-button[data-onepassword-filled], com-1password-button
    display: block

input[type=number]
  -moz-appearance: textfield
