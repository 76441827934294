@use "sass:color"

// Page settings
$mainFont: "IBM-Plex-Sans", "Helvetica Neue", Arial, sans-serif
$colorDarkerBlue: #318ec6

// CVP Colors
$colorInk: #0A1D32
$colorInk10: color.adjust($colorInk, $lightness: 10%)
$colorInk20: color.adjust($colorInk, $lightness: 20%)
$colorSky: #4363FF
$colorAvocado: #8ae133
$colorWhite: #FFFFFF
$colorButtercup: #F2C94C

$colorBeach: #ffefcd
$colorBeach_dark: #ffd375

// Graytones
$colorGray1: #E0DDDA
$colorGray2: #E7E5E1
$colorGray3: #EFEDEA
$colorPaper: #F5F4F2
$colorGray4: #F8F7F7
$colorGray5: #F9F9F9
$colorGray6: #FBFBFB

// Alerts
$colorError: #bb1212
$colorWarning: #FF9800
$colorSuccess: #4CAF50

// State colors (Keep in sync with elements.css)
$colorSelected: $colorSky
$colorFocus: $colorSky
$colorLink: $colorSky
$colorButtonPrimary: $colorInk
$colorButtonSecondaryBorder: #d6d6d6
$colorButtonSecondaryHover: #fafafa
$colorInputBorder: #d6d6d6
$colorInputHover: #F8F9FF
$colorInputEmpty: #f6fbff

// Old, to be removed
$color1: #D6D6D6
$color2: #8AB2C4
$color3: color.adjust($colorInk, $lightness: 10%)
$color4: #FFFFFF
$color5: #E7E5E1

// Sprites
$sprite_width_forth: 375px
$sprite_width_third: 500px
$sprite_width_half: 750px

.template_generator

  .dropdown_container_container
    vertical-align: middle

  .modal-wrapper
    pointer-events: auto

  .template_generator_container
    margin-right: 300px
    overflow-y: auto

  .template_generator_wrapper
    padding: 0 30px 30px 30px
    position: relative
    .extra_margin_top
      margin-top: 80px

  .document_container
    width: 595pt
    &.landscape
      width: 842pt

    &.a3
      width: 1190pt

    &.a3.landscape
      width: 1684pt

    &.ppt
      width: 1000pt

      .slide
        padding: 0
        position: relative

        .ppt_image_element
          background-color: #CCCCCC
          color: #AAA
          width: 100%
          height: 100%
          position: absolute

        .repeated_ppt_element
          display: block
          position: absolute
          border: 1px #CCC dashed
          overflow: hidden
          box-sizing: border-box
          opacity: 0.3

        .repeated_ppt_text, .repeated_ppt_paragraph
          opacity: 0.3

        .repeated_ppt_paragraph
          .selectable_element
            &.selected
              background-color: transparent

        .selectable_ppt_cell
          border-collapse: collapse
          cursor: pointer
          border: 1px #CCC dashed
          vertical-align: top

          &.selected
            box-shadow: 0 0 0 2px $colorSelected
            color: $color5

          .add_text_inline_heading
            display: none
            &.selected
              display: block

        .dashed-outline
          outline: 1px #CCC dashed

        .selectable_ppt_element
          cursor: pointer
          display: block
          position: absolute
          box-sizing: border-box
          word-break: break-word

          &.selected
            box-shadow: 0 0 0 2px $colorSelected

          .add_text_inline_heading
            display: none
            &.selected
              display: block

          .content_paragraph
            position: relative
            display: block
            &.bullet
              list-style: disc outside none
              display: list-item !important
              margin-left: 15px
              padding-left: 0px
              .element_container
                vertical-align: text-top

              .contextual_heading.left_heading.paragraph_heading
                margin-right: 37px
            &.selected
              box-shadow: 0 0 0 2px $colorSelected
              color: $color5
              .add_text_heading
                display: block

            .add_text_heading
              display: none

  .element_container
    display: inline-block
    &.line
      position: relative
      width: 100%
      display: block
      .line_content
        position: absolute
        left: 0
        top: 0
    &.table
      width: 100%
      border: 0
    &.image
      padding: 0
      border: 0
      display: block
    &.no_break
      display: inline-block !important
      margin-right: 5px
      &+.element_container
        display: inline-block !important
    &.dynamic_text, &.static_text
      display: block

  .selectable_element
    cursor: pointer
    display: inline-block
    position: relative
    user-select: none
    padding: 5px
    margin: -5px
    &.selected
      background-color: color.adjust($colorSky, $lightness: 33%)

  .contextual_heading
    position: absolute
    cursor: pointer
    display: inline-block
    pointer-events: auto
    user-select: none
    background-color: $color4
    border: 1px solid $color5
    color: $color1
    text-transform: uppercase
    font-size: 9px
    letter-spacing: 0.1rem
    height: 15px
    width: 15px
    padding: 5px
    background-image: url("../images/icons/template_generator_sprite_x2.png")
    background-size: $sprite_width_forth
    background-repeat: no-repeat
    &:after
      content: ''
      width: 0
      height: 0
      border-style: solid
      position: absolute
      top: 4px

    &.text_heading
      background: none
      width: auto
      height: auto
      z-index: 10
      display: block
      margin-top: 5px
      &:after
        display: none

    &.right_heading
      left: 100%
      top: 0
      margin: 0 0 0 10px
      &:after
        right: 100%
        border-width: 8px 6px 8px 0
        border-color: transparent $color5 transparent transparent

    &.left_heading
      right: 100%
      top: 0
      margin: 0 10px 0 0
      &:after
        left: 100%
        border-width: 8px 0 8px 6px
        border-color: transparent transparent transparent $color5

    &.top_heading
      left: -19px
      bottom: 100%
      margin-bottom: 10px
      &:after
        top: 100%
        border-color: $color5 transparent transparent transparent
        border-width: 6px 8px 0 8px
      &.right_side
        right: -17px
        left: auto

    &.absolute_right_bottom_heading
      left: 100%
      top: 0px
      z-index: 10
      &:after
        display: none

    &.right_bottom_heading
      left: 100%
      bottom: -12px
      z-index: 10
      &:after
        display: none

    &:hover
      background-color: $color4
    &.selected
      background-color: color.adjust($colorSky, $lightness: 33%)
      border-color: $colorSky
      &.left_heading:after
        border-color: transparent transparent transparent $colorSky
      &.right_heading:after
        border-color: transparent $colorSky transparent transparent
      &.add_text_inline_heading
        background-color: #FFF
        border-color: #E7E5E1

    &.move_heading, &.resize_heading
      top: -27px
      background-image: none
      &:after
        content: "\292D"
        border: none
        top: 0
        font-size: 22px
        line-height: 22px
        color: $colorInk20
    &.resize_heading
      left: 100%
      top: 100%
      margin-left: -27px
      margin-top: -27px
      &:after
        line-height: 26px
        content: "\2921"
        color: $colorInk20
    &.image_heading
      z-index: 8
      background-position: -50px -50px
    &.line_heading
      top: -11px
      z-index: 9
      background-position: -100px -50px
      margin-left: 40px
    &.table_heading
      z-index: 10
      background-position: 0px -100px
      margin-right: 40px
    &.lockable_heading
      top: -6px
      background-position: -100px -200px
      &.locked
        background-position: 0px -200px
        background-color: $color4
        border-color: $color3
        &:after
          border-color: transparent transparent transparent $color2
    &.paragraph_heading
      background-position: -150px -300px
      margin-right: 20px
      z-index: 10
    &.grouped_paragraph_heading
      background-position: -200px -300px
      margin-right: 20px
      z-index: 10
      &.add_new_grouped_paragraph_heading
        margin-right: 0
        &:after
          display: none
    &.number_of_columns_2
      top: 100%
      background-position: -300px -150px
    &.number_of_columns_3
      top: 100%
      background-position: -300px -200px

  .document, .footer, .header
    &.selected
      box-shadow: inset 0px 0px 0px 1px $colorSelected

  .table
    .cell
      border: 1px solid #000
      border-width: 0px 2px 2px 0px
      vertical-align: top
      min-width: 20px
      min-height: 20px
      padding: inherit
      &.empty_cell
        height: 20px
      &.selected
        box-shadow: inset 0px 0px 0px 5px color.adjust($colorSky, $lightness: 33%)
      .contextual_information
        position: absolute
        right: 0px
        bottom: 0px
        width: 20px
        height: 20px
        cursor: pointer
        &.repeating_cell
          background-image: url("../images/icons/template_generator_sprite_x2.png")
          background-size: $sprite_width_forth
          background-repeat: no-repeat
        &.repeating_cell
          background-position: -53px -202px
      .line_content
        width: 100% !important
        margin-left: auto !important
        position: relative !important
    &.selected
      box-shadow: inset 0px 0px 0px 5px color.adjust($colorSky, $lightness: 33%)
  .line
    hr
      margin: 0
      border-width: 1px 0 0 0
      border-top-color: #000
      border-top-style: solid
      width: 100%
      &.selected
        box-shadow: inset 0px 0px 0px 5px color.adjust($colorSky, $lightness: 33%)
  .bullet
    list-style: none
    display: flex
    border: 0 !important
    align-items: center
    padding-top: 2px
    padding-bottom: 2px
  .image
    text-align: center
    cursor: pointer
    display: inline-block
    &.ppt
      position: absolute
    .image_placeholder
      background-color: #F2F2F2
      border-radius: 2px
      overflow: hidden
      border: 1px solid #EAEAEA
      border-width: 0 1px 1px 0

  .element_option
    position: absolute
    left: 100%
    top: 0
    width: 15px
    height: 15px
    background-image: url("../images/icons/template_generator_sprite_x2.png")
    background-size: $sprite_width_forth
    background-repeat: no-repeat

    &.keep_with_next
      background-position: -104px -252px

  .contextual_tools
    position: fixed
    width: 300px
    right: 0
    top: 108px
    bottom: 0
    z-index: 200
    box-sizing: border-box
    font-size: 12px
    font-weight: bold
    text-align: left
    cursor: default
    overflow: scroll
    background-color: $color4
    font-family: $mainFont
    pointer-events: auto
    border-left: 1px solid #dcdcdc

    .table_border
      padding: 5px
      table
        width: 100%
        height: 100%
        border-collapse: collapse
      tr, td
        border: 2px solid #8AB2C4
      &.style_inside
        table
          border-style: hidden
      &.style_none
        tr, td
          border-style: dashed
          border-color: #9bb6c257

    .add_elements
      border-bottom: 1px solid $color5
      margin-bottom: 20px

      .group_heading
        padding: 10px 10px 10px 20px
        margin: 0
        display: block
        background-color: $colorGray5

    .group_heading
      text-transform: uppercase

    .new_template_element
      width: 33.3%
      height: 99px
      padding-top: 10px
      color: $colorInk10
      background-color: $color4
      border: 1px solid $color5
      border-width: 0 0 1px 0
      text-transform: uppercase
      text-align: center
      box-sizing: border-box

      .icon_label
        padding: 0 4px

      &.empty_state_action
        padding: 50px
        margin: 30px
        border-width: 1px
        width: auto
        height: auto

      &.add_new_element_action
        width: auto
        height: auto
        padding: 5px 13px
        text-align: left

      &.selection_action
        width: auto
        height: auto
        text-align: left
        margin: 20px
        border-width: 1px
        padding: 5px

        &.without_margin
          margin: 0

      &:hover
        background-color: #F8F9FF

      &.selected
        background-color: color.adjust($colorSky, $lightness: 33% )

      .icon
        height: 50px
        width: 50px
        display: inline-block
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat

        &.template_table
          background-position: 0px -200px

        &.template_image
          background-position: -100px -100px

        &.template_text_box
          background-position: -100px -200px

        &.template_shape
          background-position: -300px -500px

        &.template_text
          background-position: 0px 0px

        &.template_line
          background-position: -200px -100px

        &.template_bulletlist
          background-position: 0px -100px

        &.change_style
          background-position: 0px 0px
        &.select_content
          background-position: -100px 0px
        &.remove
          background-position: -200px 0px
        &.change_paragraph_spacing
          background-position: -400px 0px

        &.change_line
          background-position: -200px -100px

        &.change_table_margins, &.change_document_margins
          background-position: -100px -200px
        &.change_table_lines
          background-position: -200px -200px
        &.change_repeating_sections
          background-position: -300px 0px
        &.change_repeating_sections_off
          background-position: -300px -100px
        &.change_lock
          background-position: 0px -400px

        &.template_add_element
          background-position: 0px -600px
        &.template_background
          background-position: -100px -600px
        &.add_new_text
          background-position: -200px -600px

    .input_action
      border: 1px solid $color5
      border-width: 1px 0
      padding: 10px 20px 5px 20px
      margin: 0 0 25px
      color: $color2
      background-color: $color4
      &:hover
        background-color: $color3
        color: $color1

    .input_group
      margin: 0 0 25px 0
      padding: 0 20px

    .input_group_heading
      text-transform: uppercase
      margin: 0 0 10px 0

    .input_button
      display: inline-block
      cursor: pointer
      padding: 7px 12px
      font-size: 12px
      letter-spacing: 1px
      font-weight: normal
      border: 1px solid transparent
      border-radius: 20px
      user-select: none
      box-sizing: border-box

      background-color: transparent
      border-color: $colorButtonSecondaryBorder
      color: $colorInk

      &:hover
        background-color: $colorButtonSecondaryHover

      &.selected
        background-color: $colorSelected
        color: $colorWhite
        border-color: $colorSelected

      &.red
        background-color: $colorError
        border-color: $colorError
        color: #FFF !important
        &:hover
          background-color: color.adjust($colorError, $lightness: -10%)
          border-color: color.adjust($colorError, $lightness: -10%)

      &.full_width
        display: block
        text-align: center
        border-width: 1px 0 0 0

    .input_container
      margin: 0 10px 15px 10px

      &.inline
        display: inline

      .input_group
        padding: 0 0 0 10px

      label
        text-transform: uppercase
        opacity: 0.7
        display: inline-block
        cursor: default
        padding-right: 10px
        font-size: 12px
        &.block_label
          display: block

      .input_group_collection
        border: 1px solid $color2
        border-bottom: 0
        padding: 10px 15px 15px

      .input_group_collection_action
        border-color: $color2
        display: block

        &.remove_icon:after
          content: ''
          position: absolute
          right: 0
          top: 0
          background-image: url("../images/icons/template_generator_sprite_x2.png")
          background-size: $sprite_width_half
          width: 30px
          height: 30px
          background-position: -212px -7px

      .input_and_helper_container
        border-radius: 2px
        display: inline-block
        border: 1px solid $color5
        background-color: $color4

        &.warn
          border-color: $colorError

        &.left_aligned_label
          .input_field
            padding: 8px 0 8px 15px
          .input_helper
            margin: 0 0 0 5px
            padding-right: 0px

        &:hover
          background-color: #F8F9FF
          border-color: #c9c9c9

        &:focus
          border-color: $colorSky

        &.focused
          border-color: $colorSky

        .input_field
          display: inline-block
          border: 0
          padding: 8px 0 8px 10px
          font-size: 12px
          background: transparent
          &::placeholder
            color: #AAAAAA

        .input_helper
          cursor: text
          opacity: 0.5
          font-weight: normal

        .colorsquare_container
          display: inline-block
          background-color: $color5
          padding: 8px
          vertical-align: top
          .colorsquare
            width: 15px
            height: 15px
            border-radius: 2px

    .select_boxes
      cursor: pointer
      width: 50px
      height: 50px
      margin: 0 10px 0 0
      background-color: $color4
      border: 1px solid $color5
      color: $color2
      user-select: none
      &:hover
        background-color: #ebefff
        border-color: #c9c9c9
      &.selected
        background-color: color.adjust($colorSky, $lightness: 33%)
        border: 1px solid $colorSky
        color: $color1
      &.medium
        padding: 20px
        line-height: 10px
        font-size: 20px
        height: auto
        width: auto
      &.small
        padding: 15px
        line-height: 10px
        font-size: 20px
        height: auto
        width: auto
      &.extra_small
        padding: 5px 0 5px 5px
        margin: 0 10px 10px 0
        span
          background-color: $colorInk10
        &.line_0
          padding-right: 5px
          span
            display: block
            float: left
            height: 5px
            width: 35px
        &.line_5
          span
            display: block
            float: left
            width: 5px
            height: 5px
            margin-right: 5px
        &.line_2
          span
            display: block
            float: left
            width: 5px
            height: 5px
            margin-right: 3px
        &.line_1
          span
            display: block
            float: left
            width: 8px
            height: 5px
            margin-right: 5px
        &.line_6
          span
            display: block
            float: left
            width: 7px
            height: 5px
            margin-right: 3px
        &.line_7
          span
            display: block
            float: left
            width: 15px
            height: 5px
            margin-right: 5px

      &.underline, &.subheading_underline
        text-decoration: underline
      &.bold, &.subheading_bold
        font-weight: bold
      &.italic, &.subheading_italic
        font-style: italic
      &.uppercase, &.subheading_uppercase
        text-transform: uppercase

      &.left_align, &.center_align, &.right_align, &.justify_align, &.top_align, &.middle_align, &.bottom_align, &.autofit_none, &.autofit_normal, &.autofit_shape, &.filter_auto, &.filter_enabled, &.filter_starred, &.filter_unstarred
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: $sprite_width_forth
        background-repeat: no-repeat
      &.left_align
        background-position: 2px -148px
      &.center_align
        background-position: -48px -148px
      &.right_align
        background-position: -96px -148px
      &.justify_align
        background-position: -197px -348px
      &.top_align
        background-position: -248px 0px
      &.middle_align
        background-position: -248px -48px
      &.bottom_align
        background-position: -248px -94px
      &.autofit_none
        background-position: -248px -148px
      &.autofit_normal
        background-position: -248px -246px
      &.autofit_shape
        background-position: -248px -198px
      &.filter_auto
        background-position: -248px -298px
      &.filter_enabled
        background-position: -346px -347px
      &.filter_starred
        background-position: -247px -347px
      &.filter_unstarred
        background-position: -297px -347px

      &.bold, &.italic, &.underline, &.uppercase, &.subheading_bold, &.subheading_italic, &.subheading_underline, &.subheading_uppercase
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: $sprite_width_third
        background-repeat: no-repeat
      &.bold, &.subheading_bold
        background-position: -464px 5px
      &.italic, &.subheading_italic
        background-position: -464px -62px
      &.underline, &.subheading_underline
        background-position: -464px -129px
      &.uppercase, &.subheading_uppercase
        background-position: -464px -196px

      &.vertical_alignment_top, &.vertical_alignment_center, &.vertical_alignment_bottom, &.repeat_flow_sideways, &.repeat_flow_downwards, &.repeat_flow_no_repeat, &.paragraph_break_no_break, &.paragraph_break_break_after, &.balance_flow_sideways, &.document_orientation_landscape, &.document_orientation_portrait, &.document_size_a4, &.document_size_a3, &.document_size_letter, &.paragraph_keep_with_next, &.no_paragraph_bullet, &.paragraph_bullet, &.image_wrap_behind_text, &.image_wrap_in_front_of_text, &.three_columns, &.two_columns, &.single_column, &.cover_setting, &.cover_setting.disabled, &.section_type_newPage, &.section_type_continuous
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat
      &.vertical_alignment_top
        background-position: -500px -215px
      &.vertical_alignment_center
        background-position: -500px -205px
      &.vertical_alignment_bottom
        background-position: -500px -195px
      &.repeat_flow_sideways
        background-position: -300px 0px
      &.repeat_flow_downwards
        background-position: -300px -100px
      &.repeat_flow_no_repeat
        background-position: -300px -200px
      &.balance_flow_sideways
        background-position: -300px -300px
      &.paragraph_break_no_break
        background-position: -400px -100px
      &.paragraph_break_break_after
        background-position: -400px -200px
      &.document_orientation_portrait
        background-position: -400px -300px
      &.document_orientation_landscape
        background-position: -400px -400px
      &.document_size_a4
        background-position: -700px -400px
      &.document_size_letter
        background-position: -700px -500px
      &.document_size_a3
        background-position: -700px -600px
      &.paragraph_keep_with_next
        background-position: 0px -500px
      &.no_paragraph_bullet
        background-position: -300px -200px
      &.single_column
        background-position: -600px -200px
      &.two_columns
        background-position: -600px -300px
      &.three_columns
        background-position: -600px -400px
      &.paragraph_bullet
        background-position: 0px -100px
      &.image_wrap_behind_text
        background-position: -600px -100px
      &.image_wrap_in_front_of_text
        background-position: -600px 0px
      &.cover_setting
        background-position: 0px -700px
        &.disabled
          background-position: -100px -700px
      &.section_type_newPage
        background-position: -200px -700px
      &.section_type_continuous
        background-position: -300px -700px

    .repeat_wrapper
      .repeat_format_row
        line-height: 2px
        .repeat_format_select_box
          width: 2px
          height: 2px
          margin: 0 2px 2px 0
          &.selected
            width: 4px
            height: 4px

    .repeat_format_row
      line-height: 9px

      .repeat_format_select_box
        width: 14px
        height: 14px
        border: 1px solid $color2
        display: inline-block
        margin: 0 5px 5px 0

        &.selected, &.interactive:hover
          background-color: $color1
          border: none
          width: 16px
          height: 16px

        &:hover
          .repeat_format_info
            display: block

        .repeat_format_info
          display: none
          position: absolute
          top: 50%
          left: 150px
          font-size: 20px
          margin-top: -9px
          color: $color1
