// CSS Icons

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorPaper: #F5F4F2
$colorAvocado: #8ae133

// Plus icon for adding
.plus_icon
  display: block
  width: 3px
  height: 13px
  &:after
    content: ''
    position: absolute
    left: 7px
    top: 12px
    width: 13px
    height: 3px
    background: #FFF
  &:before
    content: ''
    position: absolute
    left: 12px
    top: 7px
    width: 3px
    height: 13px
    background: #FFF
//

// Handle for draggable objects
.handle
  cursor: pointer
  position: absolute
  left: -30px
  width: 22px
  height: 20px
  top: 50%
  margin-top: -10px
  z-index: 1
  cursor: grab
  .grid
    width: 4px
    height: 4px
    margin-right: 3px
    margin-bottom: 3px
    float: left
    background-color: #b8b8b8
// end helper items

// Indicator for actions on images
.action_handle_container
  position: absolute
  top: 5px
  right: 5px
  width: 5px
  padding: 5px 8px 3px
  background: #fdfdfd
  border: 1px solid #E7E5E1
  border-radius: 5px
  &:hover
    background: #fafafa
  .action_handle
    border-radius: 50%
    background: #4363FF
    width: 4px
    height: 4px
    margin-bottom: 3px
// end indicator

// ON/OFF - Switch
.switch
  position: absolute
  white-space: nowrap
  right: 60px
  top: 50%
  margin-top: -16px
  padding: 4px 8px 5px 8px
  line-height: 18px
  background-color: #F9F9F9
  border: 1px solid #E7E5E1

  border-radius: 2px

  transition: background .2s ease-in-out

  &.center_aligned
    top: 0
    left: 30px
    right: auto

  &.block_version
    display: inline-block
    position: relative
    margin: 0
    right: auto
    top: auto

  p
    display: inline-block
    font-size: 8pt
    letter-spacing: 1px

iframe#launcher
  display: none
