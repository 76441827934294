// Animations
@keyframes fadein_drive
  0%
    opacity: 0
    margin-left: -10%
  5%
    opacity: 1
  100%
    margin-left: 105%

// delay visibility
@keyframes delay_visibility
  0%
    visibility: hidden
  100%
    visibility: visible

// progressbar animation
@-webkit-keyframes progressing
  0%
    background-position: 0 0
  100%
    background-position: 50px 50px

@-moz-keyframes progressing
  0%
    background-position: 0 0
  100%
    background-position: 50px 50px

@-ms-keyframes progressing
  0%
    background-position: 0 0
  100%
    background-position: 50px 50px

@keyframes progressing
  0%
    background-position: 0 0
  100%
    background-position: 50px 50px
// END progressbar animation

// pulsate bait
@-webkit-keyframes pulsate
  0%
    border-color: #DDD
  50%
    border-color: #2e8ec8
  100%
    border-color: #DDD

@-moz-keyframes pulsate
  0%
    border-color: #DDD
  50%
    border-color: #2e8ec8
  100%
    border-color: #DDD

@-ms-keyframes pulsate
  0%
    border-color: #DDD
  50%
    border-color: #2e8ec8
  100%
    border-color: #DDD

@keyframes pulsate
  0%
    border-color: #DDD
  50%
    border-color: #2e8ec8
  100%
    border-color: #DDD
// END pulsate bait

// pulsate bait green
@-webkit-keyframes pulsate_green
  0%
    border-color: #D8D8D8
  50%
    border-color: #4363FF
  100%
    border-color: #D8D8D8

@-moz-keyframes pulsate_green
  0%
    border-color: #D8D8D8
  50%
    border-color: #4363FF
  100%
    border-color: #0e0c0c

@-ms-keyframes pulsate_green
  0%
    border-color: #D8D8D8
  50%
    border-color: #4363FF
  100%
    border-color: #D8D8D8

@keyframes pulsate_green
  0%
    border-color: #D8D8D8
  50%
    border-color: #4363FF
  100%
    border-color: #D8D8D8
// END pulsate bait green

// Marching Save states
@-webkit-keyframes upper_state
  0%
    opacity: 1
  50%
    opacity: 0.5
  100%
    opacity: 1

@-moz-keyframes upper_state
  0%
    opacity: 1
  50%
    opacity: 0.5
  100%
    opacity: 1

@-ms-keyframes upper_state
  0%
    opacity: 1
  50%
    opacity: 0.5
  100%
    opacity: 1

@keyframes upper_state
  0%
    opacity: 1
  50%
    opacity: 0.5
  100%
    opacity: 1
// END Marching Save states


// Marching dots
@-webkit-keyframes dot1
  0%
    opacity: 0
  25%
    opacity: 1

@-moz-keyframes dot1
  0%
    opacity: 0
  25%
    opacity: 1

@-ms-keyframes dot1
  0%
    opacity: 0
  25%
    opacity: 1

@keyframes dot1
  0%
    opacity: 0
  25%
    opacity: 1

@-webkit-keyframes dot2
  0%
    opacity: 0
  50%
    opacity: 1

@-moz-keyframes dot2
  0%
    opacity: 0
  50%
    opacity: 1

@-ms-keyframes dot2
  0%
    opacity: 0
  50%
    opacity: 1

@keyframes dot2
  0%
    opacity: 0
  50%
    opacity: 1

@-webkit-keyframes dot3
  0%
    opacity: 0
  75%
    opacity: 1

@-moz-keyframes dot3
  0%
    opacity: 0
  75%
    opacity: 1

@-ms-keyframes dot3
  0%
    opacity: 0
  75%
    opacity: 1

@keyframes dot3
  0%
    opacity: 0
  75%
    opacity: 1
// END Marching dots

// Loading spinner
@-webkit-keyframes spin
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0deg)

@-moz-keyframes spin
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0deg)

@-ms-keyframes spin
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0deg)

@-o-keyframes spin
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0deg)

@keyframes spin
  from
    transform: rotate(-360deg)
  to
    transform: rotate(0deg)
// END Loading spinner

// Card animation for dashboard
@-webkit-keyframes card_animate
  from
    transform: translate(0, -70px)
    opacity: 0
  to
    transform: translate(0, 0px)
    opacity: 1

@-moz-keyframes card_animate
  from
    transform: translate(0, -70px)
    opacity: 0
  to
    transform: translate(0, 0px)
    opacity: 1

@-ms-keyframes card_animate
  from
    transform: translate(0, -70px)
    opacity: 0
  to
    transform: translate(0, 0px)
    opacity: 1

@-o-keyframes card_animate
  from
    transform: translate(0, -70px)
    opacity: 0
  to
    transform: translate(0, 0px)
    opacity: 1

@keyframes card_animate
  from
    transform: translate(0, -70px)
    opacity: 0
  to
    transform: translate(0, 0px)
    opacity: 1
// End Card animation for dashboard

// Animate in opacity for tags
@-webkit-keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1

@-moz-keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1

@-ms-keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1

@-o-keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1

@keyframes animate_in_opacity
  from
    opacity: 0
  to
    opacity: 1
// End Animate in opacity for tags

// Animate modals
@-webkit-keyframes modal_show
  from
    opacity: 0
    transform: scale(0.7)
  to
    opacity: 1
    transform: scale(1)
@-moz-keyframes modal_show
  from
    opacity: 0
    transform: scale(0.7)
  to
    opacity: 1
    transform: scale(1)
@-ms-keyframes modal_show
  from
    opacity: 0
    transform: scale(0.7)
  to
    opacity: 1
    transform: scale(1)
@-o-keyframes modal_show
  from
    opacity: 0
    transform: scale(0.7)
  to
    opacity: 1
    transform: scale(1)
@keyframes modal_show
  from
    opacity: 0
    transform: scale(0.7)
  to
    opacity: 1
    transform: scale(1)
// End Animate modals

// Error animations
@-webkit-keyframes animSlideTop
  0%
    top: -100
  100%
    top: 0
@-moz-keyframes animSlideTop
  0%
    top: -100
  100%
    top: 0
@-ms-keyframes animSlideTop
  0%
    top: -100
  100%
    top: 0
@-o-keyframes animSlideTop
  0%
    top: -100
  100%
    top: 0
@keyframes animSlideTop
  0%
    top: -100
  100%
    top: 0
// End Error animations

// Initial accordion margin change
@-webkit-keyframes animAccordion
  0%
    margin-top: 0
    margin-bottom: 0
  100%
    margin-top: 20px
    margin-bottom: 20px
@-moz-keyframes animAccordion
  0%
    margin-top: 0
    margin-bottom: 0
  100%
    margin-top: 20px
    margin-bottom: 20px
@-ms-keyframes animAccordion
  0%
    margin-top: 0
    margin-bottom: 0
  100%
    margin-top: 20px
    margin-bottom: 20px
@-o-keyframes animAccordion
  0%
    margin-top: 0
    margin-bottom: 0
  100%
    margin-top: 20px
    margin-bottom: 20px
@keyframes animAccordion
  0%
    margin-top: 0
    margin-bottom: 0
  100%
    margin-top: 20px
    margin-bottom: 20px
// End Initial accordion margin change

// Preloading navigationbar gradient
@-webkit-keyframes placeHolderPreLoader
  0%
    background-position: -1500px 0
  100%
    background-position: 1500px 0
@-moz-keyframes placeHolderPreLoader
  0%
    background-position: -1500px 0
  100%
    background-position: 1500px 0
@-ms-keyframes placeHolderPreLoader
  0%
    background-position: -1500px 0
  100%
    background-position: 1500px 0
@-o-keyframes placeHolderPreLoader
  0%
    background-position: -1500px 0
  100%
    background-position: 1500px 0
@keyframes placeHolderPreLoader
  0%
    background-position: -1500px 0
  100%
    background-position: 1500px 0
// End preloading navigationbar gradient

// Preloading personal details input gradient
@-webkit-keyframes placeHolderPreLoader_input
  0%
    background-position: -1440px 0
  100%
    background-position: 1440px 0
@-moz-keyframes placeHolderPreLoader_input
  0%
    background-position: -1440px 0
  100%
    background-position: 1440px 0
@-ms-keyframes placeHolderPreLoader_input
  0%
    background-position: -1440px 0
  100%
    background-position: 1440px 0
@-o-keyframes placeHolderPreLoader_input
  0%
    background-position: -1440px 0
  100%
    background-position: 1440px 0
@keyframes placeHolderPreLoader_input
  0%
    background-position: -1440px 0
  100%
    background-position: 1440px 0
// End preloading personal details input gradient

// Preloading image gradient
@-webkit-keyframes placeHolderPreLoader_image
  0%
    background-position: -300px 0
  100%
    background-position: 300px 0
@-moz-keyframes placeHolderPreLoader_image
  0%
    background-position: -300px 0
  100%
    background-position: 300px 0
@-ms-keyframes placeHolderPreLoader_image
  0%
    background-position: -300px 0
  100%
    background-position: 300px 0
@-o-keyframes placeHolderPreLoader_image
  0%
    background-position: -300px 0
  100%
    background-position: 300px 0
@keyframes placeHolderPreLoader_image
  0%
    background-position: -300px 0
  100%
    background-position: 300px 0
// End preloading image gradient

// Reset animatino
@-webkit-keyframes reset_animation

@-moz-keyframes reset_animation

@-ms-keyframes reset_animation

@-o-keyframes reset_animation

@keyframes reset_animation
// End reset animation
