#history
  position: relative
  padding: 50px 0
  margin: 45px 0
  background-color: #F8F8F8

  h2
    text-align: center

  .fetch_more_items_activity
    text-align: center
    border-radius: 50%
    border-bottom: 1px solid #DEDEDE
    background-color: #FFF
    color: #999
    padding: 10px
    width: 18px
    position: absolute
    top: 100%
    left: 50%
    margin-left: -18px
    margin-top: -100px
    cursor: pointer
    z-index: 11

  .ghost_item
    width: 400px
    height: 102px
    background-color: #ffffff
    border-radius: 4px
    display: inline-block
    margin: 12px

    &:after
      content: ''
      position: absolute
      width: 100%
      height: 150px
      margin-top: -150px
      top: 100%
      left: 0
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(248,248,248,1) 50%)
      z-index: 10

  .placeholder_wrapper
    margin: 0 20px
    border-left: 2px solid #AAA

  .timeline_start, .timeline_stop
    position: absolute
    left: 9px
    top: 143px
    width: 21px
    height: 21px
    border-radius: 50%
    border: 2px solid #CCC
    background-color: #EEE

  .timeline_stop
    top: auto
    bottom: 30px

  .activity_log_wrapper
    transition: all 0.25s ease-in-out
    overflow: hidden
    margin: 0 20px
    border-left: 2px solid #CCC

  .activity
    position: relative
    width: 400px
    background-color: #ffffff
    border-radius: 4px
    border-bottom: 1px solid #dddddd
    display: inline-block
    margin: 12px
    text-align: left
    vertical-align: top

    img
      border-radius: 50%
      height: 80px
      padding: 10px 13px
      z-index: 10
      position: relative
      float: left

    .nopicture_placeholder
      position: relative
      float: left
      margin: 10px 13px

    p
      text-align: left
      color: #707070
      font-size: 14px
      font-weight: bold
      margin-bottom: 5px

    .updated_ago
      color: #B4B4B4
      margin-right: 5px

    .history_container
      padding: 10px
      width: 270px
      float: right

    .history_elements
      max-height: 62px
      overflow: hidden
      transition: all 0.25s ease-in-out

      li
        color: #707070
        margin-bottom: 5px
        font-size: 12px

    &.resizable
      cursor: pointer

      &:hover .increase_size
        display: block

      &.expand_element
        cursor: default
        .history_elements
          max-height: 200px
        .decrease_size
          display: block

        &:hover
          .increase_size
            display: none

      .history_elements
        overflow: auto

    .increase_size, .decrease_size
      // duplicate code with edit_cv
      position: absolute
      top: -15px
      right: -15px
      border: 3px solid #f8f8f8
      background-color: #FFF
      color: #b4b4b4
      width: 25px
      height: 25px
      line-height: 22px
      text-align: center
      border-radius: 50%
      display: none

    .increase_size
      background: #FFF url("../images/icons/dashboard_sprite_x2.png") 5px 5px no-repeat
      background-size: 50px
