@use "sass:color"

@use "bootstrap/mixins" as bootstrap-mixins

// Page settings
$mainFont: "IBM-Plex-Sans", "Helvetica Neue", Arial, sans-serif
$colorDarkerBlue: #318ec6

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorAvocado: #8ae133
$colorWhite: #FFFFFF
$colorButtercup: #F2C94C

$colorBeach: #ffefcd
$colorBeach_dark: #ffd375

// Rebrand colors
$colorRoyal500: #5B53FF

// State colors
$colorSelected: $colorSky
$colorFocus: $colorSky
$colorLink: $colorSky
$colorButtonPrimary: $colorInk
$colorButtonSecondaryBorder: #d6d6d6
$colorButtonSecondaryHover: #fafafa
$colorInputBorder: #d6d6d6
$colorInputHover: #F8F9FF
$colorInputEmpty: #f6fbff

// Graytones
$colorGray1: #E0DDDA
$colorGray2: #E7E5E1
$colorGray3: #EFEDEA
$colorPaper: #F5F4F2
$colorGray4: #F8F7F7
$colorGray5: #F9F9F9
$colorGray6: #FBFBFB

// Alerts
$colorError: #EB4925
$colorWarning: #FF9800
$colorSuccess: #4CAF50

// Sprites
$sprite_width: 200px 1100px
$sprite_width_third: 150px 824px
$sprite_width_half: 100px 550px

// Sectioncolors
$colorPersonalDetails: #E8E8E8
$colorKeyQualifications: #8DD3C7
$colorProjectExperiences: #FFED6F
$colorTechnologies: #BEBADA
$colorEducations: #FB8072
$colorWorkExperiences: #80B1D3
$colorCertifications: #FDB462
$colorCourses: #B3DE69
$colorPresentations: #FCCDE5
$colorRecommendations: #D9D9D9
$colorPositions: #BC80BD
$colorBlogs: #CCEBC5
$colorLanguages: #FFFFB3

// Sizes
$borderRadius: 10px
$borderRadiusButton: 20px

// Default heading styles
h1, h2, h3, h4, h5
  color: $colorInk

h2
  font-size: 26pt
  letter-spacing: 1px
  font-weight: bold
  margin-bottom: 10px

h3
  padding-bottom: 10px

body
  color: $colorInk

// Remove IE-input-styling
.remove_ie_input_styling
  &::-ms-clear
    width: 0
    height: 0

.clear
  clear: both

.interactive
  @include bootstrap-mixins.interactive
  label
    cursor: inherit

// default wrappers
.inlineblock_wrapper
  display: inline-block
  vertical-align: top
  &.top
    vertical-align: top !important
  &.middle
    vertical-align: middle !important
  &.bottom
    vertical-align: bottom !important
  &.sibling
    height: 100%
  &.float_right
    float: right

.relative_wrapper
  position: relative

.align_wrapper_left
  text-align: left
.align_wrapper_right
  text-align: right
.align_wrapper_center
  text-align: center
  > div, > li
    text-align: left
.align_wrapper_center_only
  text-align: center !important

.table_cell_wrapper
  display: table-cell !important
  word-break: break-word
  -ms-word-break: break-all
  &.top
    vertical-align: top
  &.middle
    vertical-align: middle
  &.variable
    width: 100%
  &.fixed_width_one
    width: 70px
    &.square
      height: 70px
  .table_cell_wrapper--divider
    margin-right: 30px
    position: relative
    &:after
      content: ''
      position: absolute
      right: 0px
      top: 0px
      height: 100%
      width: 1px
      background: #e4e4e4

.topdown_wrapper
  margin: 20px 0
  &.small
    margin: 10px 0
.topdown_async_margin
  margin: 20px 0 30px 0

.clipboard_wrapper
  background: #ececec
  padding: 10px
  margin: 0 0 10px 0
  border-radius: 5px
  cursor: pointer
  &:hover
    background-color: #DDD
    &:before
      border-color: transparent transparent #DDD transparent
  &:before
    content: ''
    position: absolute
    top: -5px
    left: 7px
    width: 0px
    height: 0px
    border-color: transparent transparent #ececec transparent
    border-width: 0px 5px 6px 5px
    border-style: solid
  span
    font-size: 14px
    color: #555

.department_wrapper
  position: absolute
  height: auto
  z-index: 1000
  background: $colorPaper
  max-width: calc(100vw - 41rem)
  &.group_search
    background: #f9f9f9
  .department_container
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    width: inherit
    &:hover
      white-space: normal
      overflow: visible

  .selected_office
    padding: 8px 2px 8px
    font-size: 12px
    letter-spacing: 1px
    color: $colorInk
    opacity: 0.7
    &:after
      content: ","
    &:last-child, &.without_comma
      &:after
        content: ""
    &.office_error
      color: #c72525
    &.and_separator
      font-weight: bold
      line-height: 18px
      text-transform: uppercase
      color: $colorInk

  .selected_tag
    &:last-child
      .and_separator
        display: none

  .clear_filters_action
    padding: 8px 2px 8px
    font-size: 12px
    letter-spacing: 1px
    position: absolute
    left: 100%
    top: 0px

.to_lowercase
  text-transform: lowercase

.backdrop_element
  position: absolute
  bottom: 30px
  left: -24px
  .highlight
    color: #AB0000
    font-weight: bold
    &:before
      content: '\0021'
      height: 18px
      width: 18px
    &:after
      content: '\21AA'
      height: 18px
      width: 18px
      position: absolute
      top: 3px
      left: 5px

.splitted_string
  word-break: break-word
  > span
    display: block
    white-space: pre-wrap

.highlighted_whitespace_wrapper
  .trailing_leading_whitespace
    border: 1px solid #ff3000
    background-color: #ffe1e1
    padding-left: 10px
    margin-left: 2px
    margin-right: 2px
    cursor: text
    border-radius: 4px

.overflow_auto
  overflow: auto

.mobile_only_wrapper
  display: none !important

.grid_wrapper
  display: grid
  &.threebythree
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: 10px
  &.twobytwo
    grid-template-columns: auto auto
  &.with_padding
    padding: 10px
  &.zebra
    &:nth-child(odd)
      background: #f8f8f8
  pre
    margin-bottom: 5px

.flex_wrapper
  display: flex !important
  flex-wrap: wrap
  margin: 0 -10px
  &.without_margin
    margin: 0
  &.flex_grow > div
    flex-grow: 1
  &.space_between
    justify-content: space-between
  &.baseline
    align-items: baseline
  &.v_align_center
    align-items: center
  &.columns
    flex-direction: column
  &.with_gap
    gap: 10px
  &.with_column_gap
    column-gap: 10px
  &.row_reverse
    flex-direction: row-reverse

.center_center_container
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  text-align: center
  z-index: 10
  .button
    display: inline-block
    vertical-align: middle

.modern_container
  box-shadow: 0px 0px 0px 1px rgba(224, 224, 224, 0.75)
  border-radius: $borderRadius
  background: $colorGray5

  &.no_overflow
    overflow: hidden

  .secondary_actions_block
    position: relative
    background-color: $colorGray5
    border-top: 1px solid $colorPaper
    letter-spacing: 0.03rem
    font-size: 12px
    text-align: left
    padding: 8px 8px
    border-bottom-right-radius: 10px
    border-bottom-left-radius: 10px

  .beta_label
    position: absolute
    top: -15px
    right: -60px
    padding: 30px 0 10px 0px
    width: 150px
    background: $colorInk
    z-index: 10
    color: #FFF
    rotate: 40deg
// end default wrappers

// Buttons
.button
  display: inline-block
  cursor: pointer
  padding: 7px 12px
  font-size: 12px
  letter-spacing: 1px
  font-weight: normal
  border: 1px solid transparent
  border-radius: 20px
  user-select: none
  box-sizing: border-box

  span
    color: inherit !important
    font-size: inherit !important

  svg
    display: inline-block
    vertical-align: top
    margin-top: 5px
    margin-left: 5px

  &.blue
    background-color: $colorButtonPrimary
    border: 1px solid $colorButtonPrimary
    color: $colorWhite
    &:hover
      background-color: color.adjust($colorButtonPrimary, $lightness: 5%)
      border-color: color.adjust($colorButtonPrimary, $lightness: 5%)

  &.blue_on_dark
    background-color: $colorSky
    border-color: $colorSky
    color: $colorWhite
    &:hover
      background-color: color.adjust($colorSky, $lightness: -5%)
      border-color: color.adjust($colorSky, $lightness: -5%)
  &.white_on_dark
    background-color: $colorWhite
    border-color: $colorWhite
    color: $colorInk
    &:hover
      border-color: color.adjust($colorWhite, $lightness: -5%)
      background-color: color.adjust($colorWhite, $lightness: -5%)
  &.icon_version
    border-radius: 50%
    padding: 7px 12px !important
  &.green
    text-shadow: 0px 1px 1px rgba(47, 155, 42, 0.5)
    color: #FFF !important
    @include bootstrap-mixins.color_style($colorAvocado, 1px)

  &.orange
    text-shadow: 0px 1px 1px rgba(155, 101, 42, 0.5)
    color: #FFF !important
    @include bootstrap-mixins.color_style(#ff931e, 1px)

  &.white, &.gray
    background-color: $colorWhite
    border-color: $colorInputBorder
    color: $colorInk
    &:hover
      background-color: $colorButtonSecondaryHover

    &.selected
      background-color: $colorSelected
      color: $colorWhite
      border-color: $colorSelected
      &:hover
        background-color: color.adjust($colorSelected, $lightness: 5%)
        border-color: color.adjust($colorSelected, $lightness: 5%)

  &.ai_button
    position: relative
    padding-left: 32px
    background: linear-gradient(339deg, #7f60ff1f, transparent)
    &:before
      content: '✨'
      font-size: 16px
      position: absolute
      top: 3px
      left: 10px
      color: transparent
      text-shadow: 0 0 0 rgb(138, 37, 238)

  &.remove_button
    position: relative
    padding-right: 32px
    &:after
      content: '✖'
      font-size: 14px
      position: absolute
      top: 6px
      right: 12px

  &.feature_button
    position: relative
    padding-left: 32px
    background: linear-gradient(339deg, #607bff14, transparent)
    &:before
      content: '➪'
      font-size: 18px
      position: absolute
      top: 3px
      left: 8px
      rotate: 270deg
      color: $colorSky

  &.white_text
    color: #FFF

  &.blue_text
    color: $colorRoyal500
    border-radius: 0
    padding-left: 0
    padding-right: 0
    &:hover
      text-decoration: underline

  &.red
    background-color: $colorError
    border-color: $colorError
    color: #FFF !important
    &:hover
      background-color: color.adjust($colorError, $lightness: -10%)
      border-color: color.adjust($colorError, $lightness: -10%)

  &.locked
    cursor: not-allowed
    padding-right: 30px !important
    &:after
      content: ""
      background: url("../images/icons/icon_sprite_x2.png")
      background-repeat: no-repeat
      background-size: 200px
      background-position: -95px -125px
      height: 23px
      width: 26px
      position: absolute
      right: 6px
      top: 3px

  &.input_button
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    padding: 9px 12px
    min-height: 38px
    border-left: 0
    vertical-align: top

  &.bigger_button
    padding: 10px 16px !important

  &.bigger_button_extra
    padding: 12px !important

  &.absolute_action
    position: absolute
    top: 14px
    right: 14px
    z-index: 10
    &.right_aligned
      top: auto
      bottom: 9px
      right: 15px

  &.float_right
    float: right

  &.label_only
    background-color: #F8f8f8
    color: #999 !important
    text-shadow: none
    border-color: #F8f8f8
    cursor: default

  &.filter_options
    vertical-align: top

  &.bigger_font_size
    font-size: 14px

  &.full_width
    display: block

  &.disabled
    cursor: default

  &.margin_top_bottom
    margin: 5px 0

  &.margin_left
    margin-left: 10px

  &.margin_right
    margin-right: 10px

  &.extra_margin_right
    margin-right: 30px

  &.half_margin_top
    margin-top: 6px

  &.margin_top
    margin-top: 8px

  &.extra_margin_top
    margin-top: 15px

  &.margin_bottom
    margin-bottom: 6px

  &.margin_bottom_10
    margin-bottom: 10px

  &.extra_margin_bottom
    margin-bottom: 20px

  &.minor_padding
    padding: 4px !important

  &.hide_file_input
    input
      cursor: pointer !important
      position: absolute !important
      padding: 0 !important
      margin: 0 !important
      border: 0 !important
      top: 0 !important
      width: 100% !important
      left: 0 !important
      height: 100% !important
      opacity: 0

// end buttons

// nopicture settings
.nopicture_placeholder
  color: $colorInk
  background-color: #ffefcd
  border: 1px solid #ffefcd
  box-sizing: border-box
  text-align: center
  height: 100%
  width: 100%
  font-size: 80px
  line-height: 165px
  cursor: default
  border-radius: 50%

  &.small
    width: 70px
    height: 70px
    font-size: 35px
    line-height: 64px

  &.thumbnail
    float: left
    height: 35px
    width: 35px
    font-size: 16px
    line-height: 33px

    &.lineup_fix
      margin-top: -1px
      margin-right: 10px

  &.medium
    float: left
    height: 42px
    width: 42px
    font-size: 18px
    line-height: 38px

  &.medium_large
    height: 50px
    width: 50px
    font-size: 24px
    line-height: 46px

  &.large
    line-height: 180px

  &.modal
    line-height: 63px
    font-size: 24px
    border-radius: 50%

  &.with_margin_left
    margin-left: 10px

  &.with_margin_right
    margin-right: 10px

  &.interactive
    @include bootstrap-mixins.interactive

  &.with_image
    border: 0
    background: none
// nopicture end

// breadcrumbs
.breadcrumbs_container
  display: block
  height: 42px

  .breadcrumbs
    z-index: 1050
    background: $colorWhite
    border-bottom: 1px solid #DCDCDC
    font-size: 14px
    margin-top: 46px
    position: fixed
    top: 10px
    left: 0px
    right: 0px
    padding: 15px 50px

    a
      color: $colorRoyal500
      &.button.blue
        color: $colorWhite
// end

// upload_image_form
.upload_file
  .button
    input
      cursor: pointer !important
      position: absolute !important
      padding: 0 !important
      margin: 0 !important
      border: 0 !important
      top: 0 !important
      width: 100% !important
      left: 0 !important
      height: 100% !important
      opacity: 0

.upload_image
  position: relative
  padding: 40px 15px 40px
  font-size: 12px
  color: #999999
  text-align: center
  &.hovered
    background-color: #DDD
    border-radius: 5px

  .photo_icon
    display: inline-block
    background-image: url("../images/icons/upload_photo_sprites.png")
    background-position: 0px 0px
    width: 34px
    height: 40px
    background-repeat: no-repeat

  .button
    input
      cursor: pointer !important
      position: absolute !important
      padding: 0 !important
      margin: 0 !important
      border: 0 !important
      top: 0 !important
      width: 100% !important
      left: 0 !important
      height: 100% !important
      opacity: 0

// live information bubble
#live_bubble_container
  position: fixed
  bottom: 20px
  left: 20px
  z-index: 214748299
  .secondary_context_action
    display: inline-block
    vertical-align: middle
    padding: 10px 14px 9px
    margin: 8px 10px 10px 30px
    border-radius: 20px
    font-size: 12px
    user-select: none
    color: #FFF !important
  .close_bubble
    position: absolute
    right: 0
    top: -5px
    width: 15px
    height: 15px
    z-index: 100
    background-color: #666
    border: 2px solid #555
    border-radius: 50%
    background-image: url("../images/icons/table_list_sprite.png")
    background-position: -88px -29px
    background-size: 250px
    cursor: pointer
  .message_bubble
    display: inline-block
    vertical-align: top
    height: 52px
    margin-left: -26px
    background-color: $colorInk
    border-top-right-radius: 20px
    border-bottom-right-radius: 20px
  .circle_bubble
    display: inline-block
    cursor: pointer
    width: 46px
    height: 46px
    background-color: $colorInk
    padding: 3px
    border-radius: 50%
    position: relative
    .bubble_icon
      height: 100%
      width: 100%
      border-radius: 50%
      position: relative
      overflow: hidden
      box-sizing: border-box
      &.progressing
        border: 2px solid $colorInk
        background-color: $colorSelected
        &:after
          content: ""
          position: absolute
          top: 0
          left: 0
          bottom: 0
          right: 0
          background-image: linear-gradient( -45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent )
          z-index: 1
          background-size: 50px 50px
          animation: progressing 2.5s linear infinite
          overflow: hidden

      &.completed
        border: 2px solid #FFFFFF
        background-color: $colorAvocado
        &:after
          content: ""
          position: absolute
          background-image: url("../images/icons/new_cv_sprites_x2.png")
          background-repeat: no-repeat
          background-size: $sprite_width
          background-position: -154px -254px
          width: 100%
          height: 100%
          left: 0px
          top: 0px

// progress bar
.progressbar_container
  display: inline-block
  height: 20px
  position: relative
  background: #FFF
  border-radius: 12px
  padding: 4px
  width: 400px
  margin-top: 12px
  border: 1px solid #CCC
  box-shadow: inset 0 1px #F7F7F7, 0px 1px #FFF

  .progressbar_label
    position: absolute
    left: 0px
    top: -17px
    text-align: center
    width: 100%
    font-size: 12px
    color: #999

  .progressbar
    display: block
    height: 100%
    border-top-left-radius: 14px
    border-top-right-radius: 14px
    border-bottom-left-radius: 14px
    border-bottom-right-radius: 14px
    background-color: $colorSelected
    position: relative
    overflow: hidden
    border: 1px solid $colorInk
    box-sizing: border-box

    &:after
      content: ""
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      background-image: linear-gradient( -45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent )
      z-index: 1
      background-size: 50px 50px
      animation: progressing 2.5s linear infinite
      overflow: hidden
// end progressbar

// icon_container
.icon_container
  width: 30px
  height: 30px
  user-select: none

  i
    background-image: url("../images/icons/icon_sprite_x2.png")
    background-repeat: no-repeat
    background-size: 200px
    width: 100%
    height: 100%
    display: block

  .star_icon
    background-position: -60px -30px
    &.enabled
      background-position: -60px -60px
// end icon container

// settings icons
.settings_icons
  width: 170px
  height: 170px
  background: url("../images/icons/settings_icons_sprite_x2.png")
  background-size: 170px 1360px
  margin-top: -85px
  top: 50%
  left: 0
  position: absolute
  &.dropbox
    background-position: 0px 0px
  &.sftp
    background-position: 0px -170px
  &.disabled_users_compliance
    background-position: 0px -340px
  &.privacy_notice
    background-position: 0px -510px
  &.help_guides
    background-position: 0px -680px
  &.help_chat
    background-position: 0px -850px
  &.help_mail
    background-position: 0px -1020px
  &.updates
    background-position: 0px -1190px
// end settings icons

// info bubble
.icon_bubble
  width: 40px
  height: 40px
  margin-right: 10px
  background-color: $colorWhite
  border: 1px solid $colorGray2
  border-radius: 50%
  user-select: none

  &.vertical_bubble
    margin-right: 0
    margin-bottom: 2px

  &.interactive
    cursor: pointer

  &.green_border
    border-color: #7ac359

  &.without_border
    border: 0

  &.without_background
    background-color: transparent

  &.with_white_background
    background-color: #FFF

  &.absolute_aligned
    position: absolute
    left: 2px
    top: 2px

  &.small_icon
    width: 27px
    height: 27px

  &.no_tooltip
    overflow: hidden

  img
    height: 100%
    border-radius: 50%

  i
    background-image: url("../images/icons/icon_sprite_x2.png")
    background-repeat: no-repeat
    background-size: 200px
    width: 100%
    height: 100%
    display: block

  .persons
    background-position: 9px 10px
  .owner
    background-position: -22px 10px

  .tag
    background-position: 8px -85px

    &.blue
      background-position: -90px -85px

  .key
    background-position: -89px -55px

  .office
    background-position: -120px -85px

  .info
    background-position: -55px 4px

  .download
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width_third
    background-position: -224px -223px

  .goto_link
    background-position: -85px -148px

  .upload_image_icon
    background-position: -55px -85px

  .preview
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width_third
    background-position: -224px -598px

  .collapse
    position: absolute
    top: 7px
    left: 7px
    &:after
      content: ''
      position: absolute
      left: 6px
      top: 7px
      border-style: solid
      border-width: 0 7px 10px 7px
      border-color: transparent transparent #999 transparent

  .plus
    // Duplicate of plus_icon
    &:after
      content: ''
      position: absolute
      left: 7px
      top: 12px
      width: 13px
      height: 3px
      background-color: $colorAvocado
    &:before
      content: ''
      position: absolute
      left: 12px
      top: 7px
      width: 3px
      height: 13px
      background-color: $colorAvocado
// end info bubble

// start autosuggest
.autosuggest
  position: absolute
  top: 100%
  left: 0
  font-weight: bold
  font-size: 12px
  letter-spacing: 0.03rem
  z-index: 1000
  box-sizing: border-box
  border-bottom-right-radius: 10px
  border-bottom-left-radius: 10px
  background-color: #FFF
  box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)
  min-width: 100%
  text-align: left

  &.nowrapping
    white-space: nowrap

  .autosuggest_header
    padding: 10px
    font-weight: normal
    font-size: 10px
    text-transform: uppercase
    letter-spacing: 0.1rem
    border-bottom: 1px solid #ffe1a0
    background-color: #ffefcd
    box-sizing: border-box
    white-space: pre
    &.company_instructions
      border-bottom: 1px solid $colorAvocado
      background-color: #F3FFE7

  .autosuggest_element
    cursor: pointer
    padding: 10px
    border-bottom: 1px solid #EAEAEA
    box-sizing: border-box
    position: relative

    &:last-child
      border-bottom: 0
      border-bottom-right-radius: 10px
      border-bottom-left-radius: 10px
      &:hover
        &:before
          border-bottom-left-radius: 10px

    &:first-child
      border-top: 0

    &:hover
      &:before
        content: ''
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 4px
        background-color: $colorSelected

    &:focus
      background-color: $colorSelected
      color: #FFF

    &.selected
      background-color: $colorSelected
      color: #FFF

    .hit
      color: $colorSelected
      margin-right: 0.05rem
// End autosuggest

// Contextual label help (?)
// TODO: Move inside block_section to get rid of important
.contextual_label_help
  height: 15px
  width: 15px
  border-radius: 50%
  background: #FFF
  text-align: center !important
  font-weight: normal
  font-size: 11px !important
  line-height: 14px
  letter-spacing: normal
  display: inline-block
  margin-left: 7px
  // offset to avoid pushing cv inputs down
  margin-bottom: -1px
  box-shadow: 0px 0px 0px 1px $colorInk
  .tooltip span
    font-size: 11px !important
    font-weight: normal !important
    color: #FFF !important
  &.small_variant
    box-shadow: 0px 0px 0px 1px #fff
    background: #f1f1f1
    span
      font-size: 10px !important

// End contextual label help (?)

// Start standard tooltip
.tooltip_wrapper
  position: relative
  .tooltip_outer
    width: 200px
    position: absolute
    right: 0
    top: 0
    z-index: 1000
    &.move_right
      top: auto
      right: auto
      left: 0
      bottom: 0
  .tooltip
    position: absolute
    top: -30px
    right: 0px
    min-width: 14px
    font-size: 11px !important
    color: #FFF !important
    font-weight: normal !important
    text-transform: none !important
    font-family: $mainFont
    letter-spacing: 0.04rem
    padding: 5px 8px 6px 8px !important
    white-space: nowrap
    background-color: $colorInk
    border-radius: 5px
    z-index: 1000
    line-height: 140%
    text-transform: none
    display: none

    &:before
      width: 0
      height: 0
      content: ''
      bottom: -6px
      right: 10px
      position: absolute
      border-style: solid
      border-color: $colorInk transparent transparent transparent
      border-width: 6px 5px 0 5px

    span
      font-size: 11px !important
      font-weight: normal !important
      color: #FFF !important

    &.contrast_border
      border: 2px solid #FFF

    &.multi_line
      top: auto
      bottom: 100%
      right: -5px
      margin-bottom: 10px
      text-align: left
      line-height: 140%
      width: auto
      max-width: 200px
      min-width: 120px
      white-space: pre-line
      &.below.left
        right: -8px
        margin-top: 25px
      .left
        left: calc(100% - 22px)
        right: auto
    &.left_menu_tooltip
      left: 100%
      top: 10px
      right: auto
      padding: 10px
      margin-left: 20px
      &:before
        border-width: 5px 6px 5px 0
        border-color: transparent #555 transparent transparent
        right: 100%
        bottom: 10px
    &.left
      right: 0
      left: auto

    &.right
      left: calc(100% - 22px)
      right: auto

      &.normalise
        left: 0

      &:before
        left: 10px
        right: auto

    &.below
      top: calc(100% + 13px)
      left: 0px
      bottom: auto
      margin-top: 10px
      margin-bottom: 0
      right: auto

      &:before
        bottom: 100%
        left: 10px
        right: auto
        border-color: transparent transparent $colorInk transparent
        border-width: 0px 5px 6px 5px

      &.left
        left: auto
        right: 0px

        &:before
          left: auto
          right: 10px

      &.right
        left: calc(100% - 22px)
        right: auto

        &:before
          left: 10px
          right: auto

    &.red_version
      background: $colorError
      &:before
        border-top-color: $colorError
      &.below
        &:before
          border-top-color: transparent
          border-bottom-color: $colorError

  &.always_active
    .tooltip
      display: block
  &:hover
    .tooltip
      display: block
  &.disable_tooltip
    .tooltip
      display: none

// End standard tooltip

// Preloader / Progress
.loader
  background: url("@cvpartner/design-system/logos/royalBlueIcon.svg") no-repeat
  background-size: 100%
  width: 100px
  height: 130px
  position: fixed
  top: 50%
  left: 50%
  margin-left: -50px
  z-index: 10000
  opacity: 0
  animation: animate_in_opacity 2s 0.5s infinite alternate
// end preloader

// context menu for draggable actions or selected actions
.context_menu
  position: fixed
  top: 100%
  left: 0
  z-index: 999
  width: 100%
  height: auto
  min-height: 130px
  max-height: 200px
  overflow: auto
  background: $colorInk
  color: #FFF
  text-align: center
  transition: transform 0.5s cubic-bezier(0.6,0,0.4,1), max-height 0.5s cubic-bezier(0.6,0,0.4,1)
  transform: translate3d(0,20px,0)
  opacity: 0

  &.enable_loading
    .dot_one, .dot_two, .dot_three
      animation: dot1 1s infinite steps(1)

    .dot_two
      animation-name: dot2

    .dot_three
      animation-name: dot3

  &.extra_size
    max-height: 90%

  &.show_context
    transform: translate3d(0,-100%,0)
    opacity: 1

  &.progress_activated
    min-height: 0px

  .block_container label, .progressbar_container .progressbar_label
    color: #FFF !important
    top: -20px

  .progressbar_container
    height: 23px
    vertical-align: middle

  .context_icon
    width: 46px
    height: 46px
    border-radius: 50%
    border-width: 2px
    border-style: solid
    border-color: #FFFFFF
    position: relative
    overflow: hidden

    &.green
      background-color: #84D152

  .context_information
    font-size: 10px
    margin-bottom: 5px
    .info_element
      background-color: color.adjust($colorInk, $lightness: 5%)
      border-radius: 5px
      padding: 6px 6px
      margin: 0 10px 10px 0

  h1
    margin-bottom: 20px
    font-size: 20px
    color: #FFF

  select
    max-width: 600px
    margin-bottom: 10px
    margin-right: 10px

  i
    background-image: url("../images/icons/new_cv_sprites_x2.png")
    background-repeat: no-repeat
    background-size: $sprite_width_half
    width: 100%
    height: 100%
    display: block

    &.x_cross
      background-position: -50px -75px

    &.completed
      background-size: $sprite_width
      background-position: -150px -250px
      position: absolute
      left: 0px
      top: 0px

  .close_context_menu
    cursor: pointer
    position: absolute
    top: 5px
    right: 5px
    width: 25px
    height: 25px
    padding: 5px
    border-radius: 50%
    background-color: #FFFFFF

  .field_type_text
    text-transform: uppercase
    margin-bottom: 5px

  .list_holder
    margin: 0 0 20px 0

  .top_div_margin
    height: 20px

  .information_block
    font-size: 20px
    font-weight: bold
    margin-left: 20px

  .context_action_info
    font-size: 14px
    font-style: italic

  .diff_container
    margin-right: 50px

  .context_option
    display: inline-block
    padding: 15px 5px 0px 20px
    border: 1px solid $colorWhite
    margin-bottom: 20px
    border-radius: 5px

    &.with_bottom_padding
      padding-bottom: 5px

  .tag_block
    padding: 8px 15px
    font-size: 12px
    background-color: #FFF
    color: $colorInk
    margin: 0 20px 20px 0
    border-radius: 4px
    max-width: 300px
    min-width: 50px
    word-break: break-word
    letter-spacing: 1px

    &.with_right_action
      background: transparent
      border-color: $colorSky

    &.extended_size
      max-width: 500px

    &.added_subsection
      border-left: 5px solid $colorAvocado
      border-color: $colorAvocado

    &.modified_subsection
      border-left: 5px solid $colorButtercup
      border-color: $colorButtercup

    &.only_in_master_subsection
      border-left: 5px solid $colorButtercup
      border-color: $colorButtercup

    img
      max-height: 50px

    .selected_text
      position: absolute
      top: 100%
      right: 0
      margin-top: 2px
      color: #FFF
      font-size: 10px

    .other_languages
      font-style: italic

    .entity
      line-height: 140%
      .entity_heading
        font-weight: bold
        margin-bottom: 5px
      .entity_emphasize
        opacity: 0.7
        &.language
          text-align: right
          font-size: 10px
          margin-top: 10px
      .added
        position: relative
        z-index: 1
        &::after
            content: ''
            position: absolute
            display: block
            background: #cefe7d
            width: 100%
            height: 100%
            left: 0
            top: 0
            z-index: -1
            border-radius: 4px
      .removed
        color: red
        text-decoration: line-through
        > span
          color: $colorInk

        &.carriage_return
          background-color: #ffcece

      .carriage_return
        display: block
        height: 14px

    &.image_height
      height: 50px

    &.without_background
      background-color: transparent
      font-style: italic
      color: $colorWhite
      border-color: transparent

    &.with_right_action
      cursor: pointer
      padding-right: 32px
      user-select: none
      .right_action
        position: absolute
        top: 5px
        right: 10px
        width: 20px
        height: 20px

    &.with_left_action
      padding-left: 35px

    &.selectable
      cursor: pointer
      user-select: none
      background: $colorWhite

      &.selected
        &:after
          content: ''
          position: absolute
          left: -2px
          top: -2px
          width: 100%
          height: 100%
          display: block
          border: 2px solid $colorSky
          border-radius: 7px

        &:before
          content: ''
          position: absolute
          right: -12px
          top: -12px
          width: 26px
          height: 26px
          background: url("../images/icons/new_cv_sprites_x2.png")
          background-size: $sprite_width_half
          background-position: 26px -123px
          background-color: $colorSky
          border-radius: 50%
          z-index: 10


// context_menu end

// contextual information for textareas
.input_toggle_translation
  position: absolute
  bottom: 100%
  right: 0
  font-size: 12px
  padding-bottom: 6px
  padding-left: 8px
  padding-top: 2px
  opacity: 0.7
  @include bootstrap-mixins.interactive
  z-index: 10

.saved_message
  position: absolute
  top: 100%
  left: 0
  padding-top: 2px
  color: #999999 !important
  font-size: 9px !important
  letter-spacing: 2px
  text-transform: uppercase
  transition: 0.5s all ease-in-out
  opacity: 0

  .action
    font-size: 9px !important

  &.saved
    opacity: 1

  &.overlay_saved_message
    padding: 0 15px 2px
    margin-top: -14px
    background: #F8f8f8

  &.international
    top: 80px

  .underlined
    border-bottom: 1px solid #CCC
    padding-bottom: 1px

  &.contextual_action
    left: auto
    right: 0
    text-transform: none

// end contextual information for textareas

// Section support for helptexts
.sectionSupport
  padding: 10px 10px 10px 10px
  margin: 5px 0 20px 0
  color: $colorInk
  background-color: #FFFDD3

  font-size: 13px
  line-height: 140%
  letter-spacing: 0.02rem
  position: relative
  border-radius: 10px
  box-sizing: border-box

  a
    color: $colorSky
    margin-right: 10px
    text-decoration: underline

  &.company_instructions
    background-color: #F3FFE7
    padding: 15px 20px
    min-height: 70px

  &.without_margin_bottom
    margin-bottom: 0

  &.delay_visibility
    visibility: hidden
    animation: delay_visibility
    animation-delay: 1.5s
    animation-fill-mode: forwards

  &.textarea_instructions
    font-style: italic
    color: #747474
    padding: 0
    margin: 10px 0 0 0
    background: none

  .sectionSupport_context
    font-weight: bold

  .sectionSupport_content
    max-height: 73px
    overflow: hidden
    display: block
    transition: all 0.1s ease-in-out

  &.resizable
    cursor: pointer
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0
    border-bottom: 0

    &:after
      content: ""
      position: absolute
      width: 100%
      height: 35px
      bottom: 0px
      left: 0
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,#F4FCE8 60%)

    &:hover
      .floating_button
        background-color: $colorButtonSecondaryHover

    .floating_button_container
      display: block
      .increase_size
        display: inline-block

    &.activated
      border-radius: 10px
      .sectionSupport_content
        overflow-y: auto
        max-height: 400px

      &:after
        display: none

      .floating_button_container
        display: block
        position: relative
        top: auto
        left: auto
        margin: 10px 0 0 0

        .decrease_size
          display: inline-block
        .increase_size
          display: none

  &.first_section

    .floating_button_container
      display: none !important

    .sectionSupport_content
      overflow-y: auto
      max-height: 1000px

    &:after
      display: none

  .floating_button_container
    display: none
    position: absolute
    top: 100%
    left: 60px
    right: 0
    margin-top: -50px
    z-index: 1
    .floating_button
      display: none
      cursor: pointer
      padding: 7px 12px
      font-size: 12px
      letter-spacing: 1px
      box-sizing: border-box
      background-color: $colorWhite
      border: 1px solid $colorButtonSecondaryBorder
      color: $colorInk
      border-radius: 20px
      user-select: none

// Add new element - expanding
.slider_holder
  width: 100%
  overflow: hidden
  margin-top: 0px
  height: 0px
  transition: all 0.5s ease-in-out

  &.expand_slider_holder
    height: 120px
    margin-top: 10px
  &.without_top_margin
    margin-top: 0
// end of multislider //

// general dropdown container

.dropdown_container_container
  position: relative
  display: inline-block
  vertical-align: top
  &:after
    content: ''
    position: absolute
    right: 15px
    top: 50%
    margin-top: -1px
    width: 0
    height: 0
    border-style: solid
    border-width: 5px 5px 0 5px
    border-color: $colorInk transparent transparent transparent
    pointer-events: none

  select:focus-visible
    outline: none

  &.with_margin_bottom
    margin-bottom: 10px

  &.with_margin_right
    margin-right: 10px

  // Hide the dropdown arrow when sensitive data in proposal
  &.sensitive
    &.proposal:after
      border-style: none

// end general dropdown container

// new general dropdown //
.dropdown_container
  display: inline-block
  vertical-align: top
  cursor: pointer
  position: relative
  padding: 7px 32px 7px 12px
  font-size: 12px
  letter-spacing: 1px
  text-align: left
  background-color: $colorWhite
  border: 1px solid
  border-color: $colorButtonSecondaryBorder
  color: $colorInk
  border-radius: 20px
  appearance: none
  font-family: $mainFont

  &:after
    content: ''
    position: absolute
    right: 15px
    top: 50%
    margin-top: -1px
    width: 0
    height: 0
    border-style: solid
    border-width: 5px 5px 0 5px
    border-color: $colorInk transparent transparent transparent
    pointer-events: none

  &:hover
    background-color: $colorButtonSecondaryHover

  &:focus
    border-color: $colorFocus !important
    & + .dropdown_container
      border-color: $colorFocus !important

  &.offset_native_dropdown
    .selected_dropdown
      padding: 10px
      margin: -10px
      padding-right: 30px
      margin-right: -30px
    ul
      label
        padding: 10px
        display: flex
        gap: 10px

  &.active_dropdown
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0px
    &:after
      border-width: 0 5px 5px 5px
      border-color: transparent transparent $colorInk transparent

  sup
    vertical-align: baseline
    position: relative
    top: -0.4em

  &.without_margin
    margin: 0

  &.with_margin_bottom
    margin-bottom: 10px

  &.float_right
    float: right

  &.with_margin_right
    margin-right: 10px

  &.with_margin_left
    margin-left: 10px

  &.with_left_icon
    padding-left: 30px

  &.full_width_block
    display: block
    border-radius: 0

  .selected_dropdown, .selected_dropdown span
    @include bootstrap-mixins.interactive
    color: inherit !important
    font-weight: inherit !important
    font-size: inherit !important
    letter-spacing: inherit !important
    display: inline-block
    &.uppercase
      text-transform: uppercase

  .dropdown_search
    input
      padding: 8px
    &:before
      display: none

  // autosuggest duplicate styles
  ul
    position: absolute
    top: calc(100% + 1px)
    left: 0
    z-index: 1000
    background: #FFF
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)
    box-sizing: border-box
    border-bottom-right-radius: 3px
    border-bottom-left-radius: 3px
    min-width: 100%
    max-width: 300px
    font-weight: inherit !important
    font-size: inherit !important
    font-weight: bold
    text-align: left
    li
      @include bootstrap-mixins.interactive
      padding: 10px
      font-size: 12px
      border-bottom: 1px solid #EAEAEA
      box-sizing: border-box
      letter-spacing: 0.03rem
      position: relative
      color: inherit
      font-weight: normal
      font-size: 12px
      font-color: $colorInk
      min-width: 150px
      min-height: 36px
      font-weight: bold

      &:last-child
        border-bottom: 0
        border-bottom-right-radius: 3px
        border-bottom-left-radius: 3px
        &:hover
          &:before
            border-bottom-left-radius: 3px

      &:first-child
        border-top: 0

      &:hover
        &:before
          content: ''
          position: absolute
          left: 0
          top: 0
          bottom: 0
          width: 4px
          background-color: $colorRoyal500

      &:focus
        background-color: $colorRoyal500
        color: #FFF

      &.selected
        background-color: $colorRoyal500
        color: #FFF

      &.link_override
        padding: 0
        a
          position: relative
          padding: 10px
          display: block
          color: #555
          svg
            visibility: hidden
            width: 18px
            height: 18px
            position: absolute
            top: 50%
            transform: translateY(-50%)
            right: 10px
        a[data-selected='true']
         svg
          visibility: visible

      label
        cursor: pointer

  &.absolute_aligned
    position: absolute
    right: 10px
    top: 0px
    margin: 0px

  &.with_half_margin_top
    margin-top: 5px

  &.breadcrumb_aligned
    margin-top: -6px
    margin-bottom: -12px
    margin-left: 100px

  &.block
    display: block

  &.selectable_dropdown
    background: transparent
    font-size: 14px
    font-weight: bold
    padding: 10px 35px 11px 11px
    color: $colorInk
    background: $colorWhite
    border: 1px solid $colorSelected

    &:after
      border-color: $colorInk transparent transparent transparent
      top: 20px

    &.not_active
      border-color: $colorInputBorder
      &:hover
        background: $colorButtonSecondaryHover
    &.not_active
      &:after
        border-color: $colorInk transparent transparent transparent


  &.gray
    background-color: #F8F8F8
    color: #555
    font-weight: bold
    border: 1px solid #D5D5D5
    &.table_align
      border-width: 0px 1px 1px 0px

  &.light_gray
    background-color: #eee
    font-weight: bold
    border: 1px solid #D5D5D5
    border-width: 0px 1px 1px 0px

  &.disabled
    background-color: #EEEEEE
    cursor: not-allowed
    &:after
      display: none

  &.disabled_dropdown
    padding-right: 12px !important
    &:after
      display: none

  &.navigation_dropdown
    padding: 0 40px 0px 12px
    height: 50px
    background-color: #fdfdfd
    color: $colorInk
    border-left: 1px solid #E4E4E4
    border-right: 1px solid #E4E4E4
    border-top: 0
    border-bottom: 0
    border-radius: 0px
    font-size: inherit
    vertical-align: middle
    letter-spacing: 0.01rem
    &:after
      border-color: $colorInk transparent transparent transparent
      border-width: 5px 5px 5px 5px

    &:hover
      background-color: #fafafa

    span, a
      overflow: hidden
      display: inline-block
      vertical-align: middle
      max-width: 250px
      text-overflow: ellipsis
      white-space: nowrap
    @at-root
      a#{&}
          color: $colorSky

    &.disabled
      cursor: default !important
      padding-right: 12px
      background: transparent
      border: none
      color: $colorInk
      &:hover
        background: transparent

    &:after
      top: 22px

  &.without_right_border
    border-right: 0

  &.input_version
    padding: 9px 35px 9px 12px
    border-top-left-radius: 0
    border-bottom-left-radius: 0px
    min-height: 38px
    min-width: 0px
    box-sizing: border-box
    border: 1px solid #d6d6d6
    border-left: 0

  &.input_aligned
    padding: 9px 35px 9px 12px
    min-height: 38px
    min-width: 0px
    box-sizing: border-box
    border: 1px solid #d6d6d6


// end new general dropdown

// Form upload button && container
.file_button_container
  background-color: $colorInk
  border-radius: 0
  margin: 15px 0
  line-height: 30px
  display: inline-block
  position: relative
  height: 30px
  width: 200px
  cursor: pointer
  border-radius: 20px

  &:hover
    background-color: color.adjust($colorInk, $lightness: 5%)

  h4
    letter-spacing: 1px
    font-size: 12px
    font-weight: normal
    height: 33px
    color: #ffffff !important

  input
    opacity: 0
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
    filter: alpha(opacity=0)
    position: absolute
    padding: 0
    margin: 0
    border: 0
    top: 0
    left: 0
    padding-left: 200px
    cursor: pointer
    color: #FFF
    height: 30px
    width: 200px
    cursor: pointer

    input
      width: 1px
      height: 1px
// end form upload button && container

// skill tags - general //
.dropholder_skills, .ui-sortable-placeholder
  display: inline-block
  vertical-align: top
  background-color: #D8D8D8
  border: 1px solid #A6A6A6
  margin-right: 8px
  margin-bottom: 8px
  overflow: hidden
  box-shadow: inset 0px 0px 0px 3px #D1D1D1,inset 1px 1px 15px rgba(0,0,0,.10)
  border-radius: 4px
  height: 35px
  width: 20px


.skill_tag, .skill_read_only
  .missing_translation_wrapper
    display: inline-block
    vertical-align: top
    margin-top: -4px
    margin-bottom: -8px
    margin-left: 10px
    margin-right: -10px
    width: 25px
    height: 18px
    padding: 6px 6px 8px 6px
    border-left: 1px solid #EAEAEA
    color: #999999
    background: #fcfcfc

    &:before
      content: ''
      position: absolute
      left: 5px
      top: 5px
      width: 26px
      height: 22px
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width_half
      background-position: -75px -352px

  .experience_indicator_wrapper
    margin-top: -4px
    margin-bottom: -6px
    margin-left: 10px
    margin-right: -10px

    .experience_indicator
      font-size: 12px !important
      display: inline-block
      padding: 7px 6px 8px 6px
      border-left: 1px solid #EAEAEA
      color: #999999
      background: #fcfcfc
      height: 32px
      box-sizing: border-box

.skill_read_only
  display: inline-block
  border-radius: 5px
  margin: 0 15px 15px 0
  padding: 4px 12px 6px 12px
  border: 1px solid #EEE
  border-width: 0px 1px 1px 0px
  background: #FFF
  border-left-width: 8px
  position: relative
  font-size: 13px
  border-left-color: #BEBADA
  &.emphasized
    border-left: 10px solid $colorAvocado
  .skill_read_only_value
    line-height: 22px

.skill_tag
  display: inline-block
  position: relative
  user-select: none
  margin: 0 10px 10px 0

  .text_for_calculated_experience
    display: inline-block
    margin: 5px
    color: $colorSky

  .calculated_experience
    display: inline-block
    font-size: 16px !important
    background-color: $colorSky
    color: #FFF
    padding: 5px
    width: 23px
    border-radius: 50%
    text-align: center

  .separator
    position: absolute
    top: 30px
    width: 1px
    height: 16px
    background: #d9d9d9
    z-index: 3
    .legend
      font-size: 10px
      color: #999
      position: absolute
      bottom: 0px
      left: 5px
      white-space: pre
    &.recommended_max
      top: 6px
      height: 17px
      background: $colorInk
      .legend
        top: 2px
        color: $colorInk

  &.variable
    background: #f1f1f1
    border-radius: 10px

  &.suggestion_tag
    .skill_name
      padding-left: 25px
      padding-right: 10px
      background: #FFF
      cursor: pointer
    &:hover
      .skill_name
        background: #F5F5F5

  &.custom_tag
    vertical-align: top
    font-weight: 300
    letter-spacing: 1px
    &:before
      content: ''
      background-image: url("../images/icons/icon_sprite_x2.png")
      background-repeat: no-repeat
      background-size: 200px
      background-position: -91px -88px
      position: absolute
      left: 0px
      top: 0px
      width: 31px
      height: 31px
      z-index: 1
      border-bottom-left-radius: 20px
      border-top-left-radius: 20px

    &.workflow_tag
      &:before
        background-image: none
        width: 19px
        height: 19px
        top: 5px
        left: 7px
        border-radius: 50%
        border: 1px solid #60ab3e
      &.live:before
        background-color: $colorAvocado
        border-color: #60ab3e
      &.ready_for_review:before
        background-color: $colorBeach
        border-color: $colorBeach_dark
      &.draft:before
        background-color: #d4d4d4
        border-color: #c9c9c9

    &.office_tag
      &:before
        background-position: -121px -88px

    &.preload
      .skill_name
        animation-duration: 1s
        animation-fill-mode: forwards
        animation-iteration-count: infinite
        animation-name: placeHolderPreLoader
        animation-timing-function: linear
        background: linear-gradient(to right, #FFFFFF 8%, #f8f8f8 18%, #FFFFFF 33%)
        background-size: 3000px 104px
        height: 31px

    &.read_only
      .skill_name
        display: inline-block
        border-radius: 5px
        margin: 0 15px 15px 0
        padding: 6px 12px
        border: 1px solid #EEE
        border-width: 0px 1px 1px 0px
        background: #FFF
        border-left-width: 8px
        position: relative
        font-size: 13px

    .skill_name
      background-color: #F8f8f8
      border: 1px solid #D5D5D5
      border-width: 0px 1px 1px 0px
      color: $colorInk
      margin-right: 0
      padding: 8px 20px 8px 35px
      font-size: 12px
      text-shadow: 0px 0px 0px
      box-shadow: none
      border-radius: 20px
      cursor: pointer
      max-width: 100%
      cursor: default
      &.with_white_background
        background-color: #FFF

      &.without_border
          border-width: 0px

      &.active_dropdown
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        border-color: #f8f8f8

    .right_action
      position: relative
      display: inline-block
      height: 31px
      width: 31px
      margin-left: 10px
      margin-right: 30px
      border: 1px solid #D5D5D5
      border-width: 0px 1px 1px 0px
      background-color: #F8F8F8
      border-radius: 20px

  .skill_name
    background-color: #fdfdfd
    border: 1px solid #E7E5E1
    font-size: 14px
    padding: 4px 37px 4px 37px
    z-index: 1000
    border-radius: 5px
    box-sizing: border-box
    cursor: grab

    .skill_name_value
      display: inline-block
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 250px
      line-height: 22px
      margin-bottom: -2px

    &.active_dropdown
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0

    .handle
      width: 15px
      height: 12px
      margin-top: 0 !important
      left: 1px
      top: 1px !important
      padding: 10px 5px
      background-color: #F8F8F8
      border-right: 1px solid #E7E5E1
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px

      .grid
        background-color: $colorInk
        width: 3px
        height: 3px
        margin-right: 2px
        margin-bottom: 2px
        border-radius: 1px

    &.without_handle
      padding-left: 10px
      min-height: 34px

    &.without_options
      margin-right: 0
      padding-right: 10px

    &.experience
      // TOOD: Should not be a sub-class of skill_name
      position: relative
      display: inline-block
      vertical-align: top
      cursor: default
      width: auto
      max-width: none
      padding: 8px 0px
      line-height: 100%
      height: 33px
      margin-right: 0px
      border-radius: 0px
      text-align: center
      overflow: visible
      z-index: 1
      background: transparent
      border: 0

      &:first-of-type
        border-top-left-radius: 10px
        border-bottom-left-radius: 10px

      .increased
        color: #7AC359 !important

      .decreased
        color: #DD7F6D !important

      &.base_div
        border: 0
        background: $colorSky
        border-top-left-radius: 10px
        border-bottom-left-radius: 10px
        &.zero_slice
          width: 0px
          border: 0px
          margin-right: 0 !important
          .number
            left: 0
            top: 100%
            font-size: 14px !important
            padding: 3px
        &.no_offset
          border-top-right-radius: 10px
          border-bottom-right-radius: 10px
        span
          color: $colorWhite
        &.zero_slice .number
          background-color: $colorSky

      &.zero_slice
        .number
          font-size: 16px !important
          background-color: color.adjust($colorSky, $lightness: -10%)
          color: $colorWhite
          padding: 5px
          position: absolute
          top: 4px
          left: -33px

      &.zero_slice + .zero_slice
        span
          display: none !important

      &.zero_slice
        width: 0%
      &.one_slice
        width: 10%
      &.two_slice
        width: 20%
      &.three_slice
        width: 30%
      &.four_slice
        width: 40%
      &.five_slice
        width: 50%
      &.six_slice
        width: 60%
      &.seven_slice
        width: 70%
      &.eight_slice
        width: 80%
      &.nine_slice
        width: 90%
      &.ten_slice
        width: 100%

    &.added
      background-color: color.adjust($colorSky, $lightness: -10%)
      border-top-right-radius: 10px
      border-bottom-right-radius: 10px
    &.reduced
      background-color: #ffd5c8
      .number
        color: $colorError
    .number
      color: $colorWhite

  .autosuggest
    margin-top: -3px !important

  .skill_options
    position: absolute
    cursor: pointer
    right: 1px
    top: 1px
    width: 27px
    height: 32px
    border-bottom-right-radius: 5px
    border-top-right-radius: 5px
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width_third
    background-position: -43px -77px
    background-color: #F9F9F9
    border-left: 1px solid #E7E5E1

    &:hover
      background-position: -81px -77px

    &.active_dropdown
      border-bottom-right-radius: 0

    .autosuggest
      white-space: pre

  .skill_information_to_user
    position: absolute
    top: -8px
    right: -8px
    width: 25px
    height: 25px
    background-color: $colorSky
    border-radius: 50%
    cursor: pointer
    .inside_letter
      position: absolute
      top: 1px
      left: 9px
      color: #ffffff
      font-weight: bold
      font-size: 14px
      cursor: pointer
// end skill tag

// section switch
.select_option_wrapper

  .sub_list
    background: #EEE
    border-radius: 3px
    border-top-left-radius: 0
    .sub_list_element
      min-width: 120px

  .select_option
    display: inline-block
    vertical-align: top
    border-radius: 4px
    position: relative
    padding: 10px
    margin: 0 20px 10px 0
    cursor: pointer
    background: $colorWhite
    border: 1px solid $colorInputBorder
    font-size: 12px

    &.preload
      animation-duration: 1.2s
      animation-fill-mode: forwards
      animation-iteration-count: infinite
      animation-name: placeHolderPreLoader_input
      animation-timing-function: linear
      background: #f6f7f8
      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
      background-size: 2880px 100%

    .choicetext
      font-weight: bold
      text-align: center
      font-size: 14px

      &.small
        font-size: 12px
        margin-top: 10px

    &.vertical_version
      display: block
      margin-bottom: 5px
      margin-right: 0
      &:last-child
        margin-bottom: 0

    &:hover
      background: $colorButtonSecondaryHover

    &.selected
      cursor: default
      border-color: $colorSelected

    &.disabled
      background: #f2f2f2
      border: 1px solid #dfdfdf
      cursor: not-allowed
      color: #999999
      &:hover
        background: #f2f2f2
      label
        cursor: not-allowed
// end section switch

// buttons
.action
  color: $colorRoyal500 !important
  cursor: pointer
  user-select: none
  white-space: nowrap
  letter-spacing: 1px
  &:hover
    text-decoration: underline

  &.alert
    color: $colorError !important

  &.cancel
    color: #B0B0B0 !important

  &.margin_right
    margin-right: 10px
  &.margin_left
    margin-left: 10px
  &.float_right
    float: right
// end buttons

// Block collection
.block_collection
  background-color: #F8f8f8
  color: #555
  letter-spacing: 0.03rem
  font-size: 12px
  text-align: left
  border: 1px solid #DDDDDD
  padding: 10px 5px 15px 15px
  margin: 15px 20px

  &.with_equal_padding
    padding: 10px

  &.with_static_width
    width: 300px

  &.with_right_connector
    &:after
      content: ''
      position: absolute
      top: 50%
      left: 100%
      width: 41px
      height: 5px
      background: #d9d9d9

  .with_margin_right
    margin-right: 5px

  .radio_element
    padding: 10px 20px
    background-color: #EEE
//

.scroll_offset
  scroll-margin-top: 50px

// Block sections
.block_container
  margin: 0 50px

  &.with_right_sidebar
    margin-right: 350px
    &.sidebar_adjustment
      margin-right: 100px
    &.offset_margin
      margin-right: 130px
  &.with_left_spacer
    margin-left: 100px

  &.with_left_menu
    margin: 0 10px 0 100px !important

  &.search_container
    margin: 0 20px

  &.search_results_container
    padding: 20px 0px 50px 0px

    &.with_left_menu
      padding: 0 10px 15px 10px
      margin-top: -5px

    &.contained_list
      margin: 0
      padding: 0
      border-left: 0
      border-bottom: 0
      &.extra_padding_bottom
        padding-bottom: 5px
      &.extra_margin_right
        margin-right: 10px
      &:last-child .list_version:last-child li:last-child .inner_container
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 10px

    .show_all_list_items_container
      background: #f9f9f9
      padding: 0px 10px
      position: relative
      font-size: 12px

  &.heading_container
    background-color: #F8F8F8
    border-right: 1px solid #DCDCDC
    border-top: 1px solid #E4E4E4
    border-left: 1px solid #DCDCDC
    margin: 0 !important
    padding: 10px 10px 0
    font-size: 14px
    overflow: auto // Hack to extend background
    display: block

    &.heading_action
      background-color: #fff
      border-top: 0
      padding: 18px 10px 20px
      border-left: 0
      text-transform: uppercase
      letter-spacing: 2px
      font-weight: bold
      border-bottom-left-radius: 10px
      border-top-left-radius: 10px
      .back_icon
        display: inline-block
        width: 24px
        height: 17px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_third
        background-repeat: no-repeat
        background-position:  -116px -273px
        transform: rotate(180deg)
      &:hover
        &:before
          content: ''
          position: absolute
          left: 2px
          right: 2px
          top: 0px
          height: 3px
          background-color: $colorSky
          border-top-left-radius: 10px
          border-top-right-radius: 10px

  &.with_topbottom_margin
    margin-top: 20px
    margin-bottom: 20px

  &.with_hash_padding
    padding-top: 20px

  .is_owner, .is_collaborative
    width: 12px
    height: 12px
    background-repeat: no-repeat
    background-size: 12px
    padding-right: 12px
  .is_owner
    background-image: url("../images/icons/svg/owner_sky.svg")
  .is_collaborative
    background-size: 9px
    background-image: url("../images/icons/svg/shared_sky.svg")
  .add_margin
    margin-left: 5px
    margin-bottom: 2px

  .placeholder_template_filetype
    background-image: url("../images/icons/filetype_sprite_x2.png")
    background-repeat: no-repeat
    background-size: 360px 140px
    height: 70px
    width: 60px
    background-position: 0px -70px
    &.word
      background-position: 0px -70px
    &.ppt
      background-position: -120px -70px
    &.indesign
      background-position: -300px -70px

.block_section_wrapper
  position: relative
  &.light_margin_top
    margin-top: 10px
  &.normal_margin_top
    margin-top: 30px
  &.extra_margin_top
    margin-top: 50px
  &.extra_padding_top
    padding-top: 45px
  &.extra_padding_bottom
    padding-bottom: 50px
  &.extra_padding_bottom_contextmenu
    padding-bottom: 200px
  &.right_sidebar_container
    position: absolute
    right: -330px
    width: 300px
    top: 0
    margin-top: 0
  &.offset_right_margin
    margin-right: -30px

  // delete later
  .textarea_context_label
    text-align: right
    position: absolute
    right: 0px
    bottom: 0px
    z-index: 100
    opacity: 0.7
    font-weight: normal
    padding: 0 16px 8px
    text-transform: uppercase
    letter-spacing: 2px
    font-size: 9px !important

  label:not(.no_formatting label, .checkmark_container label, .dropdown_container label), .label_override
    display: block
    cursor: inherit
    font-size: 13px
    font-weight: bold
    padding-bottom: 5px
    margin: 0

    span:not(.block_section_wrapper span)
      font-size: inherit !important

    // Same as minimizer
    .action
      font-weight: normal
      font-size: 12px
      letter-spacing: 1px
      text-decoration: none
      &:hover
        text-decoration: underline

    &.minus_top_margin
      margin-top: -10px

    &.to_half_margin_children
      margin-right: 15px

    &.with_extra_bottom_margin
      margin-bottom: 10px

    &.without_padding
      padding: 0

    &.without_margin
      margin: 0

    &.with_half_top_margin
      margin-top: 5px

    &.with_top_margin
      margin-top: 10px

    &.with_double_margin_top
      margin-top: 20px

    &.large_label
      font-size: 16px
      padding-bottom: 5px

    &.extra_large_label
      font-size: 24px
      padding-bottom: 10px
      .gray
        opacity: 0.7

    &.red_label
      color: $colorError !important

    &.gray_label
      opacity: 0.7
      font-style: italic
      font-weight: normal

    &.editable
      cursor: pointer
      &.grey
        color: #777 !important

    &.inline_block
      display: inline-block
      // temp, move this

    &.list_heading
      background: $colorGray5
      padding: 10px 15px
      border-bottom: 1px solid $colorGray2
      border-top: 1px solid $colorGray2
      &:nth-of-type(1)
        border-top: 0

  .disabled_input
    padding: 9px 0
    font-size: 14px

  .textarea_action_bar
    position: absolute
    bottom: 1px
    left: 1px
    right: 1px
    height: 30px
    background-color: #f8f8f8
    border-top: 1px solid #EAEAEA
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 5px
    z-index: 5
    padding: 0 10px
    &.sensitive
      margin-left: 34px
      border-bottom-left-radius: 0
    .textarea_action_bar-info
      letter-spacing: 1px
      font-size: 12px

  textarea, input[type=text], input[type=number], input[type=date], input[type=datetime-local]
    outline: none
    border: 1px solid $colorInputBorder
    background-color: #FFF
    padding: 8px
    font-size: 14px
    box-sizing: content-box
    font-family: $mainFont
    border-radius: 5px
    letter-spacing: 0.02rem
    &:hover
      background-color: $colorInputHover
      border-color: #cacaca

    &:focus
      border-color: $colorSky

    &.with_smaller_text
      font-size: 12px

    &.with_action
      border-top-right-radius: 0px
      border-bottom-right-radius: 0px

    &.invalid
      background-color: color.adjust($colorError, $lightness: 50%)
      border-color: $colorError

  input[type=datetime-local]
    padding: 6px 8px
    border-top-right-radius: 0px
    border-bottom-right-radius: 0px
    border-right: 0

  input[type=number]
    &:placeholder
      color: #c2c2c2

  textarea
    width: 100%
    box-sizing: border-box
    z-index: 2
    position: relative
    vertical-align: top
    padding-bottom: 50px
    line-height: 22px
    box-sizing: border-box

    &.autosized
      overflow: hidden
      min-height: 100px

    &.minimal
      min-height: 50px

    &.disabled_input
      background-color: #EEE !important

    &.sensitive
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      &.anonymized
        border-top-right-radius: 0px

  .input_text_substitute
    padding: 8px 18px 8px 15px
    font-size: 14px
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px
    background-color: color.adjust($colorInk, $lightness: 5%)
    border: 1px solid #fff
    border-right: 0px


  .saving_state_wrapper
    width: 1px
    position: absolute
    top: 0px
    left: 0px
    z-index: 1

    .local_state_container, .int_state_container
      margin-left: -8px
      width: 45%
      float: left
      .upper_state
        width: 4px
        height: 25px
        margin-top: 6px
        display: none
        border-color: #FFF
        background-color: $colorSky
        border-radius: 2px

      &.activated
        .upper_state
          display: block
          animation: 1s upper_state infinite

      &.invalid_state
        .upper_state
          background-color: $colorError !important
          animation-play-state: paused !important

  .workflow_tray
    display: inline-block
    font-size: 12px
    background-color: $colorWhite

    .block_workflow
      display: inline-block
      vertical-align: top
      padding: 8px 40px
      @include bootstrap-mixins.interactive
      min-height: 19px

      .checkmark_container.radio
        .squared_checkmark
          margin-top: -2px
      &.deselected
        color: #848D98
        cursor: not-allowed !important
        .contextual_label_help
          box-shadow: 0px 0px 0px 1px #848D98

      &.preload
        animation-duration: 1.2s
        animation-fill-mode: forwards
        animation-iteration-count: infinite
        animation-name: placeHolderPreLoader_input
        animation-timing-function: linear
        background: #f6f7f8
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
        background-size: 2880px 100%
        width: 200px

    .workflow_divider
      display: inline-block
      vertical-align: top
      position: relative
      height: 15px
      padding: 10px 0
      margin-left: -20px
      margin-right: -20px
      width: 40px
      &:before
        content: ''
        position: absolute
        top: 0px
        left: 8px
        background: #EEEEEE
        width: 24px
        height: 18px
        transform: skew(45deg)
      &:after
        content: ''
        position: absolute
        top: 18px
        left: 8px
        background: #EEEEEE
        width: 24px
        height: 18px
        transform: skew(-45deg)
    &.ready_for_review
      .workflow_divider.draft
        &:before, &:after
          background: $colorBeach_dark
        &:after
          border-color: $colorBeach_dark
    &.live
      .workflow_divider
        &:before, &:after
          background: $colorAvocado
        &:after
          border-color: $colorAvocado

  .block_section_container
    background-color: #FFFFFF
    box-shadow: 1px 1px 0px rgba(224, 224, 224, 0.75)
    max-width: none
    padding: 30px 30px 0 30px
    border-radius: 10px
    // duplication of modern_container

    &.selected
      box-shadow: inset 0px 0px 0px 1px $colorSky
      z-index: 10

    &.gray
      background-color: #F8f8f8

    &.normal_padding
      padding: 30px

    &.smaller_padding
      padding: 20px 30px 0px

    &.no_padding_right
      padding-right: 0

    &.no_padding_top
      // Should not be used
      padding-top: 0

    &.expandable
      padding-top: 60px
      padding-right: 5px

    &.with_large_label
      padding-top: 20px

    &.min_height_search
      min-height: 750px

    &.with_left_section_image
      padding-left: 210px

    .left_section_image
      background-color: #f8f8f8
      width: 170px
      height: 100%
      height: calc(100% - 1px)
      position: absolute
      left: 0px
      top: 0
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px

    .summary_collection
      margin: 0 30px 20px 0

    .block_section_overlay
      position: absolute
      top: 0px
      left: 0px
      width: 100%
      height: 100%
      background-color: #EEE
      z-index: 2
      opacity: 0.5

      &.opaque
        opacity: 1
        background-color: transparent
        z-index: 3

  .block_section_group

    //temp, do not use
    &.with_padding_top
      padding-top: 25px

    &.padding_right
      padding-right: 30px
      .switch
        right: 30px
    &.with_right_absolute_action
      padding-right: 170px
    .switch
      right: 0
      top: -8px
      margin-top: 0
      left: auto
    .orderable_container
      .dropholder_skills
        height: 100px
        width: 100px
        margin-right: 30px
    .subsection_adjust_handle
      .orderable_container
        .dropbolder_skills
          width: auto
          display: block
          height: 10px

    .draggable_placeholder
      margin: 0 0 5px 0

      .above, .below
        display: none

  .block_section_inner_container
    display: inline-block
    width: 100%

    &.with_half_margin_children
      padding-right: 5px

    .interactive_cards.list_version
      li
        background-color: #FFF
        .selected_container
          background-color: #FFF

    .block_section.full_width
      margin-bottom: 20px
      border: 1px solid #EEE
      border-width: 0px 1px 1px 0px

    .non_editable_textarea
      cursor: default
      padding: 10px
      background-color: #EEE
      border-top-right-radius: 5px
      border-top-left-radius: 5px
      &.without_action
        border-radius: 5px
        margin-bottom: 0

      .information_span
        color: #AAA
        font-style: italic

    .inner_container
      padding: 10px 10px 0
      background-color: #f8f8f8

  .block_section_with_action
    display: inline-block
    position: relative
    border-radius: 5px
    margin-bottom: 10px

    &.extra_size
      input
        min-width: 200px
        padding: 10px !important
        font-size: 16px

    .textfield
      display: table-cell
      vertical-align: middle
      font-size: 14px
      color: #555
      width: 100%

  .left_image
    display: block
    width: 179px
    height: 179px
    margin: 0 30px 30px 0
    background-color: #fdfdfd
    border: 1px solid #EEE
    text-align: center
    border-radius: 5px
    position: relative

    img
      border-radius: 5px
      max-height: 100%

    &.rounded
      border-radius: 50%
      img
        border-radius: 50%

    &:hover
      background-color: #fafafa

    &.has_image
      background-color: #FFF

    &.with_float
      float: left
      img
        max-width: 145px
        max-height: 145px
        padding: 10px
        border-radius: 0px

    .progressbar_container
      width: 140px
      top: 65px

    .close_imageupload
      cursor: pointer
      position: absolute
      right: 0px
      top: 0px
      width: 20px
      height: 20px
      padding: 5px
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width_half
      background-position: 3px -72px
      z-index: 10
      &:hover
        background-position: -22px -72px

  .block_section
    display: inline-block
    vertical-align: top
    text-align: left
    width: auto
    min-width: 100px
    min-height: 0
    margin: 0 30px 30px 0
    padding: 10px 15px 15px 15px
    border: 1px solid #EEE
    position: relative
    border-radius: 5px

    &.preload
      animation-duration: 1.2s
      animation-fill-mode: forwards
      animation-iteration-count: infinite
      animation-name: placeHolderPreLoader_input
      animation-timing-function: linear
      background: #f6f7f8
      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
      background-size: 2880px 100%
      width: 200px
      min-height: 38px

      &.tag_card_padding
        min-height: 42px

    &.enforce_large_label
      // emulates label.large_label
      label
        font-size: 16px
      label + label
        font-weight: normal

    &.right_aligned:hover
      .icon_bubble
        border-color: #CCC

    &.disable_text_overflow
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

      &.with_text_wrapping
        white-space: normal

    &.sidebar_friendly
      max-width: 100%
      .skill_tag.custom_tag .skill_name
        max-width: 250px

    span
      font-size: 14px
      color: $colorInk

      &.preview
        display: inline-flex

      &.placeholder_span
        display: inline-block
        padding: 10px
        margin-bottom: 7px
        font-style: italic
        color: #BBB
        opacity: 0
        animation: animate_in_opacity 0.3s 1s ease forwards
        border-radius: 5px

    .llm_generated
      background: #FBFBFF
      border: 1px solid #A46AED
      display: block
      &.progressing
        background: $colorPaper
        overflow: hidden

      .llm_generated_container
        margin-top: 10px
        margin-bottom: 5px
        background: #F4EFFE
        padding: 8px
        border-radius: 4px
        white-space: pre-wrap
        font-size: 14px

      .llm_context_wrapper
        margin: 8px -8px -8px -8px
        padding: 8px
        background: #eae0fc
        color: #604592
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px
        font-size: 13px

      .progressing_gpt_wrapper
        content: ''
        animation: fadein_drive 20s
        animation-fill-mode: forwards
        position: relative
        white-space: pre
        text-align: left
        .progressing_gpt_img
          width: 75px
          height: 50px
          background-size: 100%
          background-image: url("../images/icons/gpt_paper.gif")
      .added
        background: #cefe7d
        border-radius: 5px

      .removed
        text-decoration: line-through
        text-decoration-color: red

        &.carriage_return
          background-color: #ffcece

      .carriage_return
        display: block
        height: 14px
        width: 1em

      .flag_llm
        font-size: 12px
        color: #A46AED
        display: inline-block
        &:before
          content: '✨'
          text-shadow: 0 0 0 #A46AED
          margin-right: 3px
          color: transparent
        &.pulsate:before
          animation: animate_in_opacity 0.5s infinite alternate

    .action
      font-size: 12px
      &.arrow
        padding-right: 15px
        &:before
          border-style: solid
          border-color: $colorSky transparent transparent transparent
          border-width: 4px 4px 0 4px
          position: absolute
          top: 15px
          right: 12px
          content: ''

    .textarea_counter
      position: absolute
      bottom: 35px
      right: 10px
      color: $colorInputBorder
      text-transform: uppercase
      letter-spacing: 2px
      font-size: 10px
      transition: 0.5s all ease-in-out
      opacity: 1
      z-index: 10

      &.hide_counter
        opacity: 0

      &.less_than_fifty
        color: $colorButtonPrimary

      &.above_recommended
        color: #AB0000

    .empty input, .empty textarea
      background-color: $colorInputHover

    .contact_info
      width: 230px
      font-size: 12px
      color: $colorInk
      overflow-wrap: break-word
      line-height: 140%

      .read_only_wrapper
        border-top: 1px solid #E0E0E0
        padding-top: 10px
        margin-top: 10px

        &.without_top_margin
          margin-top: 0

      .emphasize
        font-weight: bold

    .editable_field
      cursor: pointer
      position: relative
      &:hover
        &:after
          content: ""
          background: url("../images/icons/new_cv_sprites_x2.png")
          background-size: $sprite_width_third
          background-color: #fff
          background-position: -80px -80px
          height: 25px
          width: 25px
          position: absolute
          bottom: -5px
          right: -10px

      .placeholder
        color: #999

    &.full_width
      width: 100%
      box-sizing: border-box
      background-color: transparent
      border: 0
      padding: 0
      &.editable
        padding: 15px 45px 15px 15px
        background-color: #fdfdfd
        border: 1px solid #EEE

      &.with_action
        margin-bottom: 20px

      &.with_right_action
        margin-right: 130px
        padding-right: 10px
        width: auto

      .sensitive_textarea_wrapper
        flex: 2

    &.custom_container
      cursor: pointer
      background-color: transparent
      padding: 10px
      border: 2px dashed #d6d6d6
      margin-bottom: 20px
      min-width: 0px
      .vertical_plus, .horizontal_plus
        display: inline-block
        vertical-align: middle
        background-color: $colorAvocado
        width: 20px
        height: 6px
        margin-top: -4px
      .horizontal_plus
        width: 6px
        height: 20px
        margin-left: -13px
        margin-right: 20px

      &:hover, &:focus
        background: $colorButtonSecondaryHover

      &.on_dark_background
        border: 2px dashed #d6d6d6
        &:hover
          background: #FFFFFF

      &.tag_align
        padding: 21px 21px 21px 35px
        min-width: 0
        line-height: 14px
        margin-bottom: 30px
        .icon_bubble
          top: 16px
          left: 5px

      .upload_image_icon, .upload_file_icon, .add_link_icon
        background-image: url("../images/icons/icon_sprite_x2.png")
        background-repeat: no-repeat
        background-size: 200px
        background-position: -61px -94px
        width: 28px
        height: 21px
        display: inline-block
        vertical-align: middle

      .upload_file_icon
        height: 28px
        background-position: -61px -151px

      .add_link_icon
        height: 28px
        background-position: -91px -151px

      .add_custom_section
        display: inline-block

      &.no_cursor
        cursor: default
        &:hover
          background-color: transparent

      &.right_absolute_action
        position: absolute
        line-height: 100%
        right: 0px
        top: -68px

      &.with_third_margin_top
        margin-top: 10px
      &.with_third_margin_bottom
        margin-bottom: 10px
      &.without_margin_right
        margin-right: 0px

      &.block_section_align
        margin: 0 30px 30px 0
        vertical-align: bottom

    // Should be removed
    &.clickable
      cursor: pointer
      user-select: none

    &.disabled_input
      cursor: not-allowed

    &.full_width_only
      width: 100%
      box-sizing: border-box

    // Here be dragons
    &.blocked
      display: block

    &.interactive
      background-color: #fdfdfd
      &:hover
        background-color: #fafafa

    &.right_aligned
      position: absolute
      right: 0px
      top: 30px

      &.second_row
        top: 140px

    &.with_shadow
      border: 1px solid #EEE
      border-width: 0px 1px 1px 0px

    &.active_dropdown
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

    &.dark_shadow
      border-color: $colorGray2

    &.tag_card_padding
      padding: 9px 12px 9px 9px
      white-space: pre
      div
        white-space: normal

    &.subsection_padding
      padding: 20px 0 0 30px

    &.normal_padding
      padding: 10px

    &.label_only_padding
      padding: 10px 10px 5px 10px

    &.without_padding
      padding: 0

    &.without_bottom_padding
      padding-bottom: 0

    &.without_bottom_margin
      margin-bottom: 0

    &.without_margin
      margin: 0

    &.without_right_margin
      margin-right: 0

    &.half_margin
      margin: 0 15px 15px 0

    &.margin_right
      margin-right: 10px

    &.margin_bottom
      margin-bottom: 10px

    &.without_border
      border: 0

    &.ensure_breaks
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      .action
        white-space: normal

    &.offset_padding
      margin-top: -10px
      margin-bottom: 15px

    &.disabled_section
      opacity: 0.5

    &.with_white_background
      //background-color: #FFF

    &.with_preview_images
      max-width: 200px

      img
        border-radius: 5px
        border: 1px solid #EEE

    &.with_draggable_handle
      padding-left: 40px

    &.with_background
      background-color: #F8f8f8
      &.gray
        background-color: #DDD
        border: 1px solid #CCC
      &.white
        background-color: #FFF
        border: 1px solid #EEE

    &.without_background
      background: transparent

    &.disabled_input input
      background-color: #EEE !important

    &.locked
      cursor: not-allowed
      &:after
        content: ""
        display: inline-block
        background: url("../images/icons/icon_sprite_x2.png")
        background-repeat: no-repeat
        background-size: 200px
        background-position: -95px -125px
        height: 22px
        width: 26px

    &.editable
      cursor: pointer
      background-color: #fdfdfd
      &:hover, &:focus
        background-color: #fafafa
        &:after
          position: absolute
          content: ""
          background: url("../images/icons/new_cv_sprites_x2.png")
          background-size: $sprite_width_third
          background-repeat: no-repeat
          background-position: -76px -76px
          background-color: $colorWhite
          border: 1px solid $colorGray2
          bottom: 4px
          right: 4px
          height: 35px
          width: 35px
          border-radius: 3px
      &.blue
        &:hover
          &:after
            background-position: -38px -76px

      &.absolute_disconnected
        position: absolute
        right: 0px

    &.center_block
      text-align: center
      padding: 15px 10px

    &.number

      span
        font-size: 30px
        line-height: 34px
        margin-right: 10px
        vertical-align: middle

    &.name,
    &.title
      min-width: 0

      span
        color: $colorInk
        text-align: left
        padding-bottom: 0
        letter-spacing: 0.01rem
        font-style: normal
        font-size: 26px
        font-weight: bold

        &.tooltip_wrapper
          font-size: 13px

    &.name
      min-height: 62px
      // name should not have min-height
    &.remove_min_height
      min-height: 0

    &.remove_min_width
      min-width: 0

    &.industry
      margin-bottom: 16px
      margin-top: -18px

    &.url
      margin-bottom: 18px
      margin-top: -18px

    &.minus_top_margin
      margin-top: -14px

    &.title
      &.green
        span
          color: #7AC359

      span
        font-weight: normal
        font-size: 16px

      &.smaller
        span
          font-size: 12px
          font-style: italic
          width: 100%
          color: #999
          white-space: normal
        &.interactive
          span
            text-decoration: underline

    &.date
      .date_inline_label
        display: inline-block
        vertical-align: middle
        margin-top: 9px
        opacity: 0.7
        margin-right: 5px

        &.read_only_label
          margin-top: 0
          padding-bottom: 0

        &.to_field
          margin-left: 15px

        &.other_field
          margin-left: 5px

      &.push_saved_message .saved_message
        margin-top: 10px

      .date_month_wrapper
        margin-right: 5px

      .date_month
        width: 20px

      .date_year
        width: 35px

      .date_month, .date_year
        &:hover + .date_label
          display: block
        &:focus + .date_label
          opacity: 0.7
          display: block

      .date_label
        position: absolute
        left: 0
        top: 100%
        margin-top: 2px
        width: 100%
        text-align: center
        font-size: 9px
        opacity: 0.5
        letter-spacing: 2px
        display: none

      .empty .date_label
        display: block

    &.with_right_action
      padding-right: 60px
      min-height: 40px

    &.with_right_icon
      padding-right: 40px
      .icon_container
        position: absolute
        right: 5px
        top: 5px

    &.what_is_text
      max-width: 900px
      font-size: 13px
      opacity: 0.7
      line-height: 150%
      display: block

      ul
        li
          margin-top: 5px
          margin-left: 20px
          list-style: disc

    .contextual_information
      font-size: 10px
      margin-bottom: 10px
      max-width: 230px
      span
        font-size: inherit
      &.no_max_width
        max-width: none
      &.no_bottom_margin
        margin: 0
      &.with_tiny_margin_top
        margin-top: 3px

    .block_half_wrapper
      padding: 10px 10px 0 10px
      border: 1px solid $colorError
      margin-bottom: 10px
      background-color: $colorGray5
      border-radius: 5px
      &.left
        margin-right: 30px
      &.enabled
        border-color: $colorSuccess

    .left_action, .right_action
      position: absolute
      top: 0px
      height: 100%

      .icon_bubble
        position: absolute
        top: 8px
        left: 7px

    .left_action
      left: 0px

    .right_action
      right: 0px
      background-color: $colorGray5
      border-left: 1px solid $colorGray2
      width: 45px
      border-bottom-right-radius: 5px
      border-top-right-radius: 5px
      cursor: pointer
      .icon_bubble
        top: 15px
        &.pull_vertical
          top: 10px
          left: 2px
      &.with_label_only
        .icon_bubble
          top: 21px
          text-align: center
          span
            color: #999
            font-weight: bold

    .field_overview_table
      span
        font-size: 12px
      .field_column
        padding-right: 30px
        max-width: 300px
        overflow: hidden
        text-overflow: ellipsis
        white-space: normal

    .field_element_parent
      .field_element
        &:after
          content: ", "
        &:last-child
          &:after
            content: ""
// end block sections for company CV

// Interactive card list
.interactive_cards
  margin-bottom: 500px
  text-align: left
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 20px
  #settings &
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
    .larger_min_width
      min-width: 250px

  &.without_bottom_margin
    margin-bottom: 0

  &.adjust_for_list_items_margin
    margin: 0 -10px


  &.singel_column
    min-width: 300px
    width: 25%
    display: inline-block

  &.pulsate_selectors
    .button.selectable
      animation: pulsate 2s ease-in-out infinite
      &.selected
        animation: none
    .squared_checkmark
      animation: pulsate_green 2s ease-in-out infinite
      &.selected
        animation: none

  &.list_version
    grid-template-columns: 1fr
    gap: 0px
    margin-top: 20px
    margin-bottom: 50px
    padding-left: 41px
    border-radius: 5px

    &.without_selected_container
      padding-left: 0

    &.without_navigation_arrow
      li
        .inner_container:after
          display: none

    &.contained_list
      margin: 0
      border-radius: 0
      li
        padding: 0
        margin: 0
        border-radius: 0
        border-right: 0

    &.with_white_background .inner_container
      background-color: $colorWhite

    &.without_top_margin
      margin-top: 0

    &.without_left_padding
      padding-left: 0

    &.without_bottom_margin
      margin-bottom: 0

    &.max_height_scrollable
      max-height: 350px
      overflow: auto

    &.max_five_elements
      max-height: 290px
      overflow: hidden
      margin-bottom: 0
      &.expand
        max-height: 425px
      &.show_all_elements
        max-height: none

    &.zebra_striped
      li:nth-child(odd)
        background-color: #FFFFFF
        .selected_container
          background-color: #FFFFFF

      li:nth-child(even)
        background-color: #f8f8f8
        .selected_container
          background-color: #f8f8f8

    li
      width: 100%
      padding: 0
      text-align: left
      position: relative
      grid-column: 1 / -1

      &:first-child
        border-top-left-radius: 10px
        border-top-right-radius: 10px

      &:last-child
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 10px

      .selected_container
        position: absolute
        right: 100%
        top: 0px
        height: 100%
        width: 41px
        border-bottom-left-radius: 5px
        border-top-left-radius: 5px
        .checkmark_container
          position: absolute
          top: 50%
          left: 15px
          margin-top: -11px
          cursor: pointer
          border: 1px solid transparent

      .inner_container
        width: 100%
        min-height: 0
        border: 0
        padding: 20px 75px 10px 20px
        background-color: transparent
        border-radius: 0px

        &.right_absolute_action
          padding-right: 200px

        &.without_right_padding
          padding-right: 10px

        &.tiny_list_item
          padding-top: 10px
          padding-bottom: 10px
          padding-right: 35px
          padding-left: 15px
          &.green:after, &.blue:after
            right: 0
            top: 50%
            margin-top: -20px
            border: none
            width: 30px

        &.green:after, &.blue:after
          content: ""
          position: absolute
          right: 28px
          top: 8px
          height: 40px
          width: 40px
          background: #FFF
          border: 2px solid #E6E6E6
          border-radius: 50%
          background-image: url("../images/icons/svg/navigation_arrow_right_gray.svg")
          background-size: 7px
          background-repeat: no-repeat
          background-position: center

        &.blue:hover, &.blue:focus
          &:after
            border-color: $colorSky
            background-image: url("../images/icons/svg/navigation_arrow_right_sky_blue.svg")

      .dual_button_container
        position: absolute
        right: 30px
        top: 30px
        &.active
          z-index: 10
          .dual_action_button, .dual_dropdown_button
            border-bottom-right-radius: 0px
            border-bottom-left-radius: 0px
        .dual_action_button
          padding: 8px 16px
          letter-spacing: 0.05rem
          background-color: $colorWhite
          border: 1px solid $colorInputBorder
          border-radius: 20px
          &:hover
            background-color: $colorButtonSecondaryHover
          &.with_action
            border-bottom-right-radius: 0px
            border-top-right-radius: 0px
        .dual_dropdown_button
          height: 34px
          width: 34px
          background-color: #F9F9F9
          border: 1px solid $colorInputBorder
          border-left: 0
          cursor: pointer
          user-select: none
          border-top-right-radius: 20px
          border-bottom-right-radius: 20px
          &:hover
            background-color: #EEE
          .dual_dropdown_icon_down, .dual_dropdown_icon_up
            background: url("../images/icons/new_cv_sprites_x2.png")
            background-size: $sprite_width_half
            background-position: -47px -200px
            width: 100%
            height: 25px
            margin-top: 5px
          .dual_dropdown_icon_up
            background-position: -97px -200px

        .dual_dropdown
          position: absolute
          width: 100%
          top: 100%
          left: 0px
          border: 2px solid #E6E6E6
          border-top: 0px
          box-sizing: border-box
          .dual_dropdown_element
            padding: 8px 16px
            background-color: #F9F9F9
            cursor: pointer
            &:hover
              background-color: #EEE
              color: #555

  &:has(> .twenty_width)
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

  li
    display: inline-block
    vertical-align: top
    width: 100%
    min-width: 260px
    box-sizing: border-box
    text-align: center

    &.sectionSupport
      display: inline-block
      width: 100%
      box-sizing: border-box
      text-align: center
      grid-column: 1 / -1

    &.larger_min_width
      min-width: 300px

    &.full_width
      width: 100%
      grid-column: 1 / -1
      .secondary_actions_block.with_bottom_information_card
        padding-top: 8px
        text-align: right
        width: 100%
        box-sizing: border-box
      .bottom_information_card
        top: 17px
        left: 15px
        width: auto

    &.align_wrapper_left
      text-align: left

    &.in_proposal
      transition: opacity 0.5s
      opacity: 0.6

    .secondary_list_block
      position: relative
      padding: 10px 15px 10px
      font-size: 12px
      background-color: #FFF
      letter-spacing: 0.02rem
      border-bottom: 1px solid #EAEAEA
      word-break: break-word

      .secondary_list_block
        padding: 0
        border: 0

        &.long_description
          margin: 5px 0 0 0

        &.customer_description
          margin: 5px 0 0 0

        &.customer_value_proposition
          margin: 5px 0 0 0

        &.project_experience_skills
          .heading
            display: none
          .tags
            padding: 7px 10px
            background-color: #EEE
            margin: 5px 5px 0 0
            display: inline-block
            border-radius: 2px
            .highlight_snippet
              margin: 0

        &.name, &.school
          font-weight: bold
          &:after
            display: none

        &.roles, &.key_points
          .heading
            display: none
          .name
            font-weight: bold
            &:after
              display: none
          .search_subsection_wrapper
            position: relative
            margin: 5px 0 0px 15px
            &:after
              content: ""
              position: absolute
              left: -8px
              top: 0px
              width: 1px
              height: 100%
              z-index: 1
              background-color: #CCC
        &.key_points
          .search_subsection_wrapper
            &:after
              left: -13px
              top: 3px
              width: 8px
              height: 8px
              background-color: $colorSky
              border-radius: 50%
        &.customer
          font-weight: bold
          &:after
            display: none
          .highlight_snippet
            margin-top: 0
        &.industry
          font-weight: bold
          &:after
            content: ''
          &:before
            content: ''
          .industry
            font-weight: normal

      .highlight_snippet, .subheading
        margin: 5px 0 0 0

      .heading
        font-weight: bold
      .span_wrapper
        margin-right: 5px
      .dates, .duration_in_years, .proficiency
        font-weight: normal
      .dates:before
        content: ""
        display: block
      .customer:after, .name:after
        content: '-'
        margin-left: 5px
      .industry:before
        content: '('
      .industry:after
        content: ')'

      .search_highlight
        border-bottom: 2px solid $colorSky

    .scrollable_highlights_container
      max-height: 400px
      overflow-y: auto

    .secondary_actions_block
      position: relative
      background-color: $colorGray5
      letter-spacing: 0.03rem
      font-size: 12px
      text-align: left
      padding: 8px 15px 8px
      border-bottom-right-radius: 10px
      border-bottom-left-radius: 10px
      &.not_last
        border-radius: 0
        border-bottom-color: #EAEAEA
      &.with_bottom_information_card
        padding-top: 25px
      &.without_padding
        padding: 0
      &.with_children_padding
        padding: 5px 0px 0 5px

      .interactive_text
        border: 1px solid $colorInputBorder
        background-color: $colorWhite
        padding: 4px 9px
        margin: 0 5px 5px 0
        letter-spacing: 0.05rem
        border-radius: 6px
        &:hover
          background-color: $colorButtonSecondaryHover
        &.selected
          background-color: $colorSelected
          border-color: $colorSelected
          color: #FFF
          cursor: default
      .number
        font-size: 12px
        margin-right: 4px

    .inner_container
      display: inline-block
      position: relative
      vertical-align: top
      padding: 15px 15px 0
      background-color: $colorWhite
      box-sizing: border-box
      width: 100%
      border-top-right-radius: 10px
      border-top-left-radius: 10px
      border-bottom-right-radius: 10px
      border-bottom-left-radius: 10px
      font-size: 0.8em

      &.equal_padding
        padding: 16px
        &.smaller
          padding: 5px 10px

      &.with_secondary
        border-bottom-right-radius: 0px
        border-bottom-left-radius: 0px

      &.selected
        background-color: $colorSelected
        color: #FFF !important

      &.not_interactive
        .hover_text
          display: block
        .anti_hover_text
          display: none

      &.interactive
        cursor: pointer
        &.blue:hover, &.blue:focus
          &:before
            content: ''
            position: absolute
            left: 2px
            right: 2px
            top: 0px
            height: 3px
            background-color: $colorRoyal500
            border-top-left-radius: 10px
            border-top-right-radius: 10px

          .image_container img
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale")
            -webkit-filter: grayscale(0%)
            text-decoration: none
        &:hover
          .hover_icon, .with_absolute_icon
            display: block
          .hover_text
            display: block
          .anti_hover_text
            display: none

        &.with_icon
          padding-right: 50px

        .with_absolute_icon
          display: none
          &:before
            width: 64px
            height: 64px
            position: absolute
            top: 3px
            left: 13px
            border-radius: 50%
            background-color: #FFF
            content: ''
          &:after
            content: ''
            background-image: url("../images/icons/svg/search.svg")
            background-repeat: no-repeat
            width: 40px
            height: 40px
            background-size: 32px
            position: absolute
            top: 17px
            right: 13px
            z-index: 10

        .with_fake_block
          width: 80px
          height: 0px
          display: block

      .hover_icon
        background-image: url("../images/icons/svg/search.svg")
        background-repeat: no-repeat
        width: 40px
        height: 40px
        background-size: 32px
        position: absolute
        top: 17px
        right: 13px
        display: none

      .image_indicator
        position: absolute
        right: 10px
        bottom: 15px
        .image_icon
          background: url("../images/icons/icon_sprite_x2.png")
          background-repeat: no-repeat
          background-size: 200px
          background-position: -61px -94px
          width: 28px
          height: 21px
        .image_count
          font-size: 10px
          opacity: 0.5
          text-align: center
          margin-right: 2px

    label
      display: block
      font-size: 12px
      font-weight: bold
      margin: 0

    .bottom_information_card
      position: absolute
      top: 5px
      left: 0px
      width: 100%

    .image_container
      width: 100px
      height: 100px

      .logo_placeholder
        display: inline-block
        background-color: #F2F2F2
        color: #999
        border-radius: 50%
        padding: 49px
        border: 1px solid #EAEAEA
        border-width: 0 1px 1px 0

      img
        max-width: 100px
        max-height: 100px
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")
        filter: gray
        -webkit-filter: grayscale(100%)
        opacity: 0
        animation: animate_in_opacity 0.3s 0.75s ease forwards

      .icon_container
        background-image: url("../images/icons/settings_menu_icons_x2.png")
        background-repeat: no-repeat
        background-size: 100px 2200px
        width: 100%
        height: 100%
        display: block

        &.cv_templates_icon
          background-position: 0 0
        &.project_templates_icon
          background-position: 0 -100px
        &.date_format_icon
          background-position: 0 -200px
        &.templates_icon
          background-position: 0 -300px
        &.users_icon
          background-position: 0 -400px
        &.customize_interface_icon
          background-position: 0 -500px
        &.compliance_icon
          background-position: 0 -600px
        &.apikeys_icon
          background-position: 0 -700px
        &.masterdata_icon
          background-position: 0 -800px
        &.company_cv_icon
          background-position: 0 -900px
        &.customize_interface_fields_icon
          background-position: 0 -1000px
        &.help_texts_icon
          background-position: 0 -1100px
        &.multilingual_cvs_icon
          background-position: 0 -1200px
        &.references_on_dashboard_icon
          background-position: 0 -1300px
        &.custom_tags_icon
          background-position: 0 -1400px
        &.proficiency_icon
          background-position: 0 -1500px
        &.data_import_icon
          background-position: 0 -1600px
        &.external_sharing_icon
          background-position: 0 -1700px
        &.single_sign_on_icon
          background-position: 0 -1800px
        &.help_icon
          background-position: 0 -1900px
        &.ai_icon
          background-position: 0 -2000px
        &.feature_icon
          background-position: 0 -2100px
        &.sensitive_data
          background-image: url("../images/icons/svg/shield.svg")
          background-position: center
          background-size: 50% 50%
          filter: invert(9%) sepia(17%) saturate(3746%) hue-rotate(180deg) brightness(98%) contrast(97%)

    .content_block
      display: inline-block

      &.blue
        font-size: 14px
        opacity: 0.7

      &.with_margin
        margin: 0 20px 10px 0
        max-width: 100%

      &.min_double_lineheight
        min-height: 30px

      &.margin_bottom
        margin-bottom: 20px

      &.margin_bottom_medium
        margin-bottom: 8px

      &.margin_bottom_tiny
        margin-bottom: 4px

      &.margin_top
        margin-top: 10px

      &.margin_top_bottom
        margin: 10px 0

      &.max_width_card
        max-width: 270px

      &.min_width_title
        min-width: 300px

      &.text_overflow_300
        width: 300px
        margin-right: 10px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

      &.min_width_number_of_users
        min-width: 175px

      &.small_image
        max-height: 35px
        max-width: 35px
        margin-top: -1px
        margin-right: 10px
        border-radius: 50%

      &.big_image
        max-height: 70px
        max-width: 70px
        margin-left: 10px

      &.rounded
        border-radius: 50%

      &.title
        font-size: 16px
        line-height: 120%
        font-weight: bold
        display: block
        span
          word-wrap: break-word

      &.industry
        font-size: 12px
        margin-top: 6px
        padding: 4px 10px
        opacity: 0.7
        &.live, &.draft, &.ready_for_review
          border-radius: 5px

        &.live
          background: #e7ffdd
        &.draft
          background: $colorGray3
        &.ready_for_review
          background: $colorBeach

      &.float_left
        float: left

      &.float_right
        float: right

      &.preload
        animation-duration: 1.2s
        animation-fill-mode: forwards
        animation-iteration-count: infinite
        animation-name: placeHolderPreLoader_input
        animation-timing-function: linear
        background: #f6f7f8
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
        background-size: 2880px 100%
        height: 10px
        width: 100%
        &.secondary
          width: 75%

    .size_xxl, .size_xl, .size_l, .size_m, .size_s, .size_xs
      display: inline
      word-wrap: break-word
      letter-spacing: 0.01rem
    .size_xxl
      font-size: 40px
      font-weight: bold
    .size_xl
      font-size: 30px
    .size_l
      font-size: 16px
    .size_m
      font-size: 13px
    .size_s
      font-size: 0.8em
      font-weight: normal
      color: #999

    .gray
      color: #838b95
    .lightgray
      color: #BEBEBE

    .smaller_text
      font-size: 0.9em

    .minimum_double_line
      height: 34px
      overflow: hidden

    .hover_text
      display: none

    .card_indicator_wrapper
      position: absolute
      left: 0px
      right: 0px
      top: -8px
      .card_indicator_wrapper-content
        background: #f7f7f7
        font-size: 10px
        padding: 1px 8px
        border-radius: 5px
        border: 1px solid #e9e9e9

// END Interactive card list

// Left fixed menu
.left_fixed_menu
  position: fixed
  left: 10px
  top: 87px
  z-index: 200
  height: 100%
  background: #F8F8F8
  border: 1px solid #DCDCDC
  border-bottom: 0
  margin-top: 1.2rem
  .menu_element
    height: 50px
    width: 50px
    display: block
    padding: 15px 15px 14px 15px
    border-bottom: 1px solid #E4E4E4
    background-color: $colorWhite
    .menu_text
      position: absolute
      bottom: 5px
      left: 4px
      right: 4px
      text-align: center
      font-size: 9px
      letter-spacing: 0.05em
      hyphens: auto
      color: $colorInk

    &.selected
      &:after
        content: ''
        position: absolute
        width: 4px
        height: 100%
        right: 0
        top: 0
        background-color: $colorSelected
      .menu_icon:after
        background-position-x: -50px

  .menu_icon
    width: 100%
    height: 100%
    display: block
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width
      background-repeat: no-repeat
      background-position: 0px 0px

    &.technologies:after
      background-position: -100px -500px
      background-position-y: -508px
    &.cv_roles:after
      background-position: -100px -550px
      background-position-y: -550px
    &.certifications:after
      background-position: -100px -600px
      background-position-y: -600px
    &.courses:after
      background-position: -100px -650px
      background-position-y: -650px
    &.languages:after
      background-position: -100px -700px
      background-position-y: -700px
    &.educations:after
      background-position: -100px -750px
      background-position-y: -750px
    &.project_experiences:after
      background-position: -100px -800px
      background-position-y: -1060px
// End left fixed menu


.active_suggestions_list
  padding: 10px 10px 0 10px
  background-color: #f6f6f8
  li
    margin-bottom: 10px
    margin-right: 7px

.table_list, .unapprovedlist
  &.no_heading
    padding-top: 0

  &.disabled_scroll
    max-height: none
    overflow: visible

  &.labels_matrix
    min-width: 0

  > li
    border-left: 0px solid #fff
    transition: border 1s
    border-bottom: 1px solid #E0E0E0
    background-color: #FFF
    min-width: 100%
    box-sizing: border-box

    // cope with select (z-index: -1 for focus)
    outline: none

    &.no_float
      float: none

    &.recently_added
      border-left: 15px solid $colorSelected

    &:hover, &.active_element
      background-color: transparent
      .column.link
        display: block
      .row_options
        display: block
        background-color: transparent
      .row_content .column .category_tags_wrapper
        display: inline-block
      .row_content .column .add_tag
        display: inline-block


    &.table_heading
      padding: 0
      border-bottom: 2px solid $colorRoyal500
      position: absolute
      left: 0px
      top: 0px
      width: 100%
      z-index: 100
      margin: 0

      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)

      background-color: #FBFBFB
      text-transform: uppercase
      font-weight: bold
      font-size: 14px
      letter-spacing: 1px

      box-sizing: border-box

      .row_content
        .column
          padding: 10px 10px 8px 10px

          &.sorted
            background-color: $colorRoyal500
            color: #FFF

            .sorted_arrow
              display: inline-block

    &.with_filter
      top: 52px

    &.readonly
      .row_content
        background-color: #E0E0E0 !important
      .row_options
        display: none

    &.disabled
      border-left: 5px solid $colorError
      .token_name, .access_token, .column
        text-decoration: line-through

    &.extra_value_container
      padding: 0 0 5px 0

      .to_value_element_several
        padding: 5px 30px 5px 5px
        margin: 5px 0
        background-color: #CCC
        &:after
          content: ''
          position: absolute
          right: 0
          top: 0
          height: 100%
          width: 30px
          background-color: #999

      .from_to_arrow
        background: url("../images/icons/new_cv_sprites_x2.png") no-repeat
        background-position: -110px -360px
        background-size: $sprite_width
        height: 30px
        width: 30px
    &.showing_secondary_options
      background: #FFFFFF
      .row_options
        background: #FFFFFF
        text-align: right
        display: block
        &.user_version
          left: 220px

// search_categories
.search_categories_container
  position: relative
  &.with_full_border
    border-bottom: 1px solid #EEE
  > a:nth-of-type(1)
    border-top-left-radius: 10px
    &.button
      border-top-left-radius: 20px
  .search_category_dropdown
    position: absolute
    top: 100%
    right: 0
    left: 0
    z-index: 1000
    background-color: #FFF
    box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)
    a:first-child
      border-top: 1px solid #ECECEC
    .search_category.dropdown
      border-bottom: 1px solid #ECECEC
      &:hover
        background: #f0f0f0
  .search_category
    position: relative
    padding: 20px 50px
    border-right: 1px solid #E4E4E4
    background-color: $colorWhite
    text-transform: uppercase
    letter-spacing: 2px
    font-weight: bold
    font-size: 14px
    &.without_right_border
      border-right: 0
      border-bottom-right-radius: 10px
      background-color: transparent
    &.with_small_left_padding
      padding-left: 15px
    &.with_small_right_padding
      padding-right: 15px
    &.with_equal_padding
      padding: 10px
    &.label
      font-weight: normal
      text-transform: none
      padding: 20px 20px
      cursor: default
      font-style: italic
      background: transparent
    &.selected
      background-color: $colorRoyal500
      color: #FFF
      cursor: default

      &:after
        content: ''
        position: absolute
        left: 50%
        bottom: -5px
        width: 12px
        height: 12px
        transform: rotate(45deg)
        background-color: $colorRoyal500
        margin-left: -7px
        z-index: 200
    &.dropdown_active
      padding-right: 30px
      cursor: pointer
      &:after
        content: ''
        position: absolute
        right: 10px
        top: 29px
        width: 0
        height: 0
        border-style: solid
        border-width: 5px 5px 0 5px
        border-color: $colorInk transparent transparent transparent
    &.search_tabs
      padding: 10px 30px
      .primary
        display: block
        font-weight: bold
      .secondary
        font-weight: normal

  .right_absolute_container
    position: absolute
    top: 14px
    right: 14px
    z-index: 10

#masterdata
    .settings_platform
      padding-top: 1rem

// Input selects (Radio / Checkbox)
.checkmark_container
  white-space: nowrap
  line-height: 100%
  &.with_right_spacing
    margin-right: 20px
  &.with_top_margin
    margin-top: 5px
  &.with_double_top_margin
    margin-top: 10px
  &.with_margin_bottom
    margin-bottom: 10px
  &.with_margin_right
    margin-right: 10px
  &.iceberg_padding
    padding: 8px
  &.half_width
    flex: 0 25%
  &.right_aligned
    text-align: right
  &.radio
    .squared_checkmark
      border-radius: 50%
      border-color: $colorSelected
      &.selected
        border-color: $colorSelected
        &:before
          top: 4px
          left: 4px
          width: 10px
          height: 10px
          background-color: $colorSelected
          background-image: none
          border-radius: 50%
    &.radio
      &.partly_selected, &.selected
        border-color: $colorSelected
        &:before
          background-color: $colorSelected
          border-color: $colorSelected
    &.green
      .squared_checkmark
        &.selected
          &:before
            background-color: $colorAvocado
            background-image: none

  &.gray
    .squared_checkmark
      &.selected
        &:before
          background: url("../images/icons/new_cv_sprites_x2.png")
          background-size: $sprite_width_half
          background-repeat: no-repeat
          background-position: -4px -128px
    &:hover
      .squared_checkmark
        &.selected
          &:before
            background-position: -29px -128px
  &.extended_min_width
    min-width: 200px
  &.checkmark_list
    padding: 5px
    margin: 5px
    min-width: 120px
    &.list_label
      border-top: 5px solid #EEE
      font-weight: bold
      font-size: 14px
      padding-right: 20px
      margin: 0

  &.inside_filter_options
    margin: 0 10px 10px 0
    padding: 5px 10px
    background: #FFF
    border: 1px solid #EEE

  label
    display: inline-block !important
    vertical-align: middle
    padding: 0
    font-weight: normal
    font-size: 14px
    &.lightgray
      color: #afafaf !important
    &.bolded
      font-weight: bold

  .squared_checkmark
    height: 18px
    width: 18px
    background: #FFF
    border: 1px solid $colorRoyal500
    border-radius: 3px
    position: relative
    &.partly_selected
      border-color: $colorRoyal500
      &:before
        content: ''
        position: absolute
        top: 0px
        left: 0px
        width: 18px
        height: 18px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat
        background-position: -78px -453px
        background-color: $colorRoyal500
        border-radius: 1px
    &.selected
      border-color: $colorRoyal500
      &:before
        content: ''
        position: absolute
        top: 0px
        left: 0px
        width: 18px
        height: 18px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat
        background-position: -78px -128px
        background-color: $colorRoyal500
        border-radius: 1px
    &.lightgray
      &.selected
        border-color: lightgray
        &:before
          background-color: lightgray

    &.left_label
      margin-left: 10px
    &.right_label
      margin-right: 10px
    &.smaller_right_label
      margin-right: 8px

    &.button_version
      height: 15px
      width: 15px
      // This is a temporary hack to make checkmark png work
      &.selected:before
        background-size: 98px 539px
        background-position: -78px -127px
        width: 15px
        height: 15px

  .checkmark_option
    height: 31px
    width: 31px
    border: 2px solid #FFFFFF
    background: url("../images/icons/new_cv_sprites_x2.png")
    background-size: $sprite_width_third
    background-repeat: no-repeat
    &:hover
      border-color: #CCC
    &.starred_option
      background-position: -3px -153px
      &.starred
        background-position: -78px -153px
    &.removed_option
      background-position: -3px -115px
      &:hover
        background-position: -78px -115px

  .clickable_container
    padding: 5px
    border: 2px solid transparent
    position: relative
    line-height: 16px
    min-height: 21px
    &:hover
      border-color: #CCC
      &:after
        content: ''
        position: absolute
        top: 0
        right: 0
        width: 31px
        height: 31px
        background: url("../images/icons/new_cv_sprites_x2.png") #ffffff
        background-size: $sprite_width_third
        background-repeat: no-repeat
        background-position: -3px -78px

    &.selected
      border-color: $colorSelected
    .label_container
      width: 200px
      margin: 0
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      font-size: 14px
      line-height: 18px

    &.with_handle
      padding-left: 24px
      min-height: 21px

// radiobuttons
// Duplicate, todo: Should be deleted
.radiobutton_container
  &.with_margin_left
    margin-left: 50px

  .step_guide
    position: absolute
    width: 100%
    left: 0
    top: 14px
    background-color: $colorInputBorder
    height: 1px

  .step_name
    position: absolute
    top: 30px
    left: 0
    right: 0
    font-size: 10px !important
    word-wrap: break-word

  .step_container
    flex-grow: 1
    text-align: right
    position: relative
    &:nth-child(1)
      text-align: left
    &:not(:first-child):not(:last-child)
      text-align: center

  .rounded_radiobutton
    height: 18px
    width: 18px
    border: 1px solid $colorRoyal500
    border-radius: 50%
    background: #FFF
    position: relative
    &.selected
      &:before
        content: ''
        position: absolute
        top: 4px
        left: 4px
        width: 10px
        height: 10px
        border-radius: 50%
        background-color: $colorRoyal500
      .step_name
        font-weight: bold
    &.completed
      background-color: $colorRoyal500

    &.right_label
      margin-right: 10px
    &.left_label
      margin-left: 10px
    &.right_label_long
      margin-right: 50px

    &.support_step
      &:after
        content: "?"
        font-weight: bold
        color: $colorRoyal500
        position: absolute
        top: -3px
        left: 5px
        font-size: 15px
      &.selected
        &:after
          display: none

// Filter options in table
.filter_options_container
  padding: 10px 15px
  background-color: $colorGray5
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  position: relative
  min-height: 31px

  &.with_toggle
    padding-top: 20px

  &.with_top_border
    border-top: 1px solid #DCDCDC

  &.not_last_item
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0px
    border-bottom: 1px solid #ececec

  > .sectionSupport
    // temp solution
    margin-top: -40px

  &.disabled
    opacity: 0.3
    position: relative
    &:before
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0
      cursor: not-allowed
      content: ''
      z-index: 2

  .filter_option
    padding: 5px 20px

  .absolute_button_right
    position: absolute
    top: -1px
    right: -1px

  .filter_options_divider
    padding-left: 30px
    border-left: 1px solid #ECECEC
    &.with_margin_bottom
      margin-bottom: 10px
    &.with_margin_left
      margin-left: 30px

  .filter_options_divider_offset
    margin-right: 30px

  .list_heading
    padding-top: 5px
    padding-bottom: 0

  .label_with_absolute_icon
    padding-right: 30px

  &.actions_block
    padding: 10px
    background-color: #F8F8F8

  &.with_bottom_buttons
    padding-bottom: 5px
    padding-top: 5px

  &.with_filter_toggle_list
    padding-bottom: 5px

  &.without_padding_bottom
    padding-bottom: 0

  &.with_half_padding_right
    padding-right: 5px

  .checkmark_wrapper
    margin: 10px 50px 20px 0
    display: block
    .sub_list
      background: #EEE
      border-radius: 3px
      border-top-left-radius: 0
      .sub_list_element
        min-width: 120px
    .sub_list_search
      font-size: 12px !important
      margin: 5px
      border: 1px solid #D8D8D8
      box-shadow: inset 0 1px #F7F7F7, 0px 1px #FFF

  .filter_toggle_list
    .filter_toggle_list_element
      background: #FFF
      border: 1px solid $colorButtonSecondaryBorder
      color: $colorInk
      padding: 6px 8px
      margin: 0 8px 8px 0
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 100%
      font-size: 12px
      @include bootstrap-mixins.interactive
      border-radius: 3px
      &.selected
        background-color: $colorSelected
        color: #FFF
        border-color: $colorSelected
        box-shadow: 0 0 0 rgba(0,0,0,0), inset 0 0 0 rgba(0,0,0,0)

  .close_filter_options_container
    width: 36px
    height: 30px
    position: absolute
    right: 10px
    top: 8px
    background: url("../images/icons/new_cv_sprites_x2.png") #FFFFFF
    background-size: $sprite_width_third
    background-repeat: no-repeat
    border: 1px solid #E6E6E6
    cursor: pointer
    background-position: 0px -302px

  .search_hit
    font-size: 14px
    position: relative
    display: inline-block
    // offset height change
    margin-bottom: 14px
    margin-right: 10px
    &.disabled
      opacity: 0.3
    .number_hit
      font-size: 20px
      font-style: italic

  .slider_wrapper
    margin: 0 40px 0 10px

  .slider_container
    width: 100%
    background-color: #FFF
    border: 1px solid $colorInk
    height: 10px
    margin: 20px 0px 60px
    .slider_info_container
      position: absolute
      bottom: 11px
      left: 0px
      width: 100%
      .slider_info
        display: inline-block
        vertical-align: bottom
        width: 10%
        background-color: #E5F0F7
    .slider_selected_line
      position: absolute
      top: -1px
      height: 100%
      background-color: $colorSelected
      border: 1px solid $colorSelected
    .slider_handle
      position: absolute
      min-width: 12px
      padding: 1px 8px 4px 8px
      text-align: center
      top: -10px
      background-color: #FFF
      border: 1px solid $colorInk
      border-radius: 50%
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25)
      @include bootstrap-mixins.interactive
      z-index: 10
      &.left
        left: -14px
      &.right
        right: -14px
      .slider_handles
        display: inline-block
        width: 1px
        height: 10px
        margin-right: 2px
        margin-left: 1px
        margin-bottom: 1px
        background-color: $colorInk
    .slider_context
      position: absolute
      text-align: left
      top: 38px
      left: -2px
      width: 82px
      input
        width: 20px
        padding: 5px
        margin-right: 5px
        text-align: center
        display: inline-block
      label
        display: inline-block
    .slider_labels_container
      position: absolute
      top: 29px
      left: 0
      width: 100%
      .slider_label
        position: absolute
        top: 0
        left: 0
        label
          margin-left: 10px
          max-width: 90px
          word-wrap: break-word
        &:before
          content: ''
          position: absolute
          left: 0
          top: -18px
          width: 1px
          height: 30px
          background: $colorInk
      .slider_label_0
        margin-left: -1px
      .slider_label_1
        left: 20%
      .slider_label_2
        left: 40%
      .slider_label_3
        left: 60%
      .slider_label_4
        left: 80%
      .slider_label_5
        left: auto
        right: 0
        margin-right: -1px
        &:before
          left: auto
          right: 0
        label
          margin-right: 10px
          margin-left: 0
// End filter options in table

// Filter autosuggest
.filter_autosuggest
  position: absolute
  left: 0px
  top: 100%
  z-index: 200
  width: 100%
  box-sizing: border-box
  background-color: #F8F8F8
  color: $colorInk
  padding: 0 10px 10px 10px
  border-left: 1px solid #DCDCDC
  border-right: 1px solid #DCDCDC
  border-bottom: 5px solid #DCDCDC


  li
    margin-top: 10px

  .direct_hit
    font-weight: bold

  .search_hit
    word-break: break-word
    .direct_hit
      font-weight: bold
//

// Filter elements in table (Used inside customers, search filter)
.filter_wrapper
  margin-top: 20px
  .search_hit
    margin-top: 15px
    font-size: 14px
    position: relative
    .number_hit
      font-size: 24px
      font-style: italic
// end filter elements in table

.filter_container
  position: relative
  cursor: text
  width: auto
  padding: 10px 10px 5px 55px
  background: #FFF
  background-position: 8px -74px
  text-align: left
  z-index: 160
  transition: box-shadow 0.5s
  box-shadow: 1px 1px 0px rgba(224, 224, 224, 0.75)

  &:before
    position: absolute
    top: 17px
    left: 15px
    background-image: url("../images/icons/svg/search.svg")
    background-repeat: no-repeat
    background-size: 20px
    height: 25px
    width: 25px
    content: ""

  &:hover
    background: $colorButtonSecondaryHover

  &.disabled
    height: 45px
    background-color: #F7F7F7
    border-top: 0
    border-left: 1px solid #DCDCDC
    border-right: 1px solid #DCDCDC
    border-bottom: 0
    cursor: default
    &:hover
      background-color: #F7F7F7
    &:before
      content: none

  &.with_search_result_type
    padding-right: 138px

    &.with_and_or_search
      padding-right: 260px

  &.flat_filter
    box-shadow: inset 0px 2px 1px -1px #DCDCDC, inset 0px -2px 1px -1px #DCDCDC

  &.rounded_responsive_search
    border: 1px solid #D0D0D0
    display: inline-block
    border-radius: 22px
    width: 500px
    max-width: 100%
    box-sizing: border-box
    z-index: auto
    box-shadow: 0px 0px 0px

  .input_ghost_complete
    opacity: 0.3
    margin-bottom: -6px
    font-size: 20px
    margin-left: -1px
    min-width: 1px

  .placeholder
    position: absolute
    left: 55px
    top: 18px
    opacity: 0.5
    z-index: -1
    word-break: break-all
    height: 24px
    overflow: hidden

  .search_options_container
    position: absolute
    top: 11px
    right: 12px
    display: flex
    align-items: center
    gap: 16px

  .filter_tag
    display: inline-block
    vertical-align: top
    cursor: default
    color: #FFF
    line-height: 100%
    font-size: 14px
    margin: 0 5px 5px 0
    letter-spacing: 0.03rem

    .filter_tag_content
      display: inline-block
      background-color: $colorInk
      border: 1px solid $colorInk
      padding: 10px 10px 11px 10px
      border-radius: $borderRadius
      &.removable
        padding-right: 36px
      &.option
        position: relative
        padding: 6px 8px
        margin: 5px 0 2px
        font-size: 12px
        background-color: #e6e6e6
        border-color: #e6e6e6
        color: #5d5d5d
        min-width: 27px
        text-align: center
      &.with_right_action
        border-top-right-radius: 0
        border-bottom-right-radius: 0
      .icon
        position: absolute
        top: 5px
        right: 5px
        width: 25px
        height: 25px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_half
        background-repeat: no-repeat
        &.remove
          background-position: -74px -74px
        &.filter
          background-position: -25px -425px
          &.selected
            background-position: -50px -425px

      &.context
        border-top-right-radius: 10px
        border-bottom-right-radius: 10px
        border-top-left-radius: 0
        border-bottom-left-radius: 0px
        padding: 8px 26px 8px 10px
        background-color: #f9f9f9
        border: 1px solid #e6e5e5
        border-left: 0
        height: 19px
        vertical-align: top
        &:hover
          background-color: #f6f6f6
        .context_label
          color: $colorInk
          font-size: 10px
          display: inline-block
          line-height: 12px
          margin-top: -3px
          margin-right: 10px
          &.max_width
            max-width: 50px

  .table_filter
    font-family: Arial, Helvetica, sans-serif !important
    font-size: 20px !important
    background-color: rgba(0, 0, 0, 0) !important
    border: 0 !important
    padding: 0 !important
    color: $colorInk
    transition: all 0s !important
    width: 100%
    height: 37px
    margin-bottom: 5px
    &:focus, &:hover
      background-position: 4px 8px

  &.focused_input
    background-color: #F8F9FF
    &:before
      background-image: url("../images/icons/svg/search_blue.svg")
    .placeholder
      opacity: 0.7

// filter elements in table
.table_list, .unapprovedlist
  width: auto
  min-height: 100px
  max-height: 900px
  padding-top: 38px
  overflow: auto

  &.show_tags_list
    li .row_content .column .category_tags_wrapper, li .row_content .column .add_tag
      display: inline-block

  input[type=text], input[type=email]
    vertical-align: top
    display: inline-block

  .other_languages_list
    background-image: linear-gradient(to bottom,#3a3a3a 0%,#444 100%),linear-gradient(to top,#3a3a3a 0%,#444 100%)
    background-color: #444444
    background-position: 0% 0%,0% 100%
    background-repeat: no-repeat
    background-size: 100% 10px

    li
      .column
        padding: 10px
        color: #FFF
      .row_options
        display: block
        background-color: transparent
      .row_content
        padding: 5px 0
        min-height: 37px
      .row_content_wrapper.editing
        .row_content
          background-color: #444444

  .row_content_wrapper
    position: relative
    width: 100%
    min-width: 600px

    &.editing
      .row_content
        padding: 7px 0 6px 0 // override because of tags
        background-color: #FFFFFF
      .row_options
        display: block
      .skill_name
        width: 300px

    .token_name, .access_token, .generic_inline_table_cell
      display: inline-block
      padding: 10px
      min-width: 300px

    .save, .cancel
      display: inline-block
      cursor: pointer
      padding: 9px 15px 9px

  .row_content
    min-height: 33px
    width: 100%
    position: relative

    &.with_options
      margin-right: 200px
      width: auto

    .left_input_label
      display: inline-block
      vertical-align: top
      height: 30px
      min-width: 22px
      padding: 4px 10px
      margin-left: 5px
      line-height: 30px
      margin-right: -4px
      font-weight: bold
      background-color: #CCC
      color: #666

    .sorted_arrow
      display: none
      margin-left: 5px
      width: 0px
      height: 1px
      border-style: solid
      border-width: 7px 5px 0 5px
      border-color: #FFF transparent transparent transparent

    .selection_column
      padding: 12px 12px 0 12px

    .column
      display: inline-block
      vertical-align: top
      width: 200px
      padding: 6px 10px 0 10px
      overflow: hidden
      text-overflow: ellipsis

      &.link
        cursor: pointer
        color: $colorSky
        display: none
      &.short
        width: 100px
      &.column_300
        width: 300px
      &.long
        width: 500px
      &.with_tags
        padding: 8px 0 0 10px
      &.with_equal_padding
        padding: 8px
      &.long_responsive
        width: 50%
      &.short_responsive
        width: 5%
        text-align: center
      &.dispatcher
        width: 100%
        text-align: center
        padding: 15px 10px
        box-sizing: border-box

        span.action
          margin-left: 10px

      &.number
        padding: 6px 6px 6px 6px
        line-height: 35px
        width: auto
        min-width: 50px

      .value
        display: inline-block
        vertical-align: top
        padding: 6px 10px 0 0
        margin: 0 8px 8px 0

        &.ghost
          opacity: 0.5
          max-width: 25%
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap

      .category_tags_wrapper
        display: none

        .li_label
          margin-top: 10px

        // Dragons
        .button
          display: block

      .category_tag
        display: inline-block
        vertical-align: top
        position: relative
        cursor: pointer
        background-color: $colorWhite
        border: 1px solid $colorButtonSecondaryBorder
        padding: 6px 30px 6px 10px
        margin: 0 8px 8px 0
        font-size: 12px
        border-radius: 2px
        max-width: 200px

        &:after
          content: ''
          position: absolute
          right: 10px
          top: 14px
          width: 0
          height: 0
          border-style: solid
          border-width: 5px 5px 0 5px
          border-color: $colorInk transparent transparent transparent
        &:hover:after
          border-color: $colorInk transparent transparent transparent
        &.active_dropdown
          border-bottom-right-radius: 0
          border-bottom-left-radius: 0
          padding-bottom: 5px
          cursor: default
          &:after
            border-width: 0 5px 5px 5px
            border-color: transparent transparent $colorInk transparent

        .category_name
          max-width: 202px
          text-overflow: ellipsis
          display: inline-block

      .add_tag
        vertical-align: top
        cursor: pointer
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width_third
        background-position: -224px -223px
        background-repeat: no-repeat
        background-position: -80px -115px
        transform: rotate(45deg)
        width: 30px
        height: 30px
        margin-top: 2px
        display: none

      .tiny
        font-size: 12px

      .number_of_users, .number_of_occurrences
        font-size: 30px
        font-weight: bold

      .users_overview
        position: absolute
        left: 100px
        top: 10px
        text-align: left
        font-size: 12px
        border: 2px solid $colorInk
        background-color: #FFF
        z-index: 110

        &:before
          width: 0
          height: 0
          content: ''
          top: 5px
          left: -9px
          position: absolute
          border-style: solid
          border-width: 5px 8.7px 5px 0
          border-color: transparent $colorInk transparent transparent

        li
          margin: 5px
          white-space: nowrap
          background-color: $colorInk

          &:hover
            background-color: $colorInk

          span
            margin-right: 2px

          a
            display: block
            color: #FFF !important

            &:hover
              text-decoration: none !important

        img, .nopicture_placeholder
          display: inline-block
          vertical-align: middle
          width: 35px
          height: 35px
          float: none

      .usericon, .ocurrencesicon
        height: 30px
        width: 30px
        float: left
        margin-top: -12px

    .column_vertical_wrapper
      display: inline-block
      vertical-align: middle
      width: 200px
      padding: 5px
      border: none

      &.fixed_menu_right
        margin-right: 155px

    .last_column_wrapper_fixed
      float: right
      position: absolute
      right: 0
      top: 4px

  .row_options
    display: none
    padding: 0 0 0 10px
    position: absolute
    right: 0
    top: 3px
    bottom: 0
    white-space: nowrap

    .row_options_action, .remove, .edit, .approve, .toggle_other_languages, .usher_email, .deactivate, .change_role
      display: inline-block
      vertical-align: middle
      cursor: pointer
      min-height: 30px
      min-width: 30px
      line-height: 30px
      margin: 3px 10px
      font-weight: bold
      color: #4d4d4d
      user-select: none
      white-space: nowrap

    .edit
      background: url("../images/icons/table_list_sprite.png") -150px 0px no-repeat
      &.white
        background: url("../images/icons/table_list_sprite.png") -150px -60px no-repeat
      &:hover
        background-position: -150px -30px
      &.blue
        background-position: -150px -30px

    .remove
      background: url("../images/icons/table_list_sprite.png") -180px 0px no-repeat
      &.white
        background-position: -180px -60px
        visibility: hidden
      &:hover
        background-position: -180px -30px

    .approve
      background: url("../images/icons/table_list_sprite.png") -240px 0px no-repeat
      &:hover
        background-position: -240px -30px

    .usher_email
      background: url("../images/icons/table_list_sprite.png") -300px 0px no-repeat
      &.sent_email
        background-position: -330px 0px
      &.white
        background: url("../images/icons/table_list_sprite.png") -300px -60px no-repeat
      &:hover
        background-position: -300px -30px

    .deactivate
      background: url("../images/icons/table_list_sprite.png") -360px 0px no-repeat
      &.deactivated
        background-position: -420px 0px
        &:hover
          background-position: -420px 0px
      &:hover
        background-position: -360px -30px

.input_placeholder::placeholder
  font-size: 16px
  font-weight: 400
  opacity: 0.5
  color: $colorInk
