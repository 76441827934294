@use "bootstrap/mixins"

.conversation_wrapper
  width: 270px
  top: 45px
  left: -217px
  height: auto
  z-index: 1000
  background-color: #e9e9e9
  position: absolute
  @include mixins.crunchybutton_shadow

  transition: all .2s ease-in-out

  textarea.newmessage
    background-color: #D3D3D3 !important
    border: 0 !important
    font-size: 12px !important
    width: 175px !important
    height: 15px !important
    color: #666 !important
    padding: 10px !important
    border-radius: 2px
    resize: none !important
    font-family: Helvetica !important
    line-height: 120% !important

    &:hover
      background-color: #D3D3D3 !important
      border: 0 !important

  button
    width: 42px
    margin-left: 5px
    height: 35px
    padding: 0
    background-color: #FF931E
    border: 0
    outline: none
    color: #FFF
    font-size: 12px
    border-radius: 2px

  .triangle
    background: url("../images/icons/details_sprite.png") no-repeat -70px -50px
    position: absolute
    left: 80%
    top: -12px
    width: 44px
    height: 24px

  .conversation_navigation
    background-color: #ff931e
    color: #FFF
    height: 15px
    padding: 10px 10px
    .title
      float: left
    .close_conversation, .minimize_conversation
      background: url("../images/icons/details_sprite.png") no-repeat
      position: absolute
      width: 30px
      height: 27px
      top: 4px
      cursor: pointer
      &:hover
        background-color: #ba6b1c

    .close_conversation
      background-position: -37px -19px
      right: 4px
    .minimize_conversation
      background-position: 1px -19px
      right: 35px

  .conversation_content
    border: 1px solid #CCC
    border-top: 0

  .comment_input
    padding: 10px

    .attachment, .sendasmail, label
      display: none
      font-size: 12px !important
      color: #666666 !important
    .attachment
      padding: 5px 10px
      margin-right: 20px
      margin-top: 7px
      background-color: #3FA9F5
      color: #FFF !important
      border-radius: 2px

    &:hover
      .attachment, .sendasmail, label
        display: inline-block
  ul
    max-height: 355px // set max height of container
    overflow: scroll

  li
    padding: 10px 0
    &.self
      .person_content
        float: right
        margin: 0 10px 0 0
      .comment_content
        float: left
        margin: 0 0 0 10px
      .triangle_comment
        background-position: -103px -29px !important
        left: 100%

    &.new_action
      .comment_content
        border: 2px solid #FF931E
        margin: 0px 8px 0 0
      .triangle_comment
        background-position: -71px -10px !important

    .person_content
      float: left
      width: 42px
      color: #666
      margin: 0 0 0 10px
      font-size: 10px

      img
        width: 42px
        height: 42px

      .person_role, .timestamp, .nopicture_placeholder
        float: left
        text-align: center
        width: 100%

      .person_role
        display: none

      .timestamp
        font-weight: bold
        margin: 2px 0 0 0

    .comment_content
      width: 175px
      float: right
      min-height: 36px
      margin: 0 10px 0 0
      padding: 10px
      background-color: #FFF
      font-size: 12px
      border-radius: 2px
      position: relative

      box-shadow: 0px 1px 1px #D2D2D2

      .triangle_comment
        background: url("../images/icons/details_sprite.png") no-repeat
        background-position: -71px -29px
        position: absolute
        right: 100%
        top: 11px
        width: 10px
        height: 10px

      .online_status
        position: absolute
        right: 0px
        top: 0px
        width: 10px
        height: 10px
        border: 0px solid #CCC
        border-width: 0px 0px 1px 1px
        background-color: #E2E2E2
        border-top-right-radius: 2px
        &.online
          background-color: #7AC359
          border-color: #63A546

      .comment_message
        color: #666666

      .name
        color: #4d4d4d
        font-weight: bold
        padding-bottom: 4px
