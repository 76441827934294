@use "bootstrap/mixins"

// Page settings
$mainFont: "IBM-Plex-Sans", "Helvetica Neue", Arial, sans-serif

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorAvocado: #8ae133
$colorWhite: #FFFFFF
$colorButtercup: #F2C94C

$colorBeach: #ffefcd
$colorBeach_dark: #ffd375

// State colors
$colorSelected: $colorSky
$colorFocus: $colorSky
$colorLink: $colorSky
$colorButtonPrimary: $colorInk
$colorButtonSecondaryBorder: #d6d6d6
$colorButtonSecondaryHover: #fafafa
$colorInputBorder: #d6d6d6
$colorInputHover: #F8F9FF
$colorInputEmpty: #f6fbff

// Graytones
$colorGray1: #E0DDDA
$colorGray2: #E7E5E1
$colorGray3: #EFEDEA
$colorPaper: #F5F4F2
$colorGray4: #F8F7F7
$colorGray5: #F9F9F9
$colorGray6: #FBFBFB

// Sprites
$sprite_width: 200px 1100px
$sprite_width_third: 150px 824px
$sprite_width_half: 100px 550px

#timeout_modals:before
  content: ''
  background-color: #CFCFCF
  position: fixed
  z-index: 12001
  opacity: 0.9
  top: 0
  left: 0
  right: 0
  bottom: 0

.modal_cv
  z-index: 12000
  position: fixed
  overflow-y: scroll
  top: 40px
  right: 0
  bottom: 0
  left: 0
  background-color: $colorPaper

  &.modal-show
    animation: modal_show 0.3s forwards

  &.editing .modal_nav
    border-color: $colorBeach_dark
  &.previewing .modal_nav
    border-color: $colorAvocado
  &.own_cv .modal_nav
    border-color: $colorAvocado

  &.editing.my_cv
    z-index: 1000

  #company_cv
    position: fixed
    top: 40px
    left: 0
    right: 0
    bottom: 0
    overflow-y: scroll

  .modal_nav_container
    position: fixed
    left: 0
    top: 0
    z-index: 1100
    width: 100%
    box-sizing: border-box

  .modal_nav
    position: relative
    box-sizing: border-box
    border-left: 8px solid #444
    height: 50px
    background: #FFF
    font-size: 14px
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      z-index: -1
      box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)

    &.preloading
      animation-duration: 1s
      animation-fill-mode: forwards
      animation-iteration-count: infinite
      animation-name: placeHolderPreLoader
      animation-timing-function: linear
      background: linear-gradient(to right, #FFFFFF 8%, #f8f8f8 18%, #FFFFFF 33%)
      background-size: 3000px 104px

    .modal_info
      .modal_label
        font-size: 10px
        margin-top: 6px

    .navigation_icon_wrapper
      position: absolute
      left: 0px
      top: 61px

      &.edit_mode
        top: 106px

      &.show_translation_options_mode
        top: 296px

      .navigation_icon
        border: 0
        box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)

    .navigation_icon
      display: block
      width: 50px
      height: 50px
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width
      background-repeat: no-repeat
      border-right: 1px solid #E4E4E4
      &.dropdown_icon
        margin-left: -12px
        border: 0
        display: inline-block
        vertical-align: middle
      &.download
        background-position: -50px -300px
        &:hover, &:focus
          background-position: -100px -300px
      &.edit
        background-position: -50px -100px
        &:hover, &:focus
          background-position: -100px -100px
      &.preview
        background-position: -50px -800px
        &:hover, &:focus
          background-position: -100px -800px
      &.settings
        background-position: 0px -1000px

    .close_modal
      float: right
      padding: 0 12px 0 12px
      height: 50px
      background-color: #F8F8F8
      border-left: 1px solid #E4E4E4
      color: $colorSky

  .read_only_hider
    width: 50px
    height: 400px
    position: absolute
    top: 0
    left: 8px
    background-color: $colorPaper
    background: linear-gradient(to top, rgba(255,255,255,0) 0%,$colorPaper 10%)
    z-index: 10

  .read_only_user_container
    position: fixed
    left: 8px
    top: 59px
    width: 50px
    height: 50px
    z-index: 9

    > img
      width: 50px
      height: 50px
      border-radius: 2px
      box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05), 0 12px 96px rgba(0,0,0,0.1), 0 12px 36px rgba(0,0,0,0.05)

.modal-wrapper
  position: fixed
  left: 50%
  top: 15%
  width: 650px
  margin-left: -300px
  z-index: 12001
  box-sizing: border-box
  border: 1px solid $colorGray1
  background-color: $colorPaper
  color: $colorInk
  @include mixins.crunchyelement
  font-size: 14px
  cursor: auto

  &.modal-show
    animation: modal_show 0.3s forwards

  &.scrollable_content
    .modal_content
      overflow-y: auto
      max-height: calc(85vh - 55px - 102px) // 15% top, footer and header

  &.extended_modal_size
    width: 900px
    margin-left: -450px
    top: 10%
    &.scrollable_content
      .modal_content
        max-height: calc(90vh - 55px - 128px) // 10% top, footer and extended header

  &.full_modal_size
    width: calc(100% - 50px)
    margin-left: 0
    top: 25px
    left: 25px
    right: 25px
    &.scrollable_content
      .modal_content
        max-height: calc(100vh - 55px - 112px - 25px) // 25px top, footer and header + step guides

  &.small-modal
    width: 300px
    margin-left: -150px
    .modal_content
      .modal_extra_padding
        padding: 10px 10px 20px 10px

    .progressbar_container
      width: 250px

  h3
    font-size: 0.9em
    margin-bottom: 0

  .close_view, .back_view
    height: 25px
    width: 25px
    padding: 10px
    position: absolute
    top: 0
    right: 0
    @include mixins.interactive
    &:after
      content: ''
      background: url("../images/icons/new_cv_sprites_x2.png")
      background-size: $sprite_width_third
      background-repeat: no-repeat
      background-position:  -82px -118px
      height: 25px
      width: 25px
      display: block

  .back_view
    right: auto
    left: 0px
    &:after
      background-position: -82px -268px
      transform: rotate(180deg)

  .popupheading
    font-size: 20px
    padding: 20px
    background: $colorWhite
    border-bottom: 1px solid $colorGray1
    text-align: center

    img, .nopicture_placeholder
      height: 60px
      width: 60px
      margin-right: 15px
      float: left
      border-radius: 50%

    span
      display: block
      font-size: 18px
      font-weight: bold

    h1
      font-size: 1.5em
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden

    .radiobutton_container
      margin-top: -10px
      font-size: 16px

  label
    font-weight: bold
    font-size: 14px
    white-space: pre-wrap
    margin-right: 30px
    &.inline_label
      display: inline-block
      font-size: 20px
      vertical-align: middle
      color: #777

    &.small
      font-size: 12px

    &.red_label
      color: #bb1212

    &.with_margin_top
      margin-top: 10px

  .steps
    text-align: center
    padding-bottom: 5px
    font-size: 14px
    letter-spacing: 0.01rem
    display: block

    &.left_steps
      text-align: left

    &.with_contextual_help
      margin-right: 20px

  .subheading
    padding: 10px
    font-size: 12px
    background-color: #fbfbfb
    border-bottom: 1px solid #DDD
    text-align: center
    white-space: pre-line

  .modal_content
    text-align: left

    .modal_top_down_padding
      padding: 10px 0 20px 0

    .modal_extra_padding
      padding: 20px 50px
      &.adjusted_padding
        padding-top: 10px
        padding-left: 20px
        padding-right: 20px

    .select_grid_container
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))

    .property_list
      display: flex
      flex-direction: column
      gap: 8px
      padding-top: 4px
      padding-left: 30px

    .property_list_item
      display: flex
      gap: 4px
      margin: 5px 0 0 0
      font-size: 12px
      color: $colorInk

      .property_list_item_check
        display: block
        transform: translateY(2px)
        width: 14px
        height: 14px
        flex-shrink: 0

    .emphasized_text
      font-size: 24px

    .notification_message
      display: inline-block
      text-align: center
      padding: 8px 20px 8px 40px
      border-radius: 20px
      border: 1px solid #DDDDDD
      background-color: #F2F2F2
      margin-left: 20px

      &:before
        content: '!'
        position: absolute
        right: 100%
        top: -7px
        margin-right: -30px
        font-size: 24px
        font-style: italic
        font-weight: bold
        width: 45px
        height: 45px
        line-height: 45px
        background: #F2F2F2
        border: 1px solid #DDD
        border-radius: 50%

    .styled_table
      border-collapse: collapse
      font-size: 0.9em
      min-width: 400px

      thead tr
        background-color: $colorInk
        color: #ffffff
        text-transform: uppercase
        font-size: 10px
        letter-spacing: 0.01rem

      th, td
        padding: 10px

      td
        border-right: 1px solid #243d59

      th
        border-right: 1px solid #243d59
        &.indent_th
          padding-left: 35px
          position: relative
          &:before
            content: ''
            position: absolute
            left: 15px
            top: 10px
            width: 1px
            height: calc(50% - 10px)
            background: #999999

          &:after
            content: ''
            position: absolute
            left: 15px
            top: 50%
            width: 10px
            height: 1px
            background: #999999

      tbody tr
        background: #f4f4f4
        border-bottom: 1px solid #e4e4e4

      tbody tr:last-of-type
        border-bottom: 0

      tbody td:last-of-type, thead th:last-of-type
        border-right: 0

      .active_row
        background: #FFF

    .input_group
      border-top: 2px solid $colorGray2
      margin-top: 10px
      position: relative
      &.adjusted_for_input_container
        margin: 20px 20px 10px 20px
      .group_heading_container
        position: absolute
        width: 100%
        text-align: center
        top: -13px
        .group_heading
          font-size: 10px
          text-transform: uppercase
          background-color: $colorPaper
          padding: 0 15px
          display: inline-block
          color: #868686

    .children_steps_left
      .steps
        text-align: left

    .max_height_overflow_container
      overflow-y: auto
      max-height: 60px
      padding: 10px 10px 20px 10px
      background: #FFF
      box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 3px 1px
      border: 1px solid #2f8ec6

    .input_container
      padding-top: 10px
      margin: 0 20px

      &.without_margin_right
        margin-right: 0

      &.with_top_margin
        margin-top: 10px

      &.with_margin_bottom
        margin-bottom: 10px

      .generated_by_llm
        position: absolute
        top: 1px
        right: 1px
        bottom: 1px
        width: 30px
        background: linear-gradient(339deg, #7f60ff1f, transparent)
        &:before
          content: '✨'
          font-size: 16px
          position: absolute
          top: 7px
          left: 9px
          color: transparent
          text-shadow: 0 0 0 rgb(138, 37, 238)
        &.pulsate
          animation: animate_in_opacity 1s infinite alternate

    .container_table_cell
      display: table
      width: 100%
      &.with_margin
        margin: 10px 0 0 0

    .section_indicator
      margin: 0 10px 10px 0
      padding: 6px 12px
      background: #FFF
      font-size: 14px

    .translation_margin_container
      margin-right: -84px
      margin-top: 20px

    .input_table_cell
      display: table-cell
      min-width: 50px
      text-shadow: 0px 0px
      vertical-align: middle

      input
        width: 100%

    .action_table_cell
      display: table-cell
      cursor: pointer
      width: auto
      text-align: center
      vertical-align: middle
      background-color: $colorInk
      color: #FFF

    // Duplicate of block_section_wrapper input styles
    input
      outline: none
      border: 1px solid #D6D6D6
      background-color: #FFF
      padding: 8px
      font-size: 14px
      box-sizing: content-box
      font-family: $mainFont
      border-radius: 5px
      letter-spacing: 0.02rem
      box-sizing: border-box
      font-family: $mainFont

      &:hover
        background-color: #F8F9FF
        border-color: #c9c9c9
      &:focus
        border-color: $colorSky

      &.inline_input
        display: inline-block
      &:-ms-input-placeholder
        color: #AAA !important
      &::-ms-input-placeholder
        color: #AAA
      &.invalid, &.invalid:hover, &.invalid:focus
        background: #fbe3e4
        background-color: #fbe3e4
        border-color: #fbc2c4

      &.full_width
        display: block
        width: 100%

      &.with_action
        border-bottom-right-radius: 0px
        border-top-right-radius: 0px

    .input_label
      color: #757575
      font-weight: normal
      font-style: italic
      margin-left: 10px

    select
      max-width: 235px
      min-width: 235px

    .dropdown_container_container
      max-width: 235px
      min-width: 235px

    .row
      position: relative
      margin-top: 20px
      width: 100%
      float: left

    .info_steps
      font-weight: normal
      font-style: italic

    .overflow_container
      max-height: 130px
      overflow-y: auto

    .five_by_ten_select_boxes_wrapper
      width: 504px

    .select_boxes
      cursor: pointer
      width: 50px
      height: 50px
      margin: 2px
      background-color: #D3D3D3
      border: 1px solid #BBBBBB
      color: #999
      user-select: none
      &.selected
        background-color: $colorSelected
        border: 1px solid $colorSelected
        color: #FFF
      &.small
        padding: 15px
        line-height: 10px
        margin: 0 10px 0 0
        font-size: 20px
        height: auto
        width: auto
      &.extra_small
        padding: 5px 0 5px 5px
        margin: 0 10px 10px 0
        span
          background-color: #FFF
        &.line_0
          padding-right: 5px
          span
            display: block
            float: left
            height: 5px
            width: 35px
        &.line_5
          span
            display: block
            float: left
            width: 5px
            height: 5px
            margin-right: 5px
        &.line_2
          span
            display: block
            float: left
            width: 5px
            height: 5px
            margin-right: 3px
        &.line_1
          span
            display: block
            float: left
            width: 8px
            height: 5px
            margin-right: 5px
        &.line_6
          span
            display: block
            float: left
            width: 7px
            height: 5px
            margin-right: 3px
        &.line_7
          span
            display: block
            float: left
            width: 15px
            height: 5px
            margin-right: 5px

      &.underline
        text-decoration: underline
      &.bold
        font-weight: bold
      &.italic
        font-style: italic

      &.left_align, &.center_align, &.right_align
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: 300px
        background-repeat: no-repeat
      &.left_align
        background-position: 2px -148px
      &.center_align
        background-position: -48px -148px
      &.right_align
        background-position: -96px -148px

      &.repeat_flow_sideways, &.repeat_flow_downwards, &.repeat_flow_no_repeat, &.paragraph_break_no_break, &.paragraph_break_break_after, &.balance_flow_sideways
        background-image: url("../images/icons/template_generator_sprite_x2.png")
        background-size: 600px
        background-repeat: no-repeat
      &.repeat_flow_sideways
        background-position: -300px 0px
      &.repeat_flow_downwards
        background-position: -300px -100px
      &.repeat_flow_no_repeat
        background-position: -300px -200px
      &.balance_flow_sideways
        background-position: -300px -300px
      &.paragraph_break_no_break
        background-position: -400px -100px
      &.paragraph_break_break_after
        background-position: -400px -200px

    .center_box_container
      padding: 10px
      margin: 10px
      display: inline-block
      vertical-align: middle
      &.top_aligned
        vertical-align: top
        padding: 0
      &.without_margin
        margin: 0

    .two_column
      width: 50%
      min-width: 200px
      display: inline-block
      padding: 10px 20px
      box-sizing: border-box
      vertical-align: top

      &.no_padding
        padding: 0

      &.temp_single_column
        width: 100%

      &.small
        width: 130px

      &.large
        width: 350px

      ul
        max-height: 250px
        overflow-y: auto
        &.preload
          li
            animation-duration: 1.2s
            animation-fill-mode: forwards
            animation-iteration-count: infinite
            animation-name: placeHolderPreLoader_input
            animation-timing-function: linear
            background: #FFFFFF
            background: linear-gradient(to right, #FFFFFF 8%, #EEEEEE 18%, #FFFFFF 33%)
            background-size: 2880px 100%

      li

        &.collaborator
          font-size: 12px
          padding: 5px
          margin: 0
          background: #FFF
          border: 1px solid #DDD
          border-bottom-width: 0
          cursor: pointer
          position: relative

          &:last-child
            border-bottom-width: 1px

          &:hover
            .action_text_helper
              display: block

        img, .nopicture_placeholder
          height: 25px
          max-width: 25px
          min-width: 25px
          border-radius: 0
          font-size: 10px
          line-height: 24px
          display: inline-block
          vertical-align: middle
          float: none

        .text_item
          display: inline-block
          vertical-align: middle
          margin-left: 10px
          max-width: 170px
          max-width: calc(100% - 50px)
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap

          &.wider_text_item
            width: calc(100% - 100px)

          .sub_text_item
            font-size: 10px
            color: #666

        .action_text_helper
          display: none
          position: absolute
          right: 10px
          top: 9px
          opacity: 0.5

        .muted_gray
          opacity: 0.5

        &.featured_item
          background: #EEE
          cursor: default
          .is_already_active
            position: absolute
            right: 10px
            top: 9px
          .action_text_helper
            display: block

    .inner_modal_container
      padding: 10px
      margin: 10px
      background-color: #f5f5f5
      border: 1px solid #DCDCDC

      &.without_margin_left
        margin-left: 0

    .select_option
      .image
        height: 70px
        width: 60px
        background-image: url("../images/icons/filetype_sprite_x2.png")
        background-repeat: no-repeat
        background-size: 360px 140px

      &.selected
        .word_image
          background-position: 0px -70px
        .pdf_image
          background-position: -60px -70px
        .ppt_image
          background-position: -120px -70px
        .csv_image
          background-position: -180px -70px
        .xlsx_image
          background-position: -240px -70px
        .indesign_image
          background-position: -300px -70px

      .word_image
        background-position: 0px 0px
      .pdf_image
        background-position: -60px 0px
      .ppt_image
        background-position: -120px 0px
      .csv_image
        background-position: -180px 0px
      .xlsx_image
        background-position: -240px 0px
      .indesign_image
        background-position: -300px 0px

    .download_file
      color: #52A5D6
      cursor: pointer

      &:hover
        text-decoration: underline

      .word_selected, .pdf_selected, .international_selected, .local_selected
        text-transform: uppercase
        padding: 4px
        color: #FFF

    .checkbox_list
      margin: 0 20px
      max-height: 200px
      overflow-y: auto
      &.without_margin
        margin: 10px 0 0 0
      &.disabled_list
        li
          cursor: default
          padding-left: 25px
          &:hover
            background-color: $colorWhite
          &:before
            content: ''
            position: absolute
            left: 0
            top: 0
            width: 10px
            height: 100%
            background: $colorInk

      &.gallery_list
        li
          width: 49px
          height: 49px
          display: inline-block
          vertical-align: top
          padding: 0
          margin: 0 10px 10px 0
          text-align: center
          img
            max-height: 100%
            max-width: 100%
      li
        width: 100%
        padding: 12px
        padding-right: 40px
        margin: 0 0 10px 0
        box-sizing: border-box
        position: relative
        cursor: pointer
        text-overflow: ellipsis
        background-color: $colorWhite
        border: 1px solid $colorInputBorder
        border-radius: 5px

        &.modal_list_item_in_focus
          background-color: $colorSelected
          color: #FFF
          &:after
            content: '⏎'
            position: absolute
            color: $colorWhite
            top: 15px
            right: 16px
            font-size: 12px
          &:hover
            background-color: $colorSelected
            color: #FFF

        &:hover
          background-color: $colorButtonSecondaryHover

        &.two_actions
          padding-right: 84px

        &:hover
          .custom_checkbox .checkmark
            display: inline-block
            background-position: -241px -151px

        &.selected, &.selected:hover
          background-color: $colorSelected
          border-color: $colorInputBorder
          color: #FFF
          label
            color: #FFF !important
            font-weight: normal !important
          .custom_checkbox
            background-color: $colorSelected
            border-color: $colorSelected
            .checkmark
              display: inline-block
              background-position: -116px -190px

        // Section support duplicate
        &.disabled_list_item
          border: 0
          cursor: default
          padding: 10px 10px 10px 10px
          margin: 5px 0 20px 0
          color: $colorInk
          background-color: #fff6e0
          font-size: 13px
          line-height: 140%
          letter-spacing: 0.02rem
          position: relative
          border-radius: 6px
          box-sizing: border-box
          &:hover
            background-color: #fff6e0

        .custom_checkbox
          position: absolute
          top: 0px
          right: 0px
          user-select: none
          box-sizing: border-box

          &.second_item
            right: 42px

        .custom_checkbox
          width: 32px
          height: 32px
          margin: 6px
          background-color: white
          border: 1px solid $colorSelected
          border-radius: 5px

          .checkmark
            width: 25px
            height: 25px
            display: none
            background: url("../images/icons/new_cv_sprites_x2.png")
            background-size: $sprite_width_third
            background-repeat: no-repeat
            background-position: -4px -190px

  .modal_actions
    padding: 10px 20px
    text-align: left
    position: relative

    &:after
      clear: both
      content: ''
      display: block

    // Duplicate of block_section_wrapper input styles
    input
      outline: none
      border: 1px solid #D6D6D6
      background-color: #FFF
      padding: 8px
      font-size: 14px
      box-sizing: content-box
      font-family: $mainFont
      border-radius: 5px
      letter-spacing: 0.02rem
      box-sizing: border-box
      font-family: $mainFont

      &:hover
        background-color: #F8F9FF
        border-color: #c9c9c9
      &:focus
        border-color: $colorSky

      &.inline_input
        display: inline-block
      &:-ms-input-placeholder
        color: #AAA !important
      &::-ms-input-placeholder
        color: #AAA
      &.invalid, &.invalid:hover, &.invalid:focus
        background: #fbe3e4
        background-color: #fbe3e4
        border-color: #fbc2c4

      &.full_width
        display: block
        width: 100%

      &.with_action
        border-bottom-right-radius: 0px
        border-top-right-radius: 0px

    .input_steps
      font-size: 12px
      font-weight: bold
      margin-bottom: 10px

    .cancel_modal
      padding: 10px 16px
      font-size: 12px
      margin-left: 20px
      display: inline-block
      border-radius: 5px
      background-color: #E4E4E4

    .error
      color: #bb1212

    .error_message
      color: #999999
      font-size: 12px
      margin: 10px 20px 0

    .large_button + .cancel_modal
      padding: 14px 30px
