@media only screen and (max-device-width: 1520px), screen and (max-width: 1520px)
  .table_list .row_content .column
    width: 200px
    &.short
      width: 100px

@media only screen and (max-device-width: 1300px), screen and (max-width: 1300px)
  .table_list
    padding-top: 0
    .table_heading
      display: none
    &.users_table
      .table_heading
        display: none
      .row_content_wrapper
        min-height: 76px
      .row_options
        white-space: normal
        .button
          margin-top: 5px
          vertical-align: top

@media only screen and (max-device-width: 1005px), screen and (max-width: 1005px)
  .table_list
    padding-top: 0

    .row_options
      white-space: normal
      .button
        margin-top: 5px
        vertical-align: top

@media only screen and (max-device-width: 750px), screen and (max-width: 750px)
  .interactive_cards.list_version li
    .inner_container.right_absolute_action
      padding-right: 75px
    .button.absolute_action
      position: relative
      right: auto
      top: auto


@media only screen and (max-device-width: 922px), screen and (max-width: 922px)
  .toggled_sidebar + #main_content
    .filter_container.with_search_result_type
      .search_options_container
        display: none
    .interactive_cards .full_width
      min-width: 300px
      .secondary_actions_block.with_bottom_information_card
        padding-top: 25px
        text-align: center
        width: auto
        box-sizing: border-box
      .bottom_information_card
        top: 5px
        left: 0px
        width: 100%

@media only screen and (max-device-width: 980px), screen and (max-width: 980px)
  .toggled_sidebar + #main_content
    .search_categories_container .search_category
      &.label
        display: none

@media only screen and (max-device-width: 820px), screen and (max-width: 820px)
  .toggled_sidebar + #main_content
    .search_categories_container .search_category
      padding: 20px


@media only screen and (max-device-width: 660px), screen and (max-width: 660px)
  .search_categories_container .search_category
    &.label
      display: none
  .filter_options_divider
    display: none
  .table_list .row_options
    right: auto
    left: 10px

@media only screen and (max-device-width: 592px), screen and (max-width: 592px)
  .filter_container.with_search_result_type
    padding-right: 0
    .search_options_container
      display: none

@media only screen and (max-device-width: 450px), screen and (max-width: 450px)
  .dropdown_container.with_margin_left
    margin-left: 0
    display: block
    margin-top: 5px

  .flex_wrapper
    margin-bottom: 10px !important
    &.without_margin
      margin-bottom: 0px !important

@media only screen and (max-device-width: 850px), screen and (max-width: 850px)
  .interactive_cards
    li .inner_container.interactive:hover
      &:before
        display: none
      .with_absolute_icon
        display: none
      .anti_hover_text
        display: block
      .hover_text
        display: none

@media only screen and (max-device-width: 900px), screen and (max-width: 900px)
  .table_cell_wrapper
    &.break_on_mobile
      display: block !important
      .table_cell_wrapper--divider
        margin-right: 0
        &:after
          display: none
  .flex_wrapper
    gap: 0 !important
  .mobile_only_wrapper
    display: block !important
    &.inlineblock_wrapper
      display: inline-block !important
  .mobile_only_wrapper_hide
    display: none !important
  .inline_block_on_mobile
    display: inline-block !important
  .search_category
    display: none
    &.dropdown
      display: block
      border-right: none
    &.show_override
      display: block
  .block_section_wrapper .block_section.full_width.expand_international_wrapper
    display: block !important
    width: auto !important
  .block_section_wrapper .block_section.full_width.hide_international_wrapper
    display: none !important
