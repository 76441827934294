.currentProject, .noCurrentProject
	width: 100%
	background-color: #EEE
	padding: 0.2em
	border: 1px dotted #CCC
	margin-bottom: 1em
	clear: both
	.customerAndDescription
		float: left
	.participants
		float: right
	a
		text-decoration: underline

.noCurrentProject
	background-color: #FCC
