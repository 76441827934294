// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorPaper: #F5F4F2

.open_dashboard_modal
  #dashboard_container
    visibility: hidden
  header#product-header
    visibility: hidden

.system_errormessage, .system_updatemessage, .system_offline_message
  position: absolute
  top: -100px
  left: 0
  width: auto
  display: none
  padding: 10px 20px
  color: #FFF
  z-index: 200000
  opacity: 0

  &.error
    animation: animSlideTop 0.3s cubic-bezier(0.7,0,0.3,1) forwards
    opacity: 1
    // IE HACK

  .close, .reload
    display: inline
    text-decoration: underline
    cursor: pointer

    &:hover
      text-decoration: none

.system_errormessage
  background-color: #AB0000

.system_updatemessage
  background-color: #0083d1

.system_offline_message
  background-color: #AB0000
  display: block
  animation: animSlideTop 0.3s cubic-bezier(0.7,0,0.3,1) forwards

header#product-header
  position: fixed
  left: 0
  top: 0
  z-index: 1100
  width: 100%
  box-sizing: border-box

.primary_nav
  float: right
  position: relative
  color: $colorPaper
  .tab
    display: inline-block
    position: relative
    padding: 8px 15px
    outline: none
    margin: 0 15px 0 0
    color: $colorPaper
    height: 27px
    a:focus
      text-decoration: underline
    &.active
      background-color: $colorSky

  .nav_icon
    display: inline-block
    vertical-align: middle
    width: 24px
    height: 24px
    background-repeat: no-repeat

  .navigationlabel
    display: inline-block
    vertical-align: top
    line-height: 25px

#my_cv_tab
  .nav_icon
    background-image: url("../images/icons/svg/cv_paper.svg")
    background-size: 17px

#dashboard_tab
  .nav_icon
    background-image: url("../images/icons/svg/search_paper.svg")
    background-size: 18px

#settings_tab
  .nav_icon
    background-image: url("../images/icons/svg/settings_paper.svg")
    background-size: 20px

#settings_list
  display: none

#settings_tab:hover + #settings_list, #settings_list:hover
  width: 200px
  background-color: $colorInk
  position: absolute
  right: -4px
  top: 42px
  color: $colorPaper
  z-index: 450
  display: block
  box-shadow: 0 1px 3px rgba(0,0,0,.25),inset 1px 1px 15px rgba(0,0,0,.10)

  #settings_links
    margin: 10px 0

    li:first-child
      margin-top: 0px

    li
      .head
        padding: 10px 10px 5px
        color: #FFF
        font-size: 14px

      a
        display: block
        line-height: 1em
        text-decoration: none
        font-size: 12px
        padding: 7px 10px
        color: $colorPaper
        min-height: 0

      a:hover
        text-decoration: underline

.innerHeader
  background-color: $colorInk
  position: relative
  width: 100%
  z-index: 4

  &:after
    clear: both

#topNav
  text-align: right
  color: $colorPaper
  padding-top: 5px
  font-size: 0.7em
  margin-right: 15px
  float: right

  a
    color: $colorPaper

    &:focus
      text-decoration: underline

  li
    background-color: transparent

  a:hover
    text-decoration: underline

  .current_user
    width: 140px
    height: 15px
    margin-left: 10px
    display: block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

@media only screen and (max-device-width: 850px), screen and (max-width: 850px)
  #settings_tab
    width: 50px
    margin-right: 0
    #settings_list
      width: 175px
    .navigationlabel
      display: none

    .nav_icon
      left: 10px

  #topNav
    .current_user
      width: 100px

@media only screen and (max-device-width: 630px), screen and (max-width: 630px)
  .primary_nav .navigationlabel
    display: none

  #topNav
    margin-right: 10px
    .current_user
      width: 50px
