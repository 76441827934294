#container
	min-height: 100%
	margin: 0 auto
	background-color: #F5F4F2
	width: 100%

.hidden
	visibility: hidden

.clear
	clear: both

form ul li
	clear: both
