@media only screen and (max-device-width: 1600px), screen and (max-width: 1600px)
  #dashboard
    #main_content
      .filter_options_container .search_contextual_action .hide_first
        display: none

@media only screen and (max-device-width: 1400px), screen and (max-width: 1400px)
  #dashboard
    #main_content
      .filter_options_container .search_contextual_action
        float: none

@media only screen and (max-device-width: calc(80rem - 1px)), screen and (max-width: calc(80rem - 1px))
  #dashboard
    .filter_options_container .slider_container .slider_labels_container.mobile_only_wrapper_hide .slider_label_5
      right: auto
      left: 100%
      margin-left: -10px
      &:before
        left: 0
        right: auto

    .extend_proposal
      #main_content
        .mobile_only_wrapper
          display: block !important
        .search_category
          display: none
          padding: 20px 30px
          &.dropdown
            display: block
            border-right: 0
          &.show_override
            display: block
        .filter_container .search_options_container
          display: none

  .search_categories_container
    .search_category.search_tabs
      padding: 10px 15px
      text-transform: initial

@media only screen and (max-device-width: calc(64rem - 1px)), screen and (max-width: calc(64rem - 1px))
  .department_wrapper
    max-width: calc(60vw - 120px)
  #dashboard
    .extend_proposal
      #offers.toggled_sidebar
        width: 290px
        + #main_content
          margin-right: 300px
        .block_container.search_results_container.contained_list
          display: inline-block
          vertical-align: top
          width: 288px
          &.full_width
            width: auto
            display: block


@media only screen and (max-device-width: calc(48rem - 1px)), screen and (max-width: calc(48rem - 1px))
  #dashboard
    #offers, .hide_with_proposals
      display: none

    #main_content
      margin-right: 0px !important
    .dashboard_toggler
      display: none
    .department_wrapper
      display: none
    .block_section_wrapper.normal_margin_top
      margin-top: 10px
