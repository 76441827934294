@use "bootstrap/mixins" as bootstrap-mixins

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorPaper: #F5F4F2

html:not(:has(.modern-styles)), body:not(:has(.modern-styles))
  min-height: 100%
  height: 100%

  &.open_lightbox
    overflow: hidden
    #editable_cv .main_container
      overflow: hidden

#main_container:not(:has(.modern-styles))
  min-height: 100%
  height: 100%

#paste_hack
  height: 20000px
  background: #E8E8E8

body.selected_proposal_modal, body.share_proposal_modal
  overflow: hidden

#dashboard
  padding-top: 54px
  color: $colorInk
  background-color: $colorPaper
  min-height: 100%

  .proposal
    .removeSection
      display: none

  .dashboard_toggler
    position: absolute
    right: 10px
    top: 60px
    z-index: 100

    &.fixed_toggler
      position: fixed
      right: 10px
      top: 60px
    &.left_side
      right: auto
      left: 10px

  .dashboardcontainer
    width: auto
    position: relative
    text-align: center

  .proposal_list_container
    max-height: 365px
    overflow: hidden
    &.show_all_proposals
      max-height: none

  // TODO Potentially unused css
  #main_content
    width: auto
    margin-right: 310px
    min-height: 500px

    z-index: 10
    position: relative

  .extend_proposal
    #main_content
      .search_categories_container .search_category.search_tabs
        padding: 10px 15px
    .toggled_sidebar + #main_content
      .search_categories_container .search_category
        &.label
          display: none
    #main_content
      .filter_options_container .search_contextual_action
        float: none

    #offers.toggled_sidebar
      width: 614px
      + #main_content
        margin-right: 624px
      .block_container.search_results_container.contained_list
        display: inline-block
        vertical-align: top
        width: 300px
        &.full_width
          width: auto
          display: block

    #selected_offer .dropdown_container
      width: auto

  #offers + #main_content
    margin-right: 0px

  #offers.toggled_sidebar
    width: 300px
    margin-right: 10px
    section
      display: block

  #offers.toggled_sidebar + #main_content
    margin-right: 310px

  #offers
    width: 0px
    margin-bottom: 100px
    float: right
    position: relative
    section
      display: none

  .select_offer_first
    color: $colorSky
    font-weight: bold
    letter-spacing: 1px
    margin-bottom: 10px

  #selected_offer
    margin-top: 10px

    .autosuggest .autosuggest_element
      padding: 10px

    .selector_label
      margin-bottom: 5px
      font-size: 13px
      .emphasize
        font-weight: bold
      .disabled
        text-decoration: line-through
      &.with_margin_top
        margin-top: 10px

    .actions_container
      margin-top: 10px

    .included_label
      margin: 0 0 8px
      padding-top: 10px
      padding-bottom: 2px
      display: block
      border-bottom: 1px solid $colorInk
      font-size: 12px
      text-transform: uppercase

    .included_element_container

      // Override handle
      .orderable_container .ui-sortable-handle>.handle.small_grid
        top: 50%
        transform: translateY(-50%)

      .confirmation_overlay
        width: 100%
        height: 100%
        top: 0
        right: 0
        position: absolute
        color: #FFF
        background-color: #f4f4f4
        @include bootstrap-mixins.interactive
        z-index: 10
        .remove_cv
          padding: 15px
          @include bootstrap-mixins.color_style(#dd7f6d)
        .cancel_remove_cv
          color: #555
          padding: 15px

      .included_element
        font-size: 12px
        margin: 0 0 5px 0
        padding: 6px 5px 5px 70px
        background-color: #FFF
        border-radius: 5px
        user-select: none

        .image_container
          width: 35px
          height: 35px
          position: absolute
          left: 25px
          top: 50%
          transform: translateY(-50%)

          img
            width: 35px
            border-radius: 50%

          .img_hover
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0

        .title
          font-weight: bold

@media only screen and (max-device-width: 200px), screen and (max-width: 200px)
  #dashboard
    #main_content
      float: left
      width: 100%
      margin: 0px
      border-left: 0px
      padding-left: 0px
      border-right: 0px
      @include bootstrap-mixins.crunchybutton_reset

@media only screen and (max-device-width: 615px), screen and (max-width: 615px)
  #dashboard #main_content
    #cvs
      #cvs_list.cvs_list_table
        .updated_ago
          display: none

      #cvs_list
        margin: 0
