@use "sass:color"

@use "bootstrap/mixins"

// CVP Colors
$colorInk: #0A1D32
$colorSky: #4363FF
$colorPaper: #F5F4F2
$colorAvocado: #8ae133

$colorBeach: #ffefcd
$colorBeach_dark: #ffd375

// Sprites
$sprite_width: 200px 1100px
$sprite_width_third: 150px 824px
$sprite_width_half: 100px 550px

#masterdata
  .breadcrumbs
    padding: 15px 50px 10px 15px

#settings:not(:has(.modern-styles))
  padding-top: 45px
  background-color: #F5F4F2
  height: 100%
  box-sizing: border-box
  overflow-y: auto

  > div
    height: 100%

  .scroll_nav ul
    padding-top: 80px

  .topLabel
    position: absolute
    top: -1.3em
    left: 0
    font-size: 0.9em
    overflow: hidden
    white-space: nowrap
    font-weight: bold
    padding: 0
    margin: 0
    border: none

  h1
    text-align: left
    margin-bottom: 10px

    &.without_margin
      margin-bottom: 0

  // Override
  .modal-wrapper
    cursor: default
    letter-spacing: normal
    h1
      text-align: center
      margin: 0

  h4
    color: #757575

  h5
    display: block
    font-size: 30px
    letter-spacing: 1px
    text-align: left
    font-weight: 200
    padding: 10px 0

    &.expanded_section
      &:after
        content: ''
        position: absolute
        top: 3px
        right: 3px
        width: 50px
        height: 50px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width
        background-repeat: no-repeat
        background-position: -100px -400px

    &.collapsed_section
      border-bottom: 1px solid #DCDCDC
      &:after
        content: ''
        position: absolute
        top: 3px
        right: 3px
        width: 50px
        height: 50px
        background: url("../images/icons/new_cv_sprites_x2.png")
        background-size: $sprite_width
        background-repeat: no-repeat
        background-position: -50px -400px

    &.clean_centered
      text-align: center
      background-color: transparent
      border: 0
      padding: 0
      font-size: 26px

  .ui-draggable-dragging
    z-index: 1000
    position: fixed !important
    cursor: url("../images/cursors/closedhand.cur") 7 5, default
    opacity: 0.5

  .settings_platform
    background: $colorPaper
    min-height: 100%
    overflow: hidden
    height: auto
    padding-bottom: 50px
    box-sizing: border-box

    &.full_responsive
      min-width: 0
      width: 100%
      border: 0

    &.with_scroll
      padding-bottom: 0

    &.with_sidebar
      margin-right: 220px
      z-index: 2
      width: auto
      border-right: 0
      min-width: 0
      float: none

    &.autoplatform_with_sidebar
      width: auto
      margin-right: 220px
      float: none
      min-width: 300px
      max-width: none

    .section_info
      margin: 10px 0

      .dropdown_container
        margin-top: 10px

      .important
        display: block
        margin: 5px 0
        min-height: 15px
        .bold_span
          font-weight: bold
          margin-right: 2px

    .sidebar_right
      width: 280px
      float: right

    .container_left
      margin-right: 290px

    .settings_form
      input[type=email], input[type=text]
        width: 400px

    .unapprovedlist
      max-height: 800px

      li.table_heading
        border-bottom-color: $colorBeach
        color: $colorInk

      .sorted, .category_tag
        background-color: $colorBeach
        color: $colorInk
        &:after
          border-color: $colorInk transparent transparent transparent
        &:hover:after
          border-color: $colorInk transparent transparent transparent
        &.active_dropdown
          &:after
            border-color: transparent transparent $colorInk transparent

      .button.blue_on_dark
        background-color: #FFFFFF
        border-color: $colorInk
        color: $colorInk
        &:hover
          background-color: color.adjust(#FFF, $lightness: -5%)

      .users_overview
        left: 30px

      .row_options
        display: block

    .labels_matrix
      min-width: 1000px

    .users_table
      max-height: 600px
      .row_content_wrapper
        margin-right: 175px

        &.editing
          margin-right: 0

      .select_country_wrapper
        margin: 0

        label
          display: none
        .select_country_inline_wrapper
          float: left


  #select_your_office_section, #edit_template_section, #templates_section, #custom_fields_section

    .personal_settings_list
      margin: 1em 0

    .updated_at, .used_by
      font-size: 0.8em
      color: #999999
      display: block
      margin-top: 5px

    .updated_at
      margin-top: 15px

  #masterdata_section, #api_keys_section, #customize_interface_section
    overflow: hidden

    .section_wrapper
      position: relative

      &.expanded
        .expanded_container
          display: block

      &#technologies
        .unapprovedlist
          max-height: 4000px
          min-height: 500px


    .approve
      cursor: pointer

  .error_message
    text-align: center

  #edit_template_section, #new_template_section, #backup_to_dropbox_section, #expanded_country_section, #users_section, #masterdata_section, #api_keys_section, #customize_interface_section

    legend
      padding-bottom: 10px

    .update_template, #upload_new_template
      margin-top: 20px
      padding-top: 1em
      float: left
      width: 100%
      text-align: center
      @include mixins.top_divider

    .settings_container
      width: 500px
      margin: 0 auto

    .step
      font-size: 6em

      position: absolute
      left: 20px
      top: 50%
      margin-top: -40px

      color: #999999
      text-shadow: white 0 1px 0.1px

    .checked
      .selected_item
        display: block

#table-zebra
  font-size: 12px
  margin: 45px
  width: 480px
  border-collapse: collapse
  background-color: #FFF
  text-align: center
  th
    font-size: 14px
    font-weight: normal
    padding: 12px 15px
    border-right: 1px solid #fff
    border-left: 1px solid #fff
  td
    padding: 8px 15px
    border-right: 1px solid #fff
    border-left: 1px solid #fff
    position: relative

    transition: background-color .1s ease-in-out

    span
      display: none
      position: absolute
      background-color: #FFF
      border: 1px solid #DDD
      padding: 2px 4px

      &.name
        top: -15px

    &:hover
      background-color: #FFF
      span
        display: block

  tr
    border-bottom: 1px solid #FFF

  td:first-child
    text-align: left


  .heading-even
    background: #dce4ff
    border-bottom: 1px solid #d6dfff

  .heading-odd
    background: #d0dafd
    border-bottom: 1px solid #c8d4fd

  .column-even
    background: #eff2ff

  .column-odd
    background: #e8edff

  .row-even
    background: #d0dafd

@media only screen and (max-device-width: 900px), screen and (max-width: 900px)
  #settings
    #masterdata_section #expanded_containers
      margin: 0 50px 0 0
